.file {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 34px;

  &__icon {
    //.file__icon
    margin-right: 12px;
  }

  &__title {
    // .file__title
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    color: color('neutral', 40);
  }

  &__box {
    // .file__box
    display: flex;
    align-items: center;
  }

  &__date {
    //.file__date
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

    color: color('neutral', 50);
    margin-right: 7px;
  }

  &__size {
    //.file__size
    border: 1px solid color('neutral', 85);
    font-size: 11px;
    font-weight: 600;
    color: color('blue', 20);
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 2px;
  }

  &__status {
    //.file__status
    font-size: 12px;
    color: color('red', 60);
    margin-right: 7px;
  }

  &__controls {
    //.file__controls
    margin-left: auto;

    & svg {
      width: 25px;
      height: 33px;
      top: 4.5px;
      left: 3px;
      color: #006acc;
    }
  }

  &__button {
    //.file__button
    border: none;
    background-color: transparent;
    padding: 0;
    width: 18px;
    height: 18px;
    color: color('neutral', 70);
    margin: 0 10px;

    &--delete {
      //.file__button--delete
      color: color('red', 60);
    }

    &--reload {
      //.file__button--reload
      transform: scaleY(-1);
    }

    &--download {
      //.file__button--download
      width: 21px;
    }
  }
}
