.integration {

  &__row { // .integration__row
    flex-wrap: wrap;
    
    @media (min-width:$screen-lg-min) {
      flex-wrap: nowrap;
    }
  }
  
  &__buttons-col { // .integration__buttons-col
    max-width: 100%;

    @media (min-width:$screen-lg-min) {      
      max-width: 500px;
    }
  }
  
  &__title { // .integration__title
    display: flex;
    align-items: center;
    margin-bottom: 29px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  
  &__title-marker { // .integration__title-marker
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;  
    width: 48px;
    height: 48px;
    margin-right: 8px;
    border-radius: 18px;
    background-color: color("blue", 95);
    font-family: $font-secondary;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: color("blue", 55);
  }
  
  &__button-list { // .integration__button-list
  }

  &__button-wrapper { // .integration__button-wrapper
    max-width: 407px;
    width: 100%;
    margin-bottom: 32px;
  }
  
  &__button-box { // .integration__button-box
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
    padding: 22px 30px;
    border: 2px solid color("neutral", 90);
    border-radius: 16px;
    cursor: pointer;
    transition: border-color $default-transition-time, background-color $default-transition-time;

    &:not(.integration__button-box--active):hover {
      border-color: color("blue", 90);
      background-color: color("blue", 95);
    }

    &--active { // .integration__button-box--active
      border-color: color("blue", 55);
      background-color: color("blue", 95);
    }
  }
  
  &__button-name { // .integration__button-name
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: color("neutral", 60);
  }

  &__button { // .integration__button
    width: 100%;
  }
  
  &__code-col { // .integration__code-col
    max-width: 100%;
    
    @media (min-width:$screen-lg-min) {      
      max-width: 100%/12*7;
    }
  }

  &__code-wrapper { // .integration__code-wrapper
    margin-bottom: 40px;
  }

  &__copy-button { // .integration__copy-button
    width: 100%;
    max-width: 172px;
  }

  &__input-group { // .integration__input-group
    margin-bottom: 16px;
    padding-bottom: 0;
  }

  &__code-textarea { // .integration__code-textarea
    height: 112px;
    margin-bottom: 0;
    line-height: 20px;
    resize: none;
  }
  
  &__link-textarea { // .integration__link-textarea
    height: 52px;
    padding-top: 16px;
    padding-bottom: 14px;
    line-height: 20px;
    resize: none;
  }
}
