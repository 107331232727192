.profile-avatar {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md-min) {
    flex-direction: row;
  }
  
  &__box { // .profile-avatar__box
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    margin-bottom: 24px;
    padding: 4px;
    border-radius: 50%;
    background-color: color("neutral", 100);
    box-shadow: 0 3px 5px rgba(color("neutral", 20), .1);

    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
      margin-right: 28px;
    }
  }
  
  &__image { // .profile-avatar__image
    width: 100%;
    height: 100%;
    border: 2px solid color("neutral", 95);
    border-radius: 50%;
  }
  
  &__info { // .profile-avatar__info 
    display: flex;
    flex-direction: column;   
  }
  
  &__title { // .profile-avatar__title
    margin-bottom: 4px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  
  &__text { // .profile-avatar__text
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 28px;
    color: color("neutral", 40);
  }
  
  &__action-list { // .profile-avatar__action-list
    display: flex;
    flex-direction: column;
    margin: auto -8px 0;

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }
  
  &__action { // .profile-avatar__action
    margin: 0 8px 8px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }
}