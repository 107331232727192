.toggle {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;
  margin-bottom: 0;
  user-select: none;

  &--txt {
    width: 72px;
    text-align: left;
    
    .toggle__label {
      &:after {
        width: 24px;
        height: 24px;
        top: 4px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .toggle__input:checked + .toggle__label {
      &::after {
        transform: translateX(40px);
      }
    }
  }

  .dev-tools__col--switch & {
    margin-top: 7px;
  }

  &__input { // .toggle__input
    display: none;

    &:disabled {
      & + label {
        opacity: 0.5;
        cursor: default;

        &:after, 
        &:before {
          cursor: default;
        }
      }
    }
  }

  &__label { // .toggle__label
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-color: color("neutral", 90);
      cursor: pointer;
      content: "";
      transition: background-color $default-transition-time;
    }

    &::after {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: color("neutral", 100);
      content: "";
      transition: transform $default-transition-time;
    }

    .toggle__input:checked + & {
      &::before {
        background-color: color("mint", 50);
      }
      &::after {
        transform: translateX(20px);
      }
    }

    &--disabled {
      cursor: default;
      
      &:before {
        background-color: color('neutral', 95) !important;
        cursor: default;
      }

      &:after {
        background-color: color('neutral', 90);
        cursor: default;
      }
    }
  }

  &__txt {
    position: relative;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin: 7px 0 0 14px;

    &--off {
      color: color("neutral", 60);
      margin-left: 34px;
    }
  }
}
