.tag {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px 2px;
  border: 2px solid color("neutral", 95);
  border-radius: 20px;
  background-color: color("neutral", 100);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .07em;

  &__value { // .tag__value
    margin-right: 4px;
    font-size: 12px;
    line-height: 18px;
  }

  &__close { // .tag__close
    width: 18px;
    height: 18px;
    padding: 0;
    border-width: 0;
    background-color: $transparent;
    pointer-events: all;

    &:focus {
      outline-width: 0;
    }
  }

  &__close-icon { // .tag__close-icon
    pointer-events: none;
  }
}
