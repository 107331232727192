.emoji {
  display: inline-block;
  background: url('../../assets/images/emoji-sprite/emoji@1x.png') no-repeat top
    left;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background: url('../../assets/images/emoji-sprite/emoji@2x.png') no-repeat
      top left;
    background-size: 24px 125px;
  }
  &.hourglass-not-done_24x24 {
    background-position: 0 0;
  }
  &.money-bag_24x24 {
    background-position: 0 -25px;
  }
  &.money-mouth-face_24x24 {
    background-position: 0 -50px;
  }
  &.money-with-wings_24x24 {
    background-position: 0 -75px;
  }
  &.bank_24x24 {
    background-position: 0 -100px;
  }
}
