.payment-verify-modal {
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: color('neutral', 10);
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 120px;
    background: color('mint', 84);
    border-radius: 16px 16px 0 0;
    z-index: -1;
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    aspect-ratio: 1/1;
    border: none;
    outline: none;
    color: color('neutral', 100);
    background: transparent;
    outline: none;
    &:focus {
      outline: none;
    }
  }

  &__image {
    margin-bottom: 12px;
  }

  &__form {
    .button {
      display: flex;
      gap: 8px;
      svg {
        width: 20px;
        aspect-ratio: 1/1;
        color: color('blue', 80);
      }
    }
  }
}
