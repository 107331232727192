.profile-edit {
  max-width: 1097px;

  &__col-general { // .profile-edit__col-general
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 34px;

    @media (min-width: $screen-xl-min) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $screen-md-min) {
      max-width: 50%;
      margin-bottom: 0;
    }

    @media (min-width: $screen-xl-min) {
      max-width: 45.5%;
    }
  }

  &__col-details { // .profile-edit__col-details
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-xl-min) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $screen-md-min) {
      max-width: 50%;
    }

    @media (min-width: $screen-xl-min) {
      max-width: 54.5%;
    }
  }

  &__phone-group { // .profile-edit__phone-group
    @media (min-width: $screen-md-min) {
      flex-wrap: nowrap;
    }
  }
}