.table-highlight {
  display: inline-flex;
  align-items: center;
  padding: 1px 6px;
  background-color: color('neutral', 98);
  border: 1px solid color('neutral', 95);
  border-radius: 12px;
  font-size: 14px;
  line-height: 17px;
  color: color('neutral', 40);
  letter-spacing: -0.12px;

  &__icon {
    // .table-highlight__icon
    width: 13px;
    height: 13px;
    margin-right: 2px;
  }
  
  &__icon-right {
    // .table-highlight__icon
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  &__failed-amount {
    color: color('neutral', 70);
  }
}
