.color-list {
  display: flex;
  margin: 0 -12px;

  &__group { // .color-list__group
    flex-shrink: 0;
    padding: 0 12px;
  }

  &__circle { // .color-list__circle
    position: relative;
    width: 16px;
    height: 16px;
    margin-bottom: 0;
    border-radius: 50%;
    cursor: pointer;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
      border: 1px solid color("neutral", 100);
      border-radius: 50%;
      opacity: 0;
      content: "";
      transition: opacity $default-transition-time;
    }

    &:hover {
      &::after {
        opacity: 0.6;
      }
    }

    .color-list__input:checked + & {
      &::after {
        opacity: 1;
      }
    }

    &--mint20 { // .color-list__circle--mint20
      background-color: color("mint", 20);
    }

    &--mint50 { // .color-list__circle--mint50
      background-color: color("mint", 50);
    }

    &--yellow55 { // .color-list__circle--yellow55
      background-color: color("yellow", 55);
    }

    &--red70 { // .color-list__circle--red70
      background-color: color("red", 70);
    }

    &--neutral70 { // .color-list__circle--neutral70
      background-color: color("neutral", 70);
    }

    &--chery24 { // .color-list__circle--chery24
      background-color: color("chery", 24);
    }
  }

  &__input { // .color-list__input
    display: none;
  }
}
