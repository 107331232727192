.dashboard-header {
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 18px 68px 18px;
  background-color: var(--header-color);

  &__init-screen {
    justify-content: space-between;
  }

  &__get-help {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--Neutrals-100---White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
  }

  &__top-row {
    // .dashboard-header__top-row
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__burger {
    // .dashboard-header__burger
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 28px;
    padding: 0;
    margin: 0 20px 0 0;
    border-width: 0;
    background-color: $transparent;
    color: color('neutral', 100);
    transition: color $default-transition-time;

    &:hover {
      color: color('neutral', 98);
    }

    &:focus {
      outline: 1px dotted color('neutral', 100);
    }

    @media (min-width: $screen-lg-min) {
      display: none;
    }
  }

  &__burger-icon {
    // .dashboard-header__burger-icon
    width: 24px;
    height: 24px;
  }

  &__user-nav-row {
    // .dashboard-header__user-nav-row
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
  }

  &__menu {
    // .dashboard-header__menu
    gap: 8px;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
    scrollbar-width: none;

    &__item {
      position: relative;

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &__title {
        // .dashboard-header__menu__item__title
        margin-left: 8px;
        font-size: 14px;
        color: color('neutral', 90);
        font-family: 'Archivo';
        font-weight: 700;
      }
    }
  }

  &__wrap-logo {
    // .dashboard-header__wrap-logo
    width: 22px;
    height: 15px;
    padding: 0 0 0 52px;
    margin: 0 0 0 24px;
    position: relative;
  }

  &__line {
    // .dashboard-header__line
    position: absolute;
    left: 0;
    top: -3px;
    width: 1px;
    height: 22px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &__logo {
    // .dashboard-header__logo
    width: auto;
  }
}