.dashboard-nav {
  display: none;
  flex-direction: column;
  padding: 32px 8px 16px;
  background-color: color('neutral', 100);
  border-right: 1px solid #091e4224;
  min-height: 100%;
  height: calc(100vh - 54px);
  min-width: 240px;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 0.3s linear;
  z-index: 3;

  @media (min-width: $screen-lg-min) {
    display: flex;
  }

  &--close {
    left: -241px;
  }

  &__arrow {
    // dashboard-nav__arrow
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    box-shadow: 0px 8px 12px 0px #091e4226;
    position: absolute;
    right: -11px;
    top: 50px;
    background-color: color('neutral', 100);
    cursor: pointer;
    border: none;
    z-index: 2;

    &:focus {
      outline: none;
    }

    &--close {
      right: -30px;
      transform: rotateY(180deg);
    }
  }

  &__arrowIcon {
    width: 9px;
    height: 9px;
  }

  &__profileWrapper {
    padding: 0 8px 18px;
  }

  &__menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &:not(&--onboarding-mode) {
      overflow-y: auto !important;
    }
  }

  &__page-list {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  &__page {
    display: flex;
    text-align: center;

    &--support {
      margin-top: 8px;
    }

    &--help {
      .dashboard-nav__page-link {
        flex-direction: column;
        padding: 10px;
      }

      .dashboard-nav__page-title {
        font-size: 12px;
      }

      .dashboard-nav__page-icon {
        width: 24px;
      }
    }
  }

  .dashboard-nav__dropdown {
    width: 100%;

    &__dropdown-menu {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s linear;
      padding: 0;

      &.open {
        max-height: 300px;
      }
    }

    .dashboard-nav__page {
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }

  &__page-link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    text-decoration: none;
    transition: color $default-transition-time;
    padding: 12px 8px 12px 15px;
    border-radius: 3px;

    color: var(--Neutrals-30---Icon-Default, #3D475C);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &.nested-link {
      padding: 8px 8px 8px 32px;
    }

    cursor: pointer;

    &:hover,
    &:focus,
    &--active {
      outline: none;
      color: color('blue', 40);

      svg path:first-child {
        // fill: color('blue', 40);
      }
    }

    .dashboard-nav__menu--onboarding-mode & {
      transition: none;
    }

    .dashboard-nav__menu:not(.dashboard-nav__menu--onboarding-mode) & {
      &--active {
        color: color('blue', 40);
        background-color: color('blue', 95);
        animation: fade $default-transition-time ease;
        position: relative;
        min-width: 223px;

        &:before {
          content: '';
          position: absolute;
          z-index: 100;
          left: 0;
          background-color: color('blue', 40);
          height: 30%;
          width: 4px;
          border-radius: 0 4px 4px 0;
        }

        path {
          // fill: color('blue', 40);
        }
      }
    }

    .dashboard-nav__menu:not(.dashboard-nav__menu--onboarding-mode) & {
      &--active-sub {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: left;
        color: color('blue', 40);
        text-decoration: underline;
      }
    }

    .dashboard-nav__page-list>.dashboard-nav__page>& {
      &--active-sub {
        color: color('blue', 40);
        background-color: color('blue', 95);
        animation: fade $default-transition-time ease;
        position: relative;
        min-width: 223px;

        &:before {
          content: '';
          position: absolute;
          z-index: 100;
          left: 0;
          background-color: color('blue', 40);
          height: 30%;
          width: 4px;
          border-radius: 0 4px 4px 0;
        }

        path {
          // fill: color('blue', 40);
        }
      }
    }

    .dashboard-nav__page--onboarding-active & {
      color: color('blue', 30);
    }
  }

  &__page-content {
    display: flex;
    align-items: center;
    gap: 15px;

    svg {
      width: 25px;
    }
  }

  &__page-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    transition: background-color $default-transition-time;

    .dashboard-nav__menu--onboarding-mode & {
      transition: none;
    }

    .dashboard-nav__menu:not(.dashboard-nav__menu--onboarding-mode) .dashboard-nav__page--onboarding-active & {
      background-color: color('blue', 30);
      color: color('neutral', 100);
    }
  }

  &__page-icon {
    width: 16px;
  }

  &__page-title {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
  }
}

@keyframes fade {
  from {
    background: transparent;
  }

  to {
    background: color('blue', 95);
  }
}