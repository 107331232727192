.table-filter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -8px 20px;

  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: end;
  }

  @media (min-width: $screen-lg-min) {
    flex-wrap: nowrap;
  }

  &--no-wrap {
    // .table-filter--no-wrap
    flex-wrap: nowrap !important;

    @media (max-width: 1363px) {
      flex-wrap: wrap !important;
    }
  }

  &__col {
    // .table-filter__col
    margin-bottom: 8px;
    padding: 0 8px;
    order: 1;
    flex-grow: 1;

    @media (min-width: $screen-lg-min) {
      margin-bottom: 0;
      flex-grow: 0;
      margin-bottom: 10px;
    }

    &--search {
      // .table-filter__col--search
      order: -1;
      width: 100%;

      @media (min-width: $screen-lg-min) {
        order: 1;
        flex-grow: 1;
        width: auto;
      }
    }

    &--select {
      @media (min-width: $screen-lg-min) {
        flex: 0 0 235px;
      }
    }

    &--loans {
      @media (max-width: 1363px) {
        width: 33%;

        &__dateRange {
          width: 100%;
        }
      }
    }
  }

  &__export-btn {
    // .table-filter__export-btn
    padding: 8px 13px;
    width: 100%;
  }

  &__controls {
    //.table-filter__controls
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}