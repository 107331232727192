.password-sent {
  margin: auto;
  padding-bottom: 106px;
	text-align: center;
	
	&__image { // .password-sent__image
    max-width: 100%;
		margin-top: 80px;
		margin-bottom: 33px;
	}
	
	&__text { // .password-sent__text
    max-width: 310px;
		margin: 0 auto 33px;
    font-family: $font-secondary;
		font-size: 24px;
		line-height: 32px;
		font-weight: 700;
		color: color("neutral", 40);
	}
	
	&__resend { // .password-sent__resend
    display: flex;
    align-items: center;
    margin: 0 auto;
		background-color: $transparent;
		border-width: 0;    
    font-family: $font-secondary;
		font-size: 16px;
		line-height: 28px;
		font-weight: 700;
    color: color('blue', 55);
    transition: color $default-transition-time;

    &:hover {      
      color: color('blue', 40);
    }
	} 
	
	&__resend-icon { // .password-sent__resend-icon
    width: 18px;
    height: 19px;
    margin-right: 5px;
	}
}
