.quick-actions {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;

  &__menu {
    // .quick-actions__menu
    position: absolute;
    z-index: 20;
    top: 37px;
    right: 0;
    margin-bottom: 0;
    padding: 16px;
    background-color: color('neutral', 100);
    border: 2px solid color('neutral', 95);
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(#000, 0.12);
    list-style-type: none;
  }

  &__menu-item {
    // .quick-actions__menu-item
    display: block;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    button,
    button:focus {
      outline: none;
      background: none;
    }
  }

  &__menu-link {
    // .quick-actions__menu-link
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-decoration: none;
    transition: background-color $default-transition-time;
    outline: none;
    border: none;

    &--red {
      // .quick-actions__menu-link--red
      color: color('red', 50);

      &:hover {
        background-color: color('red', 95);
      }
    }

    &--blue {
      // .quick-actions__menu-link--blue
      color: color('blue', 30);

      &:hover {
        background-color: color('blue', 95);
      }
    }
    &--green {
      // .quick-actions__menu-link--blue
      color: color('green', 30);

      &:hover {
        background-color: color('green', 95);
      }
    }

    &--green { // .quick-actions__menu-link--green
      color: color("green", 40);

      &:hover {
        background-color: rgba(color("green", 90), .9);
      }
    }
  }

  &__menu-icon {
    // .quick-actions__menu-icon
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-right: 4px;

    .quick-actions__menu-link--red & {
      color: color('red', 70);
    }

    .quick-actions__menu-link--blue & {
      color: color('blue', 40);
    }

    .quick-actions__menu-link--green & {
       color: color("green", 50);
     }
  }
}
