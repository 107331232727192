.table-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: $transparent;
  border-width: 0;
  color: color("neutral", 40);

  &:focus {
    outline-width: 0;
  }

  &--white { // .table-dots--white
    color: color("neutral", 100);
  }

  &__icon { // .table-dots__icon
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
}
