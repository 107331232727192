.dev-tools {
  &__creation.card {
    // .dev-tools__creation.card
    padding-bottom: 20px;
  }

  &__wrap-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px;
  }

  &__title {
    //.dev-tools__title
    font-size: 18px;
    font-weight: 700;
    font-family: 'Archivo';
    color: color('neutral', 10);
    margin: 0 20px 0 0;
  }

  &__title--validation {
    margin-bottom: 27px;
    font-family: Archivo;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.36000001430511475px;
    text-align: left;
  }

  &__wrap-editBox {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__editButtons {
    font-family: 'Archivo';
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    gap: 6px;
    background-color: color('neutral', 100);
    border: none;
    align-items: center;
    display: flex;
    outline: none;

    &:focus {
      outline: none;
    }

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  &__subtitle {
    //.dev-tools__subtitle
    font-size: 14px;
    font-weight: 400;
    color: color('neutral', 40);
  }

  &__container {
    //.dev-tools__container
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__menu {
    // .dev-tools__menu
    width: 228px;
    padding: 24px 24px 0;
    background-color: color('neutral', 100);
    border: 2px solid color('neutral', 95);
    border-radius: 16px;
  }

  &__content {
    // .dev-tools__content
    flex: 1;
    min-width: 1px;
  }

  &__head {
    //.dev-tools__head
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__txt {
    //.dev-tools__txt
    font-size: 14px;
    color: color('neutral', 60);
  }

  &__link {
    //.dev-tools__link
    color: color('blue', 50);
    text-decoration: none;
  }

  &__config-title {
    // .dev-tools__config-title
    text-align: center;
    color: color('blue', 40);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &__not-found {
    // .dev-tools__not-found
    &.card {
      // .dev-tools__not-found.card
      padding: 40px 0 0 0;
    }

    &-content {
      // .dev-tools__not-found-content
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__footer {
      // .dev-tools__not-found__footer
      margin-top: 40px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: color('neutral', 98);

      &-link {
        // .dev-tools__not-found__footer-link
        display: flex;
        align-items: center;
        margin-left: 5px;
        color: color('blue', 40);
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;

        &-text {
          // .dev-tools__not-found__footer-link-text
          margin-right: 3px;
        }
      }

      &-text {
        // .dev-tools__not-found__footer-text
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        color: color('neutral', 40);
      }
    }
  }

  &__not-found-txt {
    // .dev-tools__not-found-txt
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: color('neutral', 50);
    margin-bottom: 24px;
    max-width: 50%;
  }

  &__not-found-image-box {
    //.dev-tools__not-found-image-box
    margin-bottom: 10px;
  }

  &__not-found-image {
    // .dev-tools__not-found-image
    width: 80px;
    height: 80px;
  }

  &__form {
    //.dev-tools__form
    margin-top: 46px;
  }

  &__form-input {
    // dev-tools__form-input
    margin: 0 0 24px;
    padding: 0;
  }

  &__form-label {
    // dev-tools__form-label
    color: color('neutral', 40);

    &--status {
      // dev-tools__form-label--status
      width: fit-content;
    }

    &-anotherColor {
      color: color('neutral', 70);
    }

    & .label__children {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  &__doc {
    // dev-tools__doc
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid color('neutral', 98);
    padding: 24px 0 0;
    margin: 24px 0 0;

    & a {
      text-decoration: none;
      font-family: $font-secondary;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &__doc-boxDesc {
    max-width: 580px;
    padding: 0 25px 0 0;
  }

  &__doc-title {
    // dev-tools__doc-title
    font-size: 16px;
    line-height: 20px;
    color: color('neutral', 30);
    margin: 0 0 8px;
  }

  &__doc-text {
    // dev-tools__doc-text
    font-size: 16px;
    line-height: 24px;
    color: color('neutral', 50);
  }

  &__doc-btn {
    // dev-tools__doc-btn
    font-family: 'Archivo';
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: color('neutral', 10);
    display: flex;
    white-space: nowrap;
    border-radius: 6px;
    border: 1px solid color('neutral', 90);
    box-shadow: 0px 1px 0px 0px #1b1f231a;
    padding: 6px 12px;
    background-color: color('neutral', 100);
    align-items: center;
    gap: 8px;
    outline: none;
  }

  &__doc-icon {
    width: 16px;
    height: 13px;

    & path {
      fill: color('neutral', 30);
    }
  }

  &__events {
    //.dev-tools__events
    margin: 20px 0 0 0;
  }

  &__events-head {
    //."webhooks__events-head
    display: flex;
    align-items: center;
  }

  &__events-info {
    //.dev-tools__events-info
    padding-right: 40px;
  }

  &__events-title {
    //.dev-tools__events-title
    font-size: 16px;
    line-height: 20px;
    color: color('neutral', 40);
    margin-bottom: 10px;
  }

  &__events-txt {
    //.dev-tools__events-txt
    font-size: 14px;
    line-height: 20px;
    color: color('neutral', 50);
  }

  &__events-item {
    //.dev-tools__events-item
    width: fit-content;
    display: flex;
    align-items: flex-start;
    margin: 16px 0;
    cursor: pointer;
    gap: 16px;
  }

  &__events-item-title {
    //.dev-tools__event-item-title
    font-size: 12px;
    line-height: 16px;
    color: color('neutral', 40);
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__events-item-txt {
    //.dev-tools__event-item-txt
    font-size: 12px;
    line-height: 16px;
    color: color('neutral', 50);
    font-weight: 400;
  }

  &__events-subItem {
    // dev-tools__events-subItem
    margin: 0 0 0 64px;

    &--no-margin {
      // dev-tools__events-subItem--no-margin
      margin: 16px 0 0 0;
    }
  }

  &__events-subItem-wrap {
    // dev-tools__events-subItem-wrap
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 0 16px;
  }

  &__events-subItem-txt {
    // dev-tools__events-subItem-txt
    padding: 4px 8px;
    background-color: color('neutral', 98);
    border: 1px solid color('neutral', 95);
    color: color('neutral', 40);
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-family: 'IBM';
  }

  &__control {
    //.dev-tools__control
    text-align: right;
    margin-top: 32px;
    padding-top: 11px;
  }

  &__item-head {
    //.dev-tools__item-head
    display: flex;
    border-top: 2px solid color('neutral', 98);
    border-bottom: 2px solid color('neutral', 98);
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 24px;
    margin-bottom: 0;
  }

  &__block {
    //.dev-tools__block
    border-top: 2px solid color('neutral', 98);
    padding-top: 24px;
    margin: 0 0 24px;

    &--mbn {
      margin-bottom: 0;
    }

    &--no-border {
      border: none;
    }

    &.clientid {
      .user-profile__copy-field {
        overflow-wrap: anywhere;
        flex-direction: column;
        align-items: flex-start;

        .table__copy-btn {
          margin-top: 5px;
          margin-left: 0px;
        }
      }
    }

    .table__copy-btn {
      transform: none;
    }
  }

  &__block-name {
    // .dev-tools__block-name
    font-size: 16px;
    color: color('neutral', 50);
    font-weight: 500;
    margin-bottom: 10px;

    &--mbn {
      // .dev-tools__block-name--mbn
      margin-bottom: 0;
    }
  }

  &__button-wrap {
    //.dev-tools__button-wrap
    display: flex;
    align-items: center;
  }

  &__item-button {
    //.dev-tools__item-button
    margin-left: 8px;

    svg {
      margin-right: 8px;
    }
  }

  &__btn-icon {
    //.dev-tools__btn-icon
    width: 14px;
    height: 14px;
    color: color('neutral', 70);
    margin-right: 8px;

    &--arrow {
      // .dev-tools__btn-icon--arrow
      transform: rotate(90deg);
    }

    &--docs {
      //.dev-tools__btn-icon--docs
      color: color('blue', 55);
    }

    &--download {
      width: 16px;
      height: 13px;
    }

    &--test {
      color: #fff;
      width: 16px;
      height: 16px;
    }
  }

  &__item-table-wrap {
    //.dev-tools__item-table-wrap
    max-width: 100%;
    overflow: auto;
  }

  &__item-table {
    //.dev-tools__item-table
    width: 100%;
    min-width: 700px;

    tbody {
      //.dev-tools__item-table tbody
      tr {
        //.dev-tools__item-table tbody tr
        border-top: 2px solid color('neutral', 98);
      }
    }

    th {
      //.dev-tools__item-table th
      font-size: 16px;
      font-weight: 500;
      color: color('neutral', 50);
      padding: 24px 5px 8px 0;
    }

    td {
      //.dev-tools__item-table td
      font-size: 16px;
      color: color('neutral', 50);
      font-weight: 400;
      padding: 24px 25px 24px 0;

      &:last-child {
        padding-right: 0;
      }
    }

    &--sub-status {
      //.dev-tools__item-table--sub-status
      th,
      td {
        &:last-child {
          text-align: right;
        }
      }

      th {
        padding-bottom: 24px;
      }
    }

    &--history {
      //.dev-tools__item-table--history
      th,
      td {
        &:last-child {
          text-align: right;
        }
      }
    }

    &--modal {
      //.dev-tools__item-table--modal
      margin-bottom: 21px;

      td {
        padding-top: 45px;

        &:last-child {
          padding-right: 25px;
        }
      }

      th {
        padding: 24px 5px 8px 0;
      }
    }
  }

  &__item-table-date {
    //.dev-tools__item-table-date
    white-space: nowrap;
  }

  &__link-block {
    //.dev-tools__link-block
    font-size: 12px;
    font-family: $font-third;
    background-color: color('neutral', 98);
    border-radius: 6px;
    padding: 2px 6px;
    display: inline-flex;
    align-items: center;
    min-height: 24px;
    line-height: 19px;
    word-break: break-all;
  }

  &__docs {
    //.dev-tools__docs
  }

  &__docs-title {
    //.dev-tools__docs-title
    font-size: 16px;
    color: color('neutral', 50);
    margin-bottom: 10px;
  }

  &__docs-txt {
    //.dev-tools__docs-txt
    font-size: 16px;
    color: color('neutral', 60);
    margin-bottom: 12px;
  }

  &__controls {
    // .dev-tools__controls
    text-align: right;
  }

  &__message {
    //.dev-tools__message
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f0f9f9;
    color: color('mint', 50);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
  }

  &__message-icon {
    //.dev-tools__message-icon
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__details {
    // .dev-tools__details
    background-color: color('neutral', 98);
    border-radius: 8px;
    width: 100%;
    font-family: $font-third;
    font-weight: 500;
    font-size: 14px;
    color: color('neutral', 60);

    td {
      padding: 14px 16px;

      &:first-child {
        color: color('neutral', 50);
      }
    }
  }

  &__table-panel {
    //.dev-tools__table-panel
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  &__table-panel-controls {
    //.dev-tools__table-panel-controls
    display: flex;
  }

  &__table-panel-item {
    //.dev-tools__table-panel-item
    margin-left: 40px;
  }

  &__select-box {
    //.dev-tools__select-box
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__select-box-name {
    //.dev-tools__select-box-name
    font-size: 16px;
    color: color('neutral', 50);
    margin-right: 16px;
  }

  &__select-control {
    //.dev-tools__select-control
    min-width: 285px;
  }

  &__row {
    //.dev-tools__row
    display: flex;
    margin: 0 -12px;
  }

  &__col {
    //.dev-tools__col
    width: 50%;
    padding: 0 12px;
  }

  &__modal {
    //.dev-tools__modal
    padding: 40px 24px;
  }

  &__modal-controls {
    //.dev-tools__modal-controls
    text-align: right;
  }

  &__content-view {
    //.dev-tools__content-view
    background-color: color('neutral', 98);
    border-radius: 8px;
    padding: 24px 26px;
    overflow: auto;
    margin-bottom: 32px;
    font-family: $font-third;
    font-size: 14px;
    max-height: 200px;
    overflow: auto;
  }

  &__tabs {
    //.dev-tools__tabs
    margin-top: 20px;
  }
}

.webhook {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::before {
    flex-shrink: 0;
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid color('neutral', 100);
    content: '';
  }

  &--mint20 {
    // .webhook--mint20
    &::before {
      background-color: color('mint', 20);
      box-shadow: 0 0 0 1px color('mint', 20);
    }
  }

  &--mint50 {
    // .webhook--mint50
    &::before {
      background-color: color('mint', 50);
      box-shadow: 0 0 0 1px color('mint', 50);
    }
  }

  &--yellow55 {
    // .webhook--yellow55
    &::before {
      background-color: color('yellow', 55);
      box-shadow: 0 0 0 1px color('yellow', 55);
    }
  }

  &--red70 {
    // .webhook--red70
    &::before {
      background-color: color('red', 70);
      box-shadow: 0 0 0 1px color('red', 70);
    }
  }

  &--neutral70 {
    // .webhook--neutral70
    &::before {
      background-color: color('neutral', 70);
      box-shadow: 0 0 0 1px color('neutral', 70);
    }
  }

  &--chery24 {
    // .webhook--chery24
    &::before {
      background-color: color('chery', 24);
      box-shadow: 0 0 0 1px color('chery', 24);
    }
  }
}

.subscription {
  &__buttons {
    // .subscription__buttons
    margin-left: 12px;
    border-radius: 6px;
    border: 1px;
    padding: 8px 25px;
    border: 1px solid #1f232826;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: color('neutral', 10);
  }
}

.validation__wrapper {
  position: relative;
}

.validation {
  width: 70%;
  transition: 0.6s all;

  &.card {
    // .validation.card
    padding: 27px 63px 80px 44px;
  }

  &.open {
    width: 100%;
    padding-right: 94px;
    transition: 0.6s all;
  }

  &__container-list {
    // validation__container-list
    padding: 0 26px;
  }

  &__check-icon {
    // .validation__check-icon
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    top: 4.5px;
    left: 3px;
    color: #006acc;
  }

  &__list-title {
    // .validation__list-title
    margin-bottom: 20px;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 800;
    line-height: 18px;
    text-align: left;
    color: color('neutral', 10);
  }

  &__list {
    // .validation__list
    margin-bottom: 20px;
    padding-left: 0;
  }

  &__list--border-top {
    // .validation__list--border-top
    padding-top: 24px;
    border-top: 2px solid #e9ebed;
  }

  &__list-item {
    // .validation__list-item
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: color('neutral', 50);
  }

  &__list-item svg {
    margin-right: 16px;
  }

  &__list-item div {
    display: flex;
    margin-left: 6px;
  }

  & .line {
    bottom: 153px;
  }

  & .button--primary-blue {
    width: 124px;
  }
}

.validationPreview {
  overflow-y: auto;
  height: 100%;
  width: 60px;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid var(--Neutrals-90---Input-Border, #e0e4eb);
  background: #fff;
  border: 2px solid #f0f1f5;
  border-radius: 0 16px 16px 0;
  transition: 0.6s all;

  &.open {
    width: 33%;
    transition: 0.6s all;
  }

  &__header {
    padding: 20px;
    padding-left: 28px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e9ebed;

    color: var(--Neutrals-10---Primary-Text, #14181f);
    font-family: Archivo;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    transition: 0.6s all;

    img {
      cursor: pointer;
    }

    &.closed {
      padding: 20px;
      transition: 0.6s all;

      img {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;

    &.closed {
      display: none;
      pointer-events: none;
    }
  }

  &__image-container {
    // .validationPreview--image-container
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e9ebed;
  }

  &__title {
    margin-bottom: 12px;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: color('neutral', 10);
  }
  &__text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: color('neutral', 50);
  }

  &__image {
    width: 100%;
    height: 100%;
    margin-top: 12px;
    margin-bottom: 34px;
  }

  &__download-btn {
    width: 143px;
    height: 32px;
    margin-top: 12px;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: color('neutral', 10);

    & svg {
      margin-right: 8px;
    }
  }
}

.validation-account {
  // .validation-account
  width: 100%;

  &.card {
    // .validation-account.card
    padding: 20px;
  }

  &__title {
    margin-bottom: 7px;
    font-family: Archivo;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.36000001430511475px;
    text-align: left;
    color: color('neutral', 10);
  }

  &__subtitle {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: color('neutral', 40);

    & a {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #006acc;
    }
  }

  &__form {
    margin-top: 20px;
  }

  &__reset {
    display: flex;
    align-items: center;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #006acc;
    cursor: pointer;

    & svg {
      margin-right: 10px;
    }
  }

  &__reset-password {
    display: flex;
    align-items: center;
    gap: 20px;

    & .form-group {
      width: calc(100% - 87px);
    }
  }

  &__password-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    & .form-group {
      width: 50%;
    }
  }

  & .dev-tools__control {
    margin-top: 260px;
  }
}

.upload-file {
  // .upload-file

  &__title {
    // .upload-file__title
    margin-top: 40px;
    font-family: Archivo;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.36000001430511475px;
    text-align: left;
    color: color('neutral', 10);
  }

  &__title:first-child {
    margin-top: 0;
  }

  &__subtitle {
    // .upload-file__subtitle

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: color('neutral', 40);
  }
}
