.timeline {
  margin-top: 30px;
  overflow: auto;

  &__box {
    //.timeline__box
    min-width: 420px;
  }
  &__item {
    //.timeline__item
    position: relative;
    display: flex;
    cursor: pointer;
    padding: 10px 0;
    margin: 0 0 20px;

    &--checked, //.timeline__item--checked
    &:hover {
      background-color: color('neutral', 98);
    }

    &:after {
      content: '';
      display: block;
      width: 3px;
      height: calc(100% + 20px);
      background-color: color('blue', 60);
      position: absolute;
      left: 225px;
      top: -2px;
    }

    &--last {
      //.timeline__item--last
      &:after {
        height: calc(100% - 13px);
      }
    }

    &--first {
      &:after {
        height: 100%;
        top: 18px;
      }

      &.timeline__item--last {
        &:after {
          display: none;
        }
      }
    }
  }

  &__date {
    //.timeline__date
    flex: 0 0 220px;
    color: color('neutral', 55);
    font-size: 14px;
    padding: 0 0 0 10px;
    white-space: nowrap;
  }

  &__bubble {
    //.timeline__bubble
    display: block;
    flex: 0 0 12px;
    height: 12px;
    background-color: color('blue', 30);
    border-radius: 50%;
    z-index: 1;
    position: relative;
    margin-top: 2px;
  }

  &__status {
    //.timeline__status
    margin-left: 10px;
    font-size: 14px;
    background-color: color('mint', 50);
    color: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    white-space: nowrap;

    &--failed {
      background-color: color('red', 60);
    }

    &--completed {
      background-color: color('blue', 60);
    }
  }

  &__amount {
    color: color('neutral', 50);
    padding: 0 20px 0;
  }
}
