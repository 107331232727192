.radio-tab {
  display: flex;
  flex-wrap: wrap;

  &__item { // .radio-tab__item
    flex-grow: 1;
  }

  &__input { // .radio-tab__input
    display: none;
  }

  &__label { // .radio-tab__label
    display: block;
    margin-bottom: 0;
    padding: 13px 33px;
    border: 2px solid color("blue", 30);
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: color("blue", 30);
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color $default-transition-time, color $default-transition-time;
    user-select: none;

    .radio-tab__input:checked + & {
      background-color: color("blue", 30);
      color: color("neutral", 100);
    }

    .radio-tab__item:first-child & {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .radio-tab__item:last-child & {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
