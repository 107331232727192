.user-profile {
  &__title {
    //.user-profile__title
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
    font-family: $font-secondary;
    margin: 30px 0 20px;
  }

  &__sub-title {
    //.user-profile__sub-title
    font-size: 16px;
    letter-spacing: -0.4px;
    font-family: $font-secondary;
    margin: 0;
    color: color('neutral', 40);
    text-transform: none;
  }

  &__sub-txt {
    //.user-profile__sub-txt
    font-size: 12px;
    color: color('red', 70);
    display: block;
    font-weight: normal;
    padding-top: 6px;
  }

  &__row {
    //.user-profile__row
    margin-bottom: 20px;
  }

  &__col {
    //.user-profile__col
    max-width: 50%;
  }

  &__table {
    // .user-profile__table
    font-size: 14px;
    width: 100%;

    &__icon {
      width: 16px;
      margin-left: 32px;
    }

    &__button,
    &__button:focus {
      display: flex;
      outline: none;
      border: none;
      background: none;
      padding: 0;
    }

    tr {
      //..user-profile tr
      border-bottom: 1px solid color('neutral', 95);
      padding: 10px 0;
      transition: all 450ms ease;

      &:hover {
        background-color: color('neutral', 98);
        .user-profile__copy-btn {
          visibility: visible;
        }
      }
    }

    td {
      padding: 10px 10px 10px 0;

      &:first-child {
        color: color('neutral', 60);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        padding-left: 5px;
      }

      &.user-profile__table-title {
        padding: 20px 0 20px 5px;
      }

      &.user-profile__additional {
        &:first-child {
          font-size: 11px;
          padding-left: 8px;
        }
      }
    }
  }

  &__collapse-row {
    //.user-profile__collapse-row
    cursor: pointer;
    position: relative;

    &:hover {
      .user-profile__add-info-icon {
        opacity: 1;
      }
    }
  }

  &__copy-field {
    //.user-profile__copy-field
    display: flex;
    align-items: center;
  }

  &__copy-btn {
    //.user-profile__copy-btn
    visibility: hidden;
  }

  &__menu {
    //.user-profile__menu
    display: flex;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  &__menu-item {
    //.user-profile__menu-item
    color: color('blue', 30);
    font-size: 16px;
    font-weight: 700;
    font-family: $font-secondary;
    text-decoration: none;
    padding: 4px 12px 12px;
    margin: 0 8px;
    position: relative;
    border: none;
    background-color: rgba(0, 0, 0, 0);

    &:focus {
      outline: none;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &:after {
      opacity: 0;
      position: absolute;
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
      width: 51px;
      max-width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: color('blue', 30);
      content: '';
      transition: opacity 0.2s, background-color 0.2s;
    }
  }

  &__menu-last {
    //.user-profile__menu-last
    margin-left: auto;
    margin-top: -10px;
  }

  &__progress {
    //.user-profile__progress
    margin-top: 20px;
  }

  &__add-info-icon {
    //.user-profile__add-info-icon
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    transition: opacity 350ms ease;

    &--active {
      transform: rotate(180deg);
    }
  }
}
