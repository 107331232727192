.search {
  position: relative;

  &--settings {
    position: absolute;
    top: 20px;
    width: 500px;
  }

  &__input {
    width: 100%;
    height: 100%;
    padding-right: 42px;
  }

  &__button {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    padding-left: 10px;
    border-width: 0;
    background-color: $transparent;
    color: color('neutral', 50);
    transition: color $default-transition-time;

    &:hover {
      color: color('blue', 55);
    }

    &:focus {
      outline: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}
