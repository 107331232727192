.role {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::before {
    flex-shrink: 0;
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid color("neutral", 100);
    content: "";
  }

  &--mint20 { // .role--mint20
    &::before {
      background-color: color("mint", 20);
      box-shadow: 0 0 0 1px color("mint", 20);
    }
  }

  &--mint50 { // .role--mint50
    &::before {
      background-color: color("mint", 50);
      box-shadow: 0 0 0 1px color("mint", 50);
    }
  }

  &--yellow55 { // .role--yellow55
    &::before {
      background-color: color("yellow", 55);
      box-shadow: 0 0 0 1px color("yellow", 55);
    }
  }

  &--red70 { // .role--red70
    &::before {
      background-color: color("red", 70);
      box-shadow: 0 0 0 1px color("red", 70);
    }
  }

  &--neutral70 { // .role--neutral70
    &::before {
      background-color: color("neutral", 70);
      box-shadow: 0 0 0 1px color("neutral", 70);
    }
  }

  &--chery24 { // .role--chery24
    &::before {
      background-color: color("chery", 24);
      box-shadow: 0 0 0 1px color("chery", 24);
    }
  }
}
