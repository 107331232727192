.table-account-number {
  display: inline-flex;
  align-items: center;
  max-width: 130px;

  &--w100 {
    // .table-account-number--w100
    max-width: initial;
  }

  &__copy-btn {
    // .table-account-number__copy-btn
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    padding: 0;
    background-color: $transparent;
    border-width: 0;
    color: color('neutral', 80);
    transition: color $default-transition-time;

    &:hover {
      color: color('blue', 30);
    }

    &:focus {
      outline-width: 0;
    }
  }

  &__text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__copy-btn-icon {
    // .table-account-number__copy-btn-icon
  }
}
