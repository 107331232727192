.modal-content {
  padding: 0 16px 24px;

  @media (min-width: $screen-sm-min) {
    padding: 0 24px 40px;
  }

  &--mint-top {
    // .modal-content--mint-top
    position: relative;

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 164px;
      border-radius: 16px 16px 0 0;
      background-color: color('mint', 84);
      content: '';
    }
  }

  &--add-partner {
    //.modal-content--add-partner
    padding: 24px;
  }

  &__close {
    // .modal-content__close
    position: absolute;
    z-index: 10;
    top: 29px;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-width: 0;
    background-color: $transparent;
    cursor: pointer;
    color: color('blue', 30);
    transition: color $default-transition-time;

    &:focus {
      outline-width: 0;
    }

    .modal-content--mint-top & {
      color: color('neutral', 100);
      top: 12px;
      right: 12px;
    }
  }

  &__close-icon {
    // .modal-content__close-icon
    width: 32px;
    height: 32px;

    .modal-content--mint-top & {
      width: 24px;
      height: 24px;
    }
  }

  &__title {
    // .modal-content__title
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-align: center;

    &--mb {
      margin-bottom: 22px;
    }

    .modal-content--mint-top & {
      font-size: 20px;
      line-height: 24px;

      @media (min-width: $screen-md-min) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    @media (min-width: $screen-md-min) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__text {
    // .modal-content__text
    max-width: 485px;
    margin: 0 auto 40px;
    font-size: 16px;
    line-height: 24px;
    color: color('neutral', 40);
    text-align: center;
    letter-spacing: -0.02em;

    .modal-content--mint-top & {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 24px;
      color: color('neutral', 10);
      letter-spacing: unset;

      @media (min-width: $screen-md-min) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .modal-content--verify-payment-method & {
      max-width: 380px;
      margin-bottom: 15px;
    }

    .modal-content--modal-role & {
      max-width: 320px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__text-row {
    // .modal-content__text-row
    display: block;
  }

  &__form {
    // .modal-content__form
    width: 100%;
  }

  &__illustration {
    // .modal-content__illustration
    position: relative;
    width: 100%;
    margin: 0 auto;

    &::before {
      display: block;
      content: '';
    }

    &--hand {
      // .modal-content__illustration--hand
      max-width: 284px;
      margin-bottom: 32px;

      &::before {
        padding-top: 84.5%;
      }
    }

    &--confirm {
      // .modal-content__illustration--confirm
      max-width: 200px;
      margin-top: 71px;
      margin-bottom: 24px;

      &::before {
        padding-top: 100%;
      }
    }

    &--success {
      // .modal-content__illustration--success
      max-width: 200px;
      margin-top: 85px;
      margin-bottom: 24px;

      &::before {
        padding-top: 100%;
      }
    }

    &--add-payment-method {
      // .modal-content__illustration--add-payment-method
      max-width: 284px;
      margin-bottom: 28px;

      &::before {
        padding-top: 84.5%;
      }
    }

    &--verify-payment-method {
      // .modal-content__illustration--verify-payment-method
      max-width: 284px;
      margin-bottom: 28px;

      &::before {
        padding-top: 84.5%;
      }
    }

    &--envelope {
      // .modal-content__illustration--envelope
      max-width: 200px;
      margin-top: 108px;
      margin-bottom: 24px;

      &::before {
        padding-top: 100%;
      }
    }

    &--deactivate {
      // .modal-content__illustration--deactivate
      max-width: 200px;
      margin-top: 50px;
      margin-bottom: 24px;

      &::before {
        padding-top: 100%;
      }
    }

    &--hand-with-coin {
      // .modal-content__illustration--hand-with-coin
      max-width: 240px;
      margin-bottom: 28px;

      &::before {
        padding-top: 100%;
      }
    }
  }

  &__illustration-img {
    // .modal-content__illustration-img
    position: absolute;

    &--lv1 {
      // .modal-content__illustration-img--lv1
      .modal-content__illustration--hand & {
        top: 4%;
        left: 7.4%;
        width: 85%;
      }

      .modal-content__illustration--add-payment-method & {
        top: 3%;
        left: 7.8%;
        width: 84.3%;
      }

      .modal-content__illustration--verify-payment-method & {
        top: 21.2%;
        left: 18.5%;
        width: 63.5%;
      }

      .modal-content__illustration--envelope & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .modal-content__illustration--deactivate & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .modal-content__illustration--hand-with-coin & {
        top: 11%;
        left: 7.6%;
        width: 76.2%;
      }

      .modal-content__illustration--confirm & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .modal-content__illustration--success & {
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    &--lv2 {
      // .modal-content__illustration-img--lv2
      .modal-content__illustration--hand & {
        top: 19.5%;
        left: 13.4%;
        width: 67%;
      }

      .modal-content__illustration--add-payment-method & {
        top: 45.7%;
        left: 22.8%;
        width: 68.8%;
      }

      .modal-content__illustration--verify-payment-method & {
        top: 38%;
        left: 21%;
        width: 68.8%;
      }

      .modal-content__illustration--envelope & {
        top: 27.9%;
        left: 23.3%;
        width: 50.4%;
      }

      .modal-content__illustration--deactivate & {
        top: 39%;
        left: 38%;
        width: 24%;
        border-radius: 50%;
      }

      .modal-content__illustration--hand-with-coin & {
        top: 43.4%;
        left: 18%;
        width: 81.1%;
      }

      .modal-content__illustration--confirm & {
        top: 21%;
        left: 30.3%;
        width: 31.4%;
      }

      .modal-content__illustration--success & {
        top: 27.1%;
        left: 22.3%;
        width: 56.4%;
      }
    }

    &--lv3 {
      // .modal-content__illustration-img--lv3
      .modal-content__illustration--hand & {
        top: 59.5%;
        left: 8.8%;
        width: 20.5%;
      }

      .modal-content__illustration--add-payment-method & {
        top: 67.5%;
        left: 12.2%;
        width: 22.1%;
      }

      .modal-content__illustration--verify-payment-method & {
        top: 59.5%;
        left: 10.8%;
        width: 22.1%;
      }

      .modal-content__illustration--hand-with-coin & {
        top: 0;
        left: 0;
        width: 91.3%;
      }

      .modal-content__illustration--confirm & {
        top: 22.6%;
        left: 37.5%;
        width: 37.4%;
      }

      .modal-content__illustration--success & {
        top: 19.6%;
        left: 23.5%;
        width: 30.5%;
      }
    }

    &--lv4 {
      // .modal-content__illustration-img--lv4
      .modal-content__illustration--add-payment-method & {
        top: 41.7%;
        right: 23.9%;
        width: 18%;
      }

      .modal-content__illustration--verify-payment-method & {
        top: 8.2%;
        right: 27.4%;
        width: 28%;
      }
    }
  }

  &__btn-box {
    // .modal-content__btn-box
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    gap: 20px;
  }

  &__btn-delete-box {
    // .modal-content__btn-delete-box
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    padding-top: 32px;
  }

  &__btn {
    // .modal-content__btn
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--delete {
      // modal-content__btn--delete
      width: 47%;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      border-radius: 8px;
      padding: 8px 10px 8px 10px;
    }
  }
}
