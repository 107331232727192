.quick-actions-select {
  flex-shrink: 0;
  position: relative;
  display: inline-block;

  &__box {
    // .quick-actions-select__box
    display: flex;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    color: color('neutral', 100);
    cursor: pointer;
  }

  &__text {
    // .quick-actions-select__text
    flex-shrink: 0;
    margin-right: 2px;
    padding: 10px 8px 10px 16px;
    background-color: color('blue', 30);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    white-space: nowrap;
  }

  &__chevron {
    // .quick-actions-select__chevron
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    background-color: color('blue', 30);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    transition: background-color $default-transition-time;

    .quick-actions-select--opened & {
      background-color: color('blue', 40);
    }
  }

  &__chevron-icon {
    // .quick-actions-select__chevron-icon
    width: 20px;
    height: 20px;

    .quick-actions-select--opened & {
      transform: scaleY(-1);
    }
  }

  &__list {
    // .quick-actions-select__list
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    transform: translateY(12px);
    min-width: 100%;
    padding: 16px;
    background-color: color('neutral', 100);
    border: 2px solid color('neutral', 95);
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(#000, 0.16);
  }

  &__option {
    // .quick-actions-select__option
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-width: 0;
    border-radius: 8px;
    background-color: $transparent;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: color('blue', 30);
    white-space: nowrap;
    transition: background-color $default-transition-time;
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:focus {
      outline-width: 0;
    }

    &:hover {
      background-color: color('blue', 95);
    }
  }

  &__option-icon {
    // .quick-actions-select__option-icon
    width: 16px;
    height: 16px;
    margin-right: 4px;
    color: color('blue', 40);

    &--form {
      // .quick-actions-select__option-icon--form
      height: 14px;
    }
  }
}
