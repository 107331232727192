.indicator {
  display: flex;
  align-items: center;
  padding: 18px 16px 18px 22px;
  border: 2px solid color("neutral", 95);
  border-radius: 16px;
  background-color: color("neutral", 100);

  &__content-col { // .indicator__content-col
    flex-grow: 1;
    margin-right: 12px;
  }

  &__title { // .indicator__title
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.12px;
    color: color("neutral", 50);
  }

  &__value { // .indicator__value
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }

  &__difference { // .indicator__difference
    margin-left: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: color("mint", 50);
    vertical-align: 0.5em;
  }

  &__icon-box { // .indicator__icon-box
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid color("mint", 84);
    background-color: color("mint", 90);
    color: color("mint", 68);
  }

  &__icon { // .indicator__icon
    width: 20px;
    height: 20px;
  }
}
