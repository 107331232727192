@import '../../../scss/utils/_normalize.scss';
@import '../../../scss/utils/_reboot.scss';
@import '../../../scss/vars/_colors.scss';
@import '../../../scss/utils/_functions.scss';
@import '../../../scss/vars/_base.scss';
@import '../../../scss/vars/_sizes.scss';
@import '../../../scss/vendor/_vendor.scss';
p {
  margin: 0;
}

.mytypography {
  word-wrap: normal;
}

.text-primary {
  color: color('neutral', 10);
}
.text-white {
  color: color('neutral', 100);
}
.text-black {
  color: #000000;
}
.text-disabled {
  color: color('neutral', 70);
}
.text-secondary {
  color: color('neutral', 50);
}
.text-tertiary {
  color: color('neutral', 60);
}
.text-emphasis {
  color: color('neutral', 40);
}
.text-base {
  color: color('neutral', 20);
}
.text-icon {
  color: color('neutral', 30);
}
.text-info {
  color: color('blue', 30);
}
.text-overlay {
  color: color('blue', 20);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.bold-1 {
  font-weight: 800;
}
.h1 {
  font-size: 96px;
  line-height: 104px;
  letter-spacing: -1.5%;
}

.h2 {
  font-size: 60px;
  line-height: 68px;
  letter-spacing: -0.5%;
}

.h3 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0%;
}
.h4 {
  font-size: 40px;
  line-height: 54px;
  letter-spacing: -0.8px;
}

.large-title {
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.4%;
}
.large-title-2 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.4%;
}

.title-1 {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.38%;
}

.title-md {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.46px;
}

.title-2 {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.26%;
}

.title-3 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.46%;
}

.body {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.42%;
}
.body-sm {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.42%;
}

.dashed {
  text-decoration: dashed;
}
.dotted {
  text-decoration: dotted;
}
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
