.news-stories {
  @include flex($d: column, $g: 24px);
  margin-top: 48px;

  p,
  h4 {
    margin: 0;
    padding: 0;
  }

  &__title {
    margin: 0;
    margin-bottom: 8px;
    color: var(--Neutrals-10---Primary-Text, #14181F);

    font-family: Archivo;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
  }

  &__subtitle {
    margin: 0;
    color: var(--Neutrals-10---Primary-Text, #14181F);
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  &__container {
    @include flex($g: 24px);
    overflow-x: auto;

    &__news {
      min-width: 220px;
      width: calc(20% - 19.2px);
      overflow: hidden;
      border-radius: 12px;
      border: 1px solid var(--Neutrals-95---Card-Border, #F0F1F5);
      background: var(--Neutrals-100---White, #FFF);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
      position: relative;

      &__preview {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;

        img {
          width: 100%;
        }
      }

      &__content {
        @include flex($d: column, $g: 8px);
        padding: 12px 16px;
        margin-bottom: 24px;
      }

      &__title,
      &__description {
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      &__title {
        -webkit-line-clamp: 2;
        color: var(--Neutrals-10---Primary-Text, #14181F);

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      &__description {
        font-size: 12px;
        -webkit-line-clamp: 4;

        color: var(--Neutrals-50---Secondary-Text, #679);

        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      &__read-more-link {
        position: absolute;
        right: 16px;
        bottom: 16px;
        align-self: end;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        transition: $default-transition-time;
        letter-spacing: 1px;
        color: color('blue', 40);

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 1;
        }
      }
    }
  }
}