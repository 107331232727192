.tooltip {
  display: inline-block;
  max-width: 359px;
  padding: 5px 12px;
  border-radius: 8px;
  background-color: color("neutral", 20);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: color("neutral", 100);
  text-align: center;

  background: var(--Neutrals-10---Primary-Text, #14181F);
  box-shadow: 0px 23px 35px 0px rgba(0, 0, 0, 0.05), 0px 8.4px 23px 0px rgba(0, 0, 0, 0.06), 0px 3.6px 13px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.10);

  .nearLeftCorner-content & {
    margin-left: -8px;
  }

  &__title {
    text-align: left;
    border-bottom: 1px solid rgba(240, 241, 245, 1);
    padding-bottom: 4px;
    margin-bottom: 4px;
    color: var(--Neutrals-100---White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__text {
    text-align: left;
    color: var(--Neutrals-100---White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.nearLeftCorner-content {
  max-width: 256px;
}

.popup-content {
  z-index: 99999 !important;

  &.filter-content {
    transform: translateX(16px);
  }
}

.popup-arrow {
  color: color("neutral", 20);
}