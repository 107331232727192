.account {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &__title {
    color: color('neutral', 10);
    font-weight: 700;
  }

  &__text {
    color: color('neutral', 40);
  }
  .profile-details,
  .personal-details,
  .account-password,
  .delete-account {
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-lg-min) {
      max-width: 464px;
    }
    gap: 32px;
    padding: 36px;
    background: color('neutral', 100);
    border-radius: 16px;
  }

  .delete-account {
    max-width: none;
    gap: 8px;
  }

  &__container {
    @media (min-width: $screen-lg-min) {
      flex-direction: row;
    }
    flex-direction: column;
    display: flex;
    gap: 32px;
    &__profile-wrapper {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .delete-account {
    h2 {
      color: color('red', 55);
      margin: 0;
    }
    &__delete-button {
      align-self: start;
      padding: 14px 24px;
      color: color('red', 55);
      border: 2px solid color('red', 55);
      border-radius: 16px;
      background: transparent;
    }
  }
}
