.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-secondary;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  transition: border-color $default-transition-time, background-color $default-transition-time,
    color $default-transition-time;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline-width: 0;
  }

  &--lg {
    // .button--lg
    padding: 12px 23px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 28px;
  }

  &--sm {
    // .button--sm
    padding: 11px 15px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
  }

  &--xs {
    // .button--xs
    padding: 6px 14px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
  }

  &--block {
    // .button--block
    width: 100%;
  }

  &--warning-red {
    // .button--primary-blue
    background-color: color('red', 45);
    border-color: color('red', 45);
    color: color('neutral', 100);

    &:active {
      background-color: color('red', 70);
      border-color: color('red', 70);
    }
  }
  &--primary-blue {
    // .button--primary-blue
    background-color: color('blue', 30);
    border-color: color('blue', 30);
    color: color('neutral', 100);

    &:active {
      background-color: color('blue', 40);
      border-color: color('blue', 40);
    }
  }

  &--secondary-blue {
    // .button--secondary-blue
    background-color: $transparent;
    border-color: color('blue', 30);
    color: color('blue', 30);

    &:active {
      background-color: rgba(color('blue', 30), 0.1);
    }
  }

  &--outline {
    //.button--outline
    background-color: $transparent;
    border-color: color('neutral', 90);
    color: color('blue', 40);
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 6px;

    &--no-border {
      border: none;
    }
  }

  &--outline-gray {
    //.button--outline-gray
    background-color: $transparent;
    border-color: color('neutral', 95);
    color: color('neutral', 50);
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    font-family: $font-primary;
    font-weight: 400;

    &:active {
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.07);
    }

    &:disabled {
      background-color: color('neutral', 95) !important;
      border-color: color('neutral', 95) !important;

      &:active {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
      }
    }
  }

  &--light-blue {
    //.button--light-blue
    background-color: color('blue', 95);
    color: color('blue', 55);
    border: none;
    font-size: 14px;
    font-family: $font-primary;

    svg {
      margin-right: 9px;
    }
  }

  &--mint {
    background-color: color('mint', 50);
    border-color: color('mint', 50);
    color: #fff;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    font-family: $font-primary;
    font-weight: 400;

    &:active {
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.07);
    }

    &:disabled {
      background-color: color('mint', 50);
      border-color: color('mint', 50);
      opacity: 0.6;
    }
  }

  &--link {
    //..button--link
    border: none;
    color: color('neutral', 50);
    background-color: transparent;
    font-weight: 600;
    font-family: $font-primary;

    svg {
      margin-right: 8px;
    }
  }

  &--not-found {
    //.button--not-found
    min-width: 344px;
  }

  &--right {
    margin-left: auto;
  }

  &--loading,
  &:disabled {
    background-color: color('blue', 80);
    border-color: color('blue', 95);
    cursor: not-allowed;
    pointer-events: none;

    &:active {
      background-color: color('blue', 80);
      border-color: color('blue', 95);
    }
  }

  &--like-disabled {
    // .button--like-disabled
    background-color: color('blue', 80) !important;
    border-color: color('blue', 95) !important;
  }

  &__icon {
    // .button__icon
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    color: currentColor;

    .button--lg & {
      width: 20px;
      height: 20px;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }

    .button--sm &,
    .button--xs & {
      width: 16px;
      height: 16px;

      &:first-child {
        margin-right: 4px;
      }

      &:last-child {
        margin-left: 4px;
      }
    }

    &--test {
      width: 16px;
      height: 16px;
      color: #fff;
    }

    &--spinner {
      width: 16px;
      height: 16px;
      animation: spinner 1000ms linear infinite;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
