.dashboard-verify-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: #fd1c37;
  padding: 12px 0;

  &__text {
    color: var(--Neutrals-100---White, #fff);
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__link {
    color: var(--Neutrals-100---White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: -0.4px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  &__close {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 28px;
  }
}
