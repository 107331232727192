.auth-banner {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: none;
  width: 500px;
  height: 100vh;
  background-color: color('blue', 30);
  box-shadow: 10px 10px 40px rgba(#000, 0.05);
  color: color('neutral', 100);

  @media (min-width: $screen-lg-min) {
    display: block;
  }

  @media (min-width: $screen-xl-min) {
    width: 620px;
  }

  &__box {
    // .auth-banner__box
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 36px 40px;
    overflow-y: auto;
  }

  &__top {
    // .auth-banner__top
    display: flex;
    align-items: center;
    margin-bottom: 79px;
    transform: translateX(-13px);

    &--link {
      text-decoration: none;
      color: #fff;
    }
  }

  &__logo {
    // .auth-banner__logo
    position: relative;
    width: 132px;
    padding-right: 10px;

    &::after {
      position: absolute;
      top: 4px;
      right: 0;
      width: 2px;
      height: 18px;
      background-color: rgba(#fff, 0.1);
      border-radius: 2px;
      content: '';
    }
  }

  &__top-text {
    // .auth-banner__top-text
    margin: 5px 0 0 10px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  &__content {
    // .auth-banner__content
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 550px;
    margin-bottom: 54px;
    padding: 0 24px;
  }

  &__content-box {
    // .auth-banner__content-box
  }

  &__title {
    // .auth-banner__title
    margin-bottom: 16px;
    font-family: $font-secondary;
    font-size: 34px;
    line-height: 36px;
    font-weight: 800;

    @media (min-width: $screen-xl-min) {
      font-size: 40px;
      line-height: 42px;
      letter-spacing: -0.02em;
    }
  }

  &__text {
    // .auth-banner__text
    margin-bottom: 70px;
    font-size: 16px;
    line-height: 24px;

    @media (min-width: $screen-xl-min) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
    }
  }

  &__partners {
    // .auth-banner__partners
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto -8px 0;
    padding-left: 0;
    list-style-type: none;
    transform: translateX(-6px);
  }

  &__partners-item {
    // .auth-banner__partners-item
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%/3 * 1;
    margin-bottom: 26px;
    padding: 0 8px;
  }

  &__partners-logo {
    // .auth-banner__partners-logo
    max-width: 100%;
  }
}
