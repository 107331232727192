.file-upload {
  width: 100%;
  height: 128px;
  border-radius: 6px;
  border: 1px solid #f0f1f5;
  background-color: #f9fafb;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;

  &:hover {
    cursor:pointer;
  }

  &--active {
    // .file-upload--active
    border-bottom: 2px solid #004f99;
  }

  &--rejected {
    // .file-upload--rejected
    border-bottom: 2px solid #fd0221;
  }

  &__error-text {
    // .file-upload__error-text
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: color('red', 50);
  }

  &--sm {
    //.file-upload--sm
    height: 110px;
    max-width: 280px;
  }

  &__img {
    // .file-upload__img
    position: relative;
    margin-bottom: 18px;

    .file-upload--sm & {
      width: 28px;
      height: 22px;
      margin-bottom: 12px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    //.file-upload__label
    position: absolute;
    top: 0;
    left: 38px;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
  }

  &__title {
    //.file-upload__title
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: color('neutral', 50);
  }

  &__message {
    //.file-upload__message
    font-size: 12px;
    color: color('neutral', 50);
  }
}
