.backdrop {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#191C22, .4);
  opacity: 0;
  transition: opacity $default-transition-time;

  &--will-toggle { // .backdrop--will-toggle
    display: block;
  }

  &--show { // .backdrop--show
    display: block;
    opacity: 1;
  }
}
