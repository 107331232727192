.table-status {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;

  &__marker {
    // .table-status__marker
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;

    &--pending {
      // .table-status__marker--pending
      background-color: color('yellow', 55);
      box-shadow: 0 0 0 2px color('yellow', 80);
    }

    &--processed {
      // .table-status__marker--processed
      background-color: color('mint', 50);
      box-shadow: 0 0 0 2px color('mint', 84);
    }

    &--failed {
      // .table-status__marker--failed
      background-color: color('red', 55);
      box-shadow: 0 0 0 2px color('red', 80);
    }

    &--completed {
      // .table-status__marker--completed
      background-color: color('blue', 55);
      box-shadow: 0 0 0 2px color('blue', 80);
    }
  }

  &__loan {
    // .table-status__loan
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;

    &--created {
      // .table-status__loan--created
      background-color: color('blue', 40);
      box-shadow: 0 0 0 2px color('blue', 90);
    }

    &--payment-processing {
      // .table-status__loan--processing
      background-color: color('neutral', 50);
      box-shadow: 0 0 0 2px color('neutral', 90);
    }

    &--pending {
      // .table-status__loan--pending
      background-color: color('neutral', 50);
      box-shadow: 0 0 0 2px color('neutral', 90);
    }

    &--transfer-issue {
      // .table-status__loan--transfer-issue
      background-color: color('red', 50);
      box-shadow: 0 0 0 2px color('red', 90);
    }
    &--active {
      // .table-status__loan--active
      background-color: color('mint', 50);
      box-shadow: 0 0 0 2px color('mint', 90);
    }
    &--forgiven {
      // .table-status__loan--forgiven
      background-color: color('blue', 40);
      box-shadow: 0 0 0 2px color('blue', 90);
    }
    &--declined {
      // .table-status__loan--declined
      background-color: color('red', 50);
      box-shadow: 0 0 0 2px color('red', 90);
    }
    &--canceled {
      // .table-status__loan--canceled
      background-color: color('red', 50);
      box-shadow: 0 0 0 2px color('red', 90);
    }
    &--paid-off {
      // .table-status__loan--paid-off
      background-color: color('mint', 50);
      box-shadow: 0 0 0 2px color('mint', 90);
    }
    &--repayment_upcoming {
      // .table-status__loan--repayment_upcoming
      background-color: color('blue', 40);
      box-shadow: 0 0 0 2px color('blue', 90);
    }
    &--repayment_settled {
      // .table-status__loan--repayment_settled
      background-color: color('mint', 50);
      box-shadow: 0 0 0 2px color('mint', 90);
    }
    &--repayment-late {
      // .table-status__loan--repayment-late
      background-color: color('yellow', 50);
      box-shadow: 0 0 0 2px color('yellow', 90);
    }
    &--repayment_missed {
      // .table-status__loan--repayment_missed
      background-color: color('red', 50);
      box-shadow: 0 0 0 2px color('red', 90);
    }
    &--deactivated {
      // .table-status__loan--deactivated
      background-color: color('red', 50);
      box-shadow: 0 0 0 2px color('red', 90);
    }
  }
}
