.progress-loader {

  &__circle {// .progress-loader__circle
    stroke: color('neutral', 95);
  }

  &__txt {
    font-size: 11px;
    font-weight: 700;
    font-family: $font-primary;
    fill: color('mint', 50);
  }
}