.selectionFlow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  &__stepsBlock {
    width: 268px;
    position: absolute;
    top: 36px;
    left: 96px;
    padding: 20px;

    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(65, 77, 92, 0.20), 0px 1px 14px 0px rgba(0, 7, 22, 0.14);

    @media (max-width: 1400px) {
      width: 240px;
      left: 26px;
    }

    @media (max-width: $screen-lg-min) {
      display: none;
    }

    &__step {
      display: flex;
      gap: 8px;

      &__marker {
        position: relative;

        svg {
          width: 20px;
          height: 20px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          height: 46px;
          background: rgba(224, 228, 235, 1);
        }
      }

      &__info {
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &__number {
        color: var(--Neutrals-40---Emphasis-Text, #525F7A);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      &__name {
        color: var(--Neutrals-70---Disabled-Text, #A3ADC2);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        &--active {
          color: var(--Blue-40, #006ACC);
          font-weight: 700;
        }
      }

      &:last-child {
        .selectionFlow__stepsBlock__step__marker {

          &::after {
            display: none;
          }
        }

        .selectionFlow__stepsBlock__step__info {
          padding-bottom: 0;
        }
      }
    }
  }

  &__wrapper {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__text-color {
    color: color('neutral', 30);
  }

  &__card {
    width: calc(50% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-95---Card-Border, #F0F1F5);
    background: var(--Neutrals-100---White, #FFF);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.14);
    transition: 0.3s all;
    cursor: pointer;

    &--active,
    &:hover {
      border: 1px solid var(--Blue-30, #004F99);
      background: var(--Neutrals-100---White, #FFF);
      box-shadow: 0px 4px 20px 0px rgba(0, 53, 102, 0.15);
      transition: 0.3s all;
    }

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      &__left {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    &__logo {
      border-radius: 8px;
      border: var(--space-space-0, 1px) solid var(--Neutrals-95---Card-Border, #F0F1F5);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__title {
      color: var(--Neutrals-10---Primary-Text, #14181F);
      font-family: Archivo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &__subtitle {
      overflow: hidden;
      color: var(--Neutrals-50---Secondary-Text, #679);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    &__info {
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__imageList {
      display: flex;
      gap: 8px;
    }

    &__imageItem {
      width: calc(50% - 4px);
      border-radius: 2.4px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__selectBtn {
      padding: 6px;
      padding-left: 8px;
      padding-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;

      border-radius: 6px;
      border: 1.429px solid var(--Blue-30, #004F99);
      color: var(--Blue-30, #004F99);
      text-align: center;
      font-feature-settings: 'clig'off, 'liga'off;
      font-family: Archivo;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      cursor: pointer;
    }
  }
}