.copy-button {
  display: flex;
  background: transparent;
  border: none;
  transition: $default-transition-time;
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.9);
  }
  &__icon {
    width: 20px;
    color: color('blue', 55);
  }
  &__text {
    margin-left: 8px;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: color('blue', 55);
  }
  &:hover {
    opacity: 0.65;
  }
}
