.radio {
  cursor: pointer;
  margin-bottom: 16px;

  &--disabled {
    // .radio--disabled
    opacity: 0.5;
    pointer-events: none;
  }

  &__input {
    //.radio__input
    display: none;

    &:checked {
      & + span {
        &:before {
          border-color: color('mint', 50);
          box-shadow: inset 0 0 0 5px #fff, inset 0 0 0 12px color('mint', 50);
        }
      }
    }
  }

  &__label {
    // .radio__label
    font-size: 16px;
    color: color('neutral', 50);
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      border: 2px solid color('neutral', 80);
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
