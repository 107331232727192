.table-customize-filter {
  // .table-customize-filter
  position: relative;

  &__btn {
    //.table-customize-filter__btn

    background: #fff;
    width: 48px;
    height: 48px;
    border: 1px solid color('neutral', 90);
    border-radius: 12px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 450ms ease;

    &:hover {
      background-color: color('blue', 95);
      border-color: color('blue', 90)
    }


  }

  &__box {
    // .table-customize-filter__box
    padding: 25px 24px;
    position: absolute;
    z-index: 10;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: color('neutral', 100);
    border-radius: 16px;
    min-width: 350px;
    box-shadow: 0 4px 20px rgba(color('blue', 20), 0.15);
  }

  &__head {
    // .table-customize-filter__head
    color: color('neutral', 50);
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__close {
    // .table-customize-filter__close
    width: 12px;
    height: 12px;
    background: none;
    border: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &__list {
    // .table-customize-filter__list
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    // .table-customize-filter__item
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: color('neutral', 10);
    margin-bottom: 12px;
  }

  &__footer {
    text-align: right;
    padding-top: 10px;
  }

  &__reset {
    // .table-customize-filter__reset
    background: none;
    border: none;
    color: color('blue', 30);
    padding: 0;
    font-family: $font-secondary;
    font-weight: 700;
    margin-left: auto;
    
    &:focus {
      outline: none;
    }
  }
}