.bank {
  &__no-bank-account {
    @include flex($d: column, $a: center, $g: 44px);
    gap: 44px;
    margin-top: 24px;
    padding: 50px;
    border: 3px dashed color('neutral', 90);
    border-radius: 16px;

    &__title {
      @include flex($d: column, $a: center);
    }

    .button {
      width: 343px;

      @media (max-width: 343px) {
        width: auto;
      }
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__add-bank-account {
    max-width: 969px;
    margin: 48px auto 0;

    &__container {
      min-height: 460px;
      display: flex;
    }
  }

  &__micro-deposit-container {
    flex: 1;
    padding-right: 54px;
    border-right: 1px solid color('neutral', 90);

    &__form {
      .button {
        padding: 8px 16px;
      }
    }
  }

  &__plaid-container {
    flex: 1;
    margin-left: 54px;
    max-width: 410px;
  }

  &__plaid,
  &__delete {
    display: flex;
    flex-direction: column;
    gap: 36px;
    background: color('neutral', 100);
    border: 2px solid color('neutral', 95);
    padding: 36px 16px 21px;
    border-radius: 16px;

    .button {
      margin: 0 16px;
    }

    &__info {
      margin-left: 44px;
      display: flex;
      gap: 16px;

      &__description {
        a {
          text-decoration: none;
          color: color('blue', 40);
          font-weight: 700;
          transition: $default-transition-time;

          &:hover {
            opacity: 0.7;
          }
        }

        &__links {
          display: flex;
          margin-top: 8px;
          gap: 12px;
          line-height: 20px;

          a {
            &:last-child {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: -9px;
                width: 5px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background: color('neutral', 60);
              }
            }
          }
        }
      }
    }

    &__modal {
      position: relative;
      padding: 64px 24px;
      @include flex($d: column, $a: center);

      &__logos {
        @include flex($j: center);

        margin-bottom: 64px;
      }

      &__info {
        @include flex($d: column, $a: start, $g: 16px);
      }

      &__info-container {
        &:last-child {
          margin-bottom: 16px;
        }
      }

      &__policy-info {
        ::before {
          content: ' ';
          position: absolute;
          width: 50%;
          height: 1px;
          background-color: color('neutral', 85);
          left: 25%;
          margin-top: -16px;
        }

        padding-top: 16px;
        line-height: 20px;

        p {
          margin-bottom: 32px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;

          a {
            color: inherit;
            font-weight: 500;
          }
        }
      }

      .button {
        align-self: stretch;
      }

      &__close-button {
        padding: 0;
        position: absolute;
        border: none;
        outline: none;
        background: transparent;
        transition: $default-transition-time;
        top: 42px;
        right: 42px;
        color: color('neutral', 80);

        & svg {
          margin: 0;
        }
      }

      &__title {
        // .bank__delete__modal__title
        //styleName: Archivo Bold︱20;
        font-family: $font-secondary;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  &__delete {
    &__modal {
      padding: 26px 24px 64px;

      &__image {
        margin: 0;
      }

      &__description {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
      }

      &--padding {
        padding: 24px;
      }
    }
  }
}

.bank-accounts {
  max-width: 969px;
  margin: 0px auto;
  @include flex($d: column, $a: stretch, $g: 24px);

  &__container {
    @include flex($d: column, $a: center, $g: 8px);
  }

  &__toggle-button {
    align-self: end;
  }

  .button--secondary-blue {
    padding: 13px 46px;
  }
}

.example {
  @include flex($d: row, $a: center, $g: 10px);
}

.bank-account-card {
  margin-inline: auto;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  h6 {
    margin: 0;
  }

  &__devision {
    background-color: color('neutral', 60);
    border-radius: 100%;
    width: 4px;
    height: 4px;
  }

  &__main {
    @include flex($j: space-between, $a: center);

    padding-inline: 24px;
    padding-block: 14px;
    border-radius: 8px;
    border: 1px solid color('neutral', 85);
    background: color('neutral', 98);
    transition: $default-transition-time;

    &--active {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background: color('neutral', 95);
    }

    &:hover {
      background: color('neutral', 95);
    }
  }

  &__account {
    @include flex($j: space-between, $g: 10px);
  }

  &__img {
    position: relative;
    width: 38px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    position: absolute;
    top: -7px;
    left: -7px;
    width: 18px;
    height: 18px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    @include flex($d: column, $g: 4px);
  }

  &__details {
    @include flex($j: flex-start, $a: center, $g: 8px);
  }

  &__actions {
    @include flex($j: center, $a: center, $g: 24px);
  }

  &__verify {
    font-family: 'Roboto', 'Helvetica Neue';
    font-size: 14px;
    color: color('blue', 30);
    font-weight: 500;
    margin: 0;
    padding: 2px;
    border: none;
    background-color: transparent;
    text-decoration: unset;

    &:focus {
      outline: unset;
    }

    &:active {
      color: color('blue', 40);
    }
  }

  &__delete {
    font-size: 14px;
    color: color('blue', 30);
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: unset;

    svg {
      height: 20px;
      width: 20px;
      margin: 0;
    }

    &:focus {
      outline: unset;
    }

    &:active {
      color: color('blue', 40);
    }
  }

  &__dropdown {
    padding-inline: 24px;
    padding-block: 14px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid color('neutral', 85);
    background: color('neutral', 98);
    animation: slide-down 300ms ease-out forwards;
    @include flex($j: space-between, $a: center);
  }

  &__depositRecent {
    @include flex($d: column, $j: flex-start, $a: flex-start, $g: 4px);

    &-lists {
      @include flex($j: flex-start, $a: center);

      >* {
        border-right: 1px solid color('neutral', 85);
      }
    }

    &-item {
      padding-inline: 16px;
      padding-block: 8px;
      @include flex($d: column, $j: flex-start, $a: center, $g: 8px);
    }
  }

  &__deposit {
    padding: 8px;
    @include flex($d: column, $j: flex-start, $a: flex-end, $g: 4px);

    &-info {
      @include flex($d: column, $a: center, $g: 4px);
    }

    &-amount {
      @include flex($a: center, $g: 8px);
    }

    &-refresh {
      font-family: 'Roboto', 'Helvetica Neue';
      font-size: 16px;
      color: color('blue', 30);
      font-weight: 500;
      margin: 0;
      padding: 2px;
      border: none;
      background-color: transparent;
      text-decoration: unset;

      &:focus {
        outline: unset;
      }

      &:active {
        color: color('blue', 40);
      }
    }
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}