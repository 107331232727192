.datepicker {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 16px;
  text-align: left;
  min-width: 280px;

  &--no-margin {
    // .datepicker--no-margin
    padding-bottom: 0;
  }

  &--error {
    // .datepicker--error
    margin-bottom: 10px;
  }

  .datepicker--date-filter & {
    padding-bottom: 0;
  }

  &__box {
    // .datepicker__box
    position: relative;
  }

  &__input {
    // .datepicker__input
    display: block;
    width: 100%;
    min-width: 0;
    margin-bottom: 8px;
    cursor: pointer;
    user-datepicker: none;
    transition: border-color $default-transition-time, box-shadow $default-transition-time;

    &--disabled {
      background-color: #f0f1f5 !important;
      border-color: #f0f1f5;
      color: #8592ad;
      -webkit-text-fill-color: #8592ad;
      opacity: 1;
      cursor: not-allowed;

      svg {
        color: #8592ad !important;
      }
    }

    &--role-datepickered {
      // .datepicker__input--role-datepickered
      padding-left: 40px;
    }

    .datepicker--opened > * > & {
      border-color: color('blue', 30);
      box-shadow: 0 0 0 1px color('blue', 30);
    }

    .datepicker--disabled & {
      background-color: color('neutral', 95);
      border-color: color('neutral', 95);
      color: color('neutral', 60);
      cursor: not-allowed;

      &:focus {
        box-shadow: none;
      }
    }

    .datepicker--flag & {
      padding-left: 38px;
    }

    .datepicker--disabled & {
      background-color: color('neutral', 95);
      border-color: color('neutral', 95);
      color: color('neutral', 60);
    }

    .datepicker--date-filter & {
      margin-bottom: 0;
      padding-left: 56px;
      font-weight: 500;
      color: color('blue', 30);
    }

    .datepicker--no-margin & {
      margin-bottom: 0;
    }

    .datepicker--error & {
      border-color: color('red', 55);
      box-shadow: 0 0 0 1px color('red', 55);
    }
  }

  &__chevron {
    // .datepicker__chevron
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    display: flex;
    align-items: center;
    width: 36px;
    border-width: 0;
    padding: 0;
    background-color: color('neutral', 100);
    border-radius: 17px;

    &:not(button) {
      pointer-events: none;
    }

    &:focus {
      outline-width: 0;
    }

    .datepicker--disabled > * > & {
      background-color: color('neutral', 95);
    }

    .datepicker--small > * > & {
      width: 29px;
    }
  }

  &__chevron-icon {
    // .datepicker__chevron-icon
    width: 24px;
    height: 24px;
    color: color('neutral', 30);

    .datepicker--opened > * > * > & {
      transform: scaleY(-1);
    }

    .datepicker--disabled > * > * > & {
      color: color('neutral', 60);
    }

    .datepicker--small & {
      width: 16px;
      height: 16px;
    }
  }

  &__flag {
    // .datepicker__flag
    width: 21px;
    height: 14px;
    margin-right: 5px;
    border-radius: 3px;

    .datepicker--disabled & {
      opacity: 0.5;
    }
  }

  &__current-flag {
    // .datepicker__current-flag
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  &__options {
    // .datepicker__options
    display: none;
    position: absolute;
    z-index: 100;
    top: 60px;
    width: 100%;
    background-color: color('neutral', 100);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(color('blue', 20), 0.15);
    list-style-type: none;
    flex-direction: column;
    padding: 16px;
    width: 97vw;

    .datepicker--opened & {
      display: flex;
    }

    @media (min-width: $screen-sm-min) {
      left: -46vw;
    }

    @media (min-width: $screen-md-min) {
      left: -32vw;
    }

    @media (min-width: $screen-lg-min) {
      left: -400px;
      flex-direction: row;
      width: 880px;
    }

    @media (min-width: $screen-xxl-min) {
      left: auto;
      right: 0;
    }

    &__zirtue-pay {
      right: 0;
      left: unset;

      @media (max-width: 992px) {
        width: auto;
      }
    }
  }

  &__option {
    // .datepicker__option
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    border: 2px solid $transparent;
    cursor: pointer;
    transition: border-color $default-transition-time, background-color $default-transition-time,
      color $default-transition-time;

    &:hover,
    &--datepickered {
      // .datepicker__option--datepickered
      border-color: color('blue', 30);
      color: color('blue', 30);
      background-color: rgba(color('blue', 30), 0.05);
    }
  }

  &__option-section {
    // .datepicker__option-section
    width: 100%;
    margin-top: 20px;

    @media (min-width: $screen-lg-min) {
      margin: 0 0 0 48px;
      width: calc(47% - 48px);
    }
  }

  &__error-text {
    // .datepicker__error-text
    font-size: 14px;
    line-height: 16px;
    color: color('red', 55);
  }

  /*
  --------------------------------------------------
  Date filter datepicker
  --------------------------------------------------
  */

  &--date-filter {
    // .datepicker--date-filter
    padding-bottom: 0;
  }

  &__date-input-prefix {
    // .datepicker__date-input-prefix
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: color('neutral', 50);
  }

  &__date-option-list {
    // .datepicker__date-option-list
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
  }

  &__date-option {
    // .datepicker__date-option
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &--datepickered {
      // .datepicker__date-option--datepickered
      font-weight: 500;
      color: color('blue', 30);
    }
  }

  &__date-option-icon {
    // .datepicker__date-option-icon
    display: none;
    width: 16px;
    height: 16px;
    color: color('mint', 50);

    .datepicker__date-option--datepickered & {
      display: block;
    }
  }

  &__date-range {
    // .datepicker__date-range
    margin: 0 10px;
    padding: 14px 0;
    border-top: 1px solid color('neutral', 95);
  }

  &__date-range-title {
    // .datepicker__date-range-title
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }

  &__date-range-form {
    // .datepicker__date-range-form
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date-range-item {
    // .datepicker__date-range-item
    width: 45%;
  }

  &__date-range-separator {
    // .datepicker__date-range-separator
    width: 10%;
    color: color('neutral', 50);
    font-size: 14px;
    margin-top: 24px;
    text-align: center;
  }

  &__date-range-input {
    // .datepicker__date-range-input
    flex-grow: 1;
    max-width: 100%;
  }

  &__date-range-icon {
    // .datepicker__date-range-icon
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    color: color('neutral', 90);
  }

  /*
  --------------------------------------------------
  Role datepicker
  --------------------------------------------------
  */

  &__role {
    // .datepicker__role
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  &__error-text {
    // .tag-area__error-text
    font-size: 14px;
    line-height: 16px;
    color: color('red', 55);
  }

  &__relative {
    display: flex;
    align-items: center;
  }

  &__relative-txt {
    margin-right: 14px;
    font-size: 14px;
    color: #667799;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }

  &__btn {
    width: 80px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    font-family: $font-secondary;
    border-radius: 8px/6px;
    background-color: color('blue', 30);
    color: #fff;
    border: none;

    &--cancel {
      border: 2px solid color('blue', 30);
      color: color('blue', 30);
      background: none;
      margin-right: 12px;
    }

    &:disabled {
      background: color('blue', 95);
      color: color('blue', 80);
      cursor: default;
    }
  }
}
