@import '../../../scss/utils/_normalize.scss';
@import '../../../scss/utils/_reboot.scss';
@import '../../../scss/vars/_colors.scss';
@import '../../../scss/utils/_functions.scss';
@import '../../../scss/vars/_base.scss';
@import '../../../scss/vars/_sizes.scss';
@import '../../../scss/vendor/_vendor.scss';

.loans {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__multiStepForm {
    &__form {
      height: 100%;
      display: flex;
      flex-direction: column;

      &__container {
        height: calc(100% - 8px - 86px);
        overflow-y: auto;

        &--centered {
          justify-content: center;
        }

        &--withoutFooter {
          height: 100%;
        }

        &__loader {
          min-height: 600px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 45px;

          &__text {
            color: var(--Neutrals-50---Secondary-Text, #679);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.256px;
          }
        }
      }
    }
  }

  &__modal {
    padding: 32px 24px;
    padding-bottom: 40px;

    &__close {
      border-radius: var(--border-radius-border-radius-pill, 100px);
      background: #fff;

      box-shadow: 0px 0px 4px 2px rgba(0, 79, 153, 0.15);

      width: 40px;
      height: 40px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__iconCenter {
      margin: 0 auto;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 86px;
      height: 86px;
      border-radius: var(--border-radius-border-radius-pill, 100px);
      background: var(--Red-95---Background, #ffe6e9);
    }

    &__overtitle {
      font-feature-settings: 'clig' off, 'liga' off;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(90deg, #0084ff 0%, #66b5fe 19.19%, #2895f9 55.73%, #006acc 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
    }

    &__title {
      margin-bottom: 24px;
      color: var(--Blue-20---Overlay, #003566);
      text-align: center;
      font-family: Archivo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
    }

    &__body {
      margin: 0 50px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__footer {
      margin: 0 50px;
      margin-top: 40px;

      &--isCancelLoan {
        margin: 0;
        margin-top: 40px;
        display: flex;
        gap: 16px;
      }
    }

    &__text {
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &--isCancelLoan {
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
        text-align: center;

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      list-style-position: inside;
      padding-left: 24px;
      margin-bottom: 0;
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &__item {
        list-style-position: outside;
        margin-left: -8px;
      }
    }

    &__supportBlock {
      display: flex;
      gap: 12px;

      &__left {
        position: relative;
        width: 45px;
        height: 45px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__avatar {
        border-radius: 200px;
        border: 3px solid var(--Neutrals-100---White, #fff);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
        overflow: hidden;

        &__hand {
          position: absolute;
          bottom: -2px;
          right: -2px;
        }
      }

      &__right {
        width: calc(100% - 45px - 12px);
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    &__modalCard {
      display: flex;
      justify-content: center;
    }

    &__subtitle {
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-feature-settings: 'clig' off, 'liga' off;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &__link {
      margin: 0 4px;
      color: var(--Blue-40, #006acc);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .selection {
    display: flex;
    gap: 16px;
  }

  &__wrapper {
    padding: 0 20px;
  }

  &__footer {
    border-top: 2px solid #e9ebed;
    padding: 0 20px;
    padding-top: 21px;
    padding-bottom: 23px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--lastStep {
      justify-content: space-between;
    }

    &--noBorder {
      border-top: 2px solid transparent;
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      &--lastStep {
        gap: 24px;
      }

      &--retry {
        gap: 12px;
      }
    }

    &__totalAmount {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &__label {
        color: var(--Neutrals-50---Secondary-Text, #679);
        text-align: right;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      &__value {
        color: var(--Blue-20---Overlay, #003566);
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;

        font-family: Archivo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  &__complete-text {
    margin: 20px 0;
    color: var(--Neutrals-50---Secondary-Text, #679);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    display: flex;
    width: 124px;
    height: 42px;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 16px;
    transition: all $default-transition-time;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Blue-30, #004f99);

    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Archivo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;

    &:disabled {
      background: var(--Blue-95---Background, #e5f3ff);
      color: color('blue', 80);
      border: none;

      & > svg {
        color: color('blue', 80);
      }
    }

    &__progress {
      padding: 24px;
    }

    &--prev {
      padding: 2px 27px;
      outline: none;
      border: none;
      background: transparent;
      color: var(--Blue-30, #004f99);
      font-family: Archivo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;

      &--isDisabled {
        cursor: auto;
        color: var(--Blue-80, #99ceff);
      }
    }

    &--complete {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 0;
      color: var(--Neutrals-100---White, #fff);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Archivo;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    &--secondary {
      background: #fff;
    }
  }

  &__title {
    padding: 4px 0;
    color: var(--Neutrals-10---Primary-Text, #14181f) !important;
    font-family: Archivo !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: -0.36px !important;
  }

  &__descr {
    color: var(--Neutrals-50---Secondary-Text, #679) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
  }

  &__loanRepaymentRequests,
  &__selectBorrowerForm,
  &__selectLoanTypeBorrowerForm,
  &__selectLoanRepaymentPlanForm,
  &__selectLoanSummaryForm,
  &__selectLoanSummaryStatus {
    display: flex;
    justify-content: center;

    &__container {
      width: 46%;
      margin-top: 54px;
    }

    &__input-groups {
      margin-top: 40px;
    }

    &__input-label {
      margin-bottom: 8px;
      color: var(--Neutrals-50---Secondary-Text, #679);

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__loanRepaymentRequests {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px 32px;
      border-radius: 24px;
      background: var(--Neutrals-100---White, #fff);
      box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
    }

    &__divider {
      width: 100%;
      height: 1px;
      background: #f0f1f5;
    }

    &__item {
      display: flex;
      gap: 16px;

      &__image {
        min-width: 44px;
        width: 44px;
        height: 44px;
        border-radius: 60px;
        border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
        background: var(--Neutrals-100---White, #fff);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
        }
      }

      &__descr {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &__title {
        color: var(--Neutrals-10---Primary-Text, #14181f);
        font-feature-settings: 'clig' off, 'liga' off;

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &__subtitle {
        color: var(--Neutrals-50---Secondary-Text, #679);

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &__selectLoanTypeBorrowerForm {
    &__body {
      margin-top: 40px;
      display: flex;
      gap: 32px;
    }

    &__typeSelect {
      width: calc(50% - 16px);
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      border-radius: 16px;
      border: 2px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: #fff;
      box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.06);
      transition: 0.3s all;
      cursor: pointer;

      &:hover,
      &--active {
        border: 2px solid var(--Blue-40, #006acc);
        background: #f5faff;
        transition: 0.3s all;
      }

      &__descr {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__image {
        width: 40px;
        height: 40px;
      }

      &__title {
        color: var(--Blue-20---Overlay, #003566);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.076px;
      }
    }
  }

  &__selectLoanTypeBusinessForm {
    display: flex;
    justify-content: center;

    &__container {
      width: 46%;
      margin-top: 54px;
      margin-bottom: 96px;
    }

    &__body {
      margin-top: 40px;
      padding: 8px;
      border-radius: 16px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: var(--background-elevated, #fff);

      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    }

    &__input {
      margin: 0 46px;
      margin-top: 8px;
    }

    &__selectItems {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__selectItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      border-radius: 10px;
      border: 2px solid transparent;
      background-color: transparent;
      transition: 0.3s all;
      cursor: pointer;

      &__dot {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #dee1e4;
        transition: 0.3s all;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        transition: 0.3s all;
        background: var(--Neutrals-98---Base-Background, #f9fafb);
      }

      &--active {
        transition: 0.3s all;

        .loans__selectLoanTypeBusinessForm__selectItem__dot {
          border: 7px solid #006acc;
          transition: 0.3s all;
        }
      }

      &--focused {
        border: 2px solid var(--Blue-40, #006acc);
        background: var(--Neutrals-98---Base-Background, #f9fafb);
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &__imageBlock {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: var(--Blue-95---Background, #e5f3ff);
        box-shadow: 4px 12px 40px 6px rgba(28, 40, 51, 0.09);
      }

      &:last-child {
        .loans__selectLoanTypeBusinessForm__selectItem__imageBlock {
          background: var(--neutral-neutral-30-border, #ebedef);
        }
      }
    }
  }

  &__selectLoanReasonTypeForm {
    display: flex;
    justify-content: center;

    &__container {
      width: 30%;
      margin-top: 54px;
      margin-bottom: 96px;
    }

    &__body {
      margin-top: 40px;
      padding: 8px;
      border-radius: 16px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: var(--background-elevated, #fff);

      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    }

    &__input {
      margin: 0 46px;
      margin-top: 8px;
    }

    &__selectItems {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__selectItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      border-radius: 10px;
      border: 2px solid transparent;
      background-color: transparent;
      transition: 0.3s all;
      cursor: pointer;

      &__dot {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #dee1e4;
        transition: 0.3s all;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        transition: 0.3s all;
        background: var(--Neutrals-98---Base-Background, #f9fafb);
      }

      &--active {
        transition: 0.3s all;

        .loans__selectLoanReasonTypeForm__selectItem__dot {
          border: 7px solid #006acc;
          transition: 0.3s all;
        }
      }

      &--focused {
        border: 2px solid var(--Blue-40, #006acc);
        background: var(--Neutrals-98---Base-Background, #f9fafb);
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &__imageBlock {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: var(--Blue-95---Background, #e5f3ff);
        box-shadow: 4px 12px 40px 6px rgba(28, 40, 51, 0.09);
      }

      &:first-child,
      &:nth-child(2) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: var(--purple-purple-50, #efecfe);
        }
      }

      &:nth-child(3),
      &:last-child {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: var(--neutral-neutral-30-border, #ebedef);
        }
      }

      &:nth-child(4),
      &:nth-child(12) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: #ceeefd;
        }
      }

      &:nth-child(5),
      &:nth-child(9) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: #fee6e8;
        }
      }

      &:nth-child(6) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: #dde6f8;
        }
      }

      &:nth-child(7) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: #fbebd0;
        }
      }

      &:nth-child(8),
      &:nth-child(10) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: var(--green-green-50, #e9f7ec);
        }
      }

      &:nth-child(11) {
        .loans__selectLoanReasonTypeForm__selectItem__imageBlock {
          background: var(--blue-blue-50, #e5f0ff);
        }
      }
    }
  }

  &__selectAmountForm {
    display: flex;
    justify-content: center;

    &__container {
      width: 46%;
      margin-top: 54px;
    }

    &__body {
      margin-top: 40px;
    }

    &__infoBlock {
      margin-top: 16px;
    }

    &__summaryBlocks {
      padding: 24px;
      padding-bottom: 32px;
      border-radius: 16px;
      border: var(--space-space-0, 1px) solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: var(--Neutrals-100---White, #fff);
      box-shadow: 0px 0px 1px 0px rgba(61, 71, 92, 0.1), 0px 4px 10px 0px rgba(61, 71, 92, 0.05);
    }

    &__summaryBlock {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__label {
        display: flex;
        align-items: center;
        gap: 4px;

        color: var(--Neutrals-50---Secondary-Text, #679);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &__icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }

        &__badge {
          width: 38px;
          border-radius: 200px;
          background: var(--Blue-95---Background, #e5f3ff);
          display: flex;
          justify-content: center;
          align-items: center;

          &__text {
            background: linear-gradient(93deg, #1890ff 4.45%, rgba(24, 75, 255, 0.69) 100.33%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: Roboto;
            font-size: 9px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.45px;
          }
        }
      }

      &__text {
        max-width: 205px;
        color: var(--neutral-neutral-400-text-weak, var(--neutral-neutral-400-text-weak, #5c6d7d));
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      &__price {
        color: var(--Blue-20---Overlay, #003566);
        text-align: right;
        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &__label {
      margin-bottom: 8px;
      color: var(--Neutrals-60---Tertiary-Text, #8592ad);
      font-feature-settings: 'clig' off, 'liga' off;

      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &__divider {
      margin: 24px 0;
      height: 1px;
      width: 100%;
      background: #f0f1f5;
    }
  }

  &__selectLoanRepaymentPlanForm {
    &__body {
      margin-top: 32px;
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__buttons {
      display: flex;
      gap: 24px;
    }

    &__typeSelect {
      width: calc(50% - 12px);
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      border-radius: 16px;
      border: 2px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: #fff;
      box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.06);
      transition: 0.3s all;
      cursor: pointer;

      &:hover,
      &--active {
        border: 2px solid var(--Blue-40, #006acc);
        background: #f5faff;
        transition: 0.3s all;
      }

      &__descr {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__image {
        width: 40px;
        height: 40px;
      }

      &__title {
        color: var(--Blue-20---Overlay, #003566);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.076px;
      }
    }

    &__info {
      margin-bottom: 12px;
      color: var(--Neutrals-50---Secondary-Text, #679);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.256px;
    }

    &__planBlock {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      padding-top: 28px;
      border-radius: 16px;
      border: var(--space-space-0, 1px) solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: var(--Neutrals-100---White, #fff);
      box-shadow: 0px 0px 1px 0px rgba(61, 71, 92, 0.1), 0px 4px 10px 0px rgba(61, 71, 92, 0.05);

      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__label,
      &__date {
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: var(--Neutrals-50---Secondary-Text, #679);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &__date {
        font-size: 12px;
        line-height: 18px;
      }

      &__value {
        color: var(--Blue-20---Overlay, #003566);
        text-align: right;

        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      &__divider {
        height: 1px;
        width: 100%;
        background: #f0f1f5;

        &__hide {
          display: none;
        }
      }
    }

    &__button {
      border-radius: 8px;
      border: 1px solid var(--Blue-30, #004f99);
      background: var(--Neutral-White-000, #fff);
      box-shadow: 0px 1px 1px 0px rgba(9, 30, 66, 0.25), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
      color: var(--Blue-30, #004f99);
      font-family: Archivo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    &__datePicker {
      width: 80%;
      margin: 0 auto;
      margin-top: 24px;
    }
  }

  &__selectLoanSummaryForm,
  &__selectLoanSummaryStatus {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 55%;
    }

    &__body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 55%;
      margin-top: 40px;
    }

    @media (max-width: 1400px) {
      &__container {
        width: 65%;
      }

      &__body {
        width: 65%;
      }
    }

    @media (max-width: 1200px) {
      &__container {
        width: 75%;
      }

      &__body {
        width: 75%;
      }
    }

    @media (max-width: 992px) {
      &__container {
        width: 85%;
      }

      &__body {
        width: 85%;
      }
    }

    &__title {
      margin-top: 32px;
      margin-bottom: 12px;
      color: var(--Blue-20---Overlay, #003566);
      font-family: Archivo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &__loansCoverageBlock {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 20px;
      border-radius: 16px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: var(--neutral-neutral-00-surface, #fff);
      box-shadow: 0px 0.575px 1.149px 0px rgba(0, 0, 0, 0.14),
        0px 0px 1.724px 0px rgba(0, 0, 0, 0.12);

      &__body {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &__descr {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: calc(100% - 16px - 54px);
      }

      &__title {
        color: var(--Blue-20---Overlay, #003566);
        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      &__subtitle {
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &__iconBlock {
        width: 54px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid var(--Neutrals-90---Input-Border, #e0e4eb);
        background: var(--Neutrals-100---White, #fff);
        box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.03);
      }

      &__button {
        color: var(--Neutrals-100---White, #fff);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      &__footer {
        width: 100%;
      }
    }

    &__loansDetails {
      border-radius: 16px;
      box-shadow: 0px 0px 1px 0px rgba(61, 71, 92, 0.1), 0px 4px 10px 0px rgba(61, 71, 92, 0.05);

      &__mb96 {
        margin-bottom: 96px;
      }

      &__head {
        padding: 16px;
        border-bottom: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      }

      &__subtitle {
        color: var(--Neutrals-10---Primary-Text, #14181f);

        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      &__body {
        background: #fff;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        border-radius: 16px;

        &--hasFooter {
          border-radius: 16px 16px 0 0;
        }

        &--detailsBlock {
          padding: 24px 0;
        }
      }

      &__footer,
      &__infoBlock {
        padding: 12px 16px 14px 16px;
        border-radius: 0px 0px 16px 16px;
        background: var(--Blue-95---Background, #e5f3ff);
        display: flex;
        align-items: center;
        gap: 12px;

        &--isColorRed {
          background: var(--Red-95---Background, #ffe6e9);
        }

        &--isColorBlue {
          background: var(--Blue-95---Background, #e5f3ff) !important;
        }

        &__iconBlock {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius-border-radius-pill, 100px);
          background: var(--Blue-90, #cce6ff);

          &--isColorRed {
            background: var(--Red-90, #ffccd3);
          }

          &--isColorBlue {
            background: var(--Blue-95---Background, #cce6ff) !important;
          }
        }

        &__descr {
          width: calc(100% - 12px - 24px);
          display: flex;
          align-items: center;
          gap: 4px;
        }

        &__text {
          color: var(--Blue-40, #006acc);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          &--isColorRed {
            color: var(--Red-50, #fd0221);
          }

          &--isColorBlue {
            color: var(--Blue-40, #006acc) !important;
          }
        }

        &__link {
          display: inline;
          color: var(--Blue-40, #006acc);
          font-family: Archivo;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.12px;

          padding: 0;
          background: none;
          outline: none;
          border: none;

          &--isColorRed {
            color: var(--Red-50, #fd0221);
            font-weight: 500;
          }

          &--bold {
            font-weight: 700;
          }

          &:focus {
            outline: none;
          }
        }
      }

      &__infoBlock {
        padding: 12px 16px 14px 16px;
        border-radius: 0;
        background: var(--Blue-95---Background, #e5f3ff);
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &__innerBody {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }

      &__row {
        display: flex;
        justify-content: space-between;

        &--mt16 {
          margin-top: 16px;
        }

        &--mb16 {
          margin-bottom: 16px;
        }

        &--mt32 {
          margin-top: 32px;
        }

        &--mb32 {
          margin-bottom: 32px;
        }

        &--hasPadding {
          padding: 0 16px;
        }
      }

      &__bankAccountBlock {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 16px;
        border-radius: 12px;
        border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
        background: var(--Neutrals-100---White, #fff);

        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 9px;
          border-radius: 4px;
          border: var(--space-space-0, 1px) solid var(--Neutrals-95---Card-Border, #f0f1f5);
          background: #fff;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
        }

        &__label {
          color: var(--Neutrals-50---Secondary-Text, #679);

          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }

        &__value {
          color: var(--Neutrals-10---Primary-Text, #14181f);

          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      &__label {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--Neutrals-50---Secondary-Text, #679);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &__value {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--Blue-20---Overlay, #003566);
        text-align: right;
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        &--second {
          padding-right: 20px;
        }
      }

      &__accordion {
        padding: 0;
        background: none;
        outline: none;
        border: none;

        &:focus {
          outline: none;
        }

        svg {
          transition: 0.3s all;
        }

        &--active {
          svg {
            transition: 0.3s all;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__selectLoanSummaryStatus {
    position: relative;

    &__baseName {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      margin: 0 auto;
      margin-bottom: 28px;

      border-radius: 200px;
      border: 3px solid var(--Neutrals-100---White, #fff);
      background: var(--Blue-95---Background, #e5f3ff);
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07), 0px -1px 0px 0px #d4d4d4 inset,
        -1px 0px 0px 0px #e3e3e3 inset, 1px 0px 0px 0px #e3e3e3 inset, 0px 1px 0px 0px #e3e3e3 inset;
      color: var(--Blue-40, #006acc);
      text-transform: capitalize;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Archivo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    &__cardBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin: 0 auto;
      margin-bottom: 16px;
      border-radius: 200px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: var(--Neutrals-100---White, #fff);
    }
  }

  &__link {
    position: relative;
    padding-top: 32px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__sparklessBlock {
      display: flex;
      justify-content: center;
    }

    &__close {
      top: 32px;
    }

    &__title {
      margin-top: 20px;
      color: var(--Blue-20---Overlay, #003566);
      text-align: center;
      font-family: Archivo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
    }

    &__subtitle {
      margin-top: 12px;
      color: var(--Neutrals-50---Secondary-Text, #679);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.256px;
    }

    &__body {
      width: 30%;
      margin-top: 24px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: #fff;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07), 0px -1px 0px 0px #d4d4d4 inset,
        -1px 0px 0px 0px #e3e3e3 inset, 1px 0px 0px 0px #e3e3e3 inset, 0px 1px 0px 0px #e3e3e3 inset;

      &--isModal {
        width: 75%;
      }

      &__title {
        margin-bottom: 6px;
        color: var(--Neutrals-50---Secondary-Text, #679);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.256px;
      }

      &__subtitle {
        margin-bottom: 4px;
        color: var(--Neutrals-50---Secondary-Text, #679);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.256px;
      }

      &__qrcodeBlock {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6px;
        border: 1px solid rgb(224, 224, 224);
      }

      &__linkButton {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 4px;
        background: none;
        border: none;
        outline: none;

        color: var(--Blue-40, #006acc);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.256px;

        &:focus {
          outline: none;
        }
      }
    }

    &__divider {
      margin: 16px 0;
      width: 58%;
      height: 1px;
      background: #f0f1f5;
    }
  }
}

.loans-overview {
  // .loans-overview

  &__empty-state {
    // .loans-overview__empty-state
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    margin-bottom: 48px;

    &__title {
      // .loans-overview__empty-state__title
      margin-top: 24px;
      margin-bottom: 8px;
      font-family: Archivo;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #003566;
    }

    &__text {
      // .loans-overview__empty-state__text
      width: 237px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: color('neutral', 50);
    }
  }

  &__title-wrapper {
    // .loans-overview__title-wrapper
    position: relative;
  }

  &__create-button {
    // .loans-overview__create-button
    position: absolute;
    right: 0;
    top: 0;
    width: 118px;
    height: 32px;
    font-family: Archivo;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 6px 16px 6px 12px;
    gap: 8px;
    border-radius: 8px;

    svg {
      width: 16px !important;
      height: 16px !important;
      margin: 0 !important;
    }
  }

  &__notification {
    // .loans-overview__notification
    margin-bottom: 25px;
    padding: 15px;
    display: flex;
    align-items: 'baseline';
    background-color: #fef6e7;
    border-radius: 4px;
    border: 1px solid #f0f1f5;

    & svg {
      margin-top: 3px;
    }

    &__title {
      // .loans-overview__notification__title
      margin-bottom: 5px;
      color: var(--Neutrals-10---Primary-Text, #14181f);
      font-family: Roboto;
      font-size: 13.134px;
      font-style: normal;
      font-weight: 600;
      line-height: 18.762px; /* 142.857% */
    }

    &__subtitle {
      // .loans-overview__notification__subtitle
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-family: Roboto;
      font-size: 13.134px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.762px; /* 142.857% */
    }
  }

  &__state-card {
    // .loans-overview__state-card
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    &__title {
      // .loans-overview__state-card__title
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: color('neutral', 10);
    }

    &__loading-content {
      display: flex;
      justify-content: center;
    }

    &__default-content {
      // .loans-overview__state-card__default-content
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__text {
        // .loans-overview__state-card__default-content__text
        margin-top: 2%;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: color('neutral', 50);
      }
    }

    &__error-content {
      // .loans-overview__state-card__error-content
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        margin-right: 2px;
        margin-left: 4px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #fd0221;
      }

      & div {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #004f99;
        cursor: pointer;
      }

      & svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__recent-news-title {
    // .loans-overview__recent-news-title
    margin-bottom: 7px;
    font-family: Archivo;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.36000001430511475px;
    text-align: left;
    color: color('neutral', 10);
  }

  &__recent-news-subtitle {
    // .loans-overview__recent-news-subtitle
    margin-bottom: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: color('neutral', 50);
  }

  &__lineChart-section-zp {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1300px) {
      flex-direction: column;
    }

    &__graph {
      padding: 24px;
      height: 182px;

      @media (max-width: 1300px) {
        height: auto;
      }
    }

    &__icon {
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: 1px solid var(--Blue-85---Memoji-Base, #b3daff);
      background: var(--Blue-90, #cce6ff);

      @media (max-width: 1440px) {
        height: 56px;
        width: 56px;

        svg {
          width: 80%;
        }
      }

      @media (max-width: 1263px) {
        height: 40px;
        width: 40px;
        border-radius: 8px;

        svg {
          width: 70%;
        }
      }
    }
  }

  &__metric-section,
  &__metric-section-zp {
    // .loans-overview__metric-section
    display: flex;
    gap: 30px;

    canvas {
      max-width: 217px;
      max-height: 217px;
    }

    & > div {
      width: 33%;
    }

    &--analytics {
      // .loans-overview__metric-section--analytics
      padding: 24px;
      padding-bottom: 0;
      border-bottom: 4px solid #dddfe2;

      & > div {
        width: 50%;
      }
    }

    &__analytic-value {
      // .loans-overview__metric-section__analytic-value
      margin-top: 24px;
      margin-bottom: 16px;
      font-family: Roboto;
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.02em;
      text-align: left;
      color: color('neutral', 10);
    }

    &__small-section-value {
      // .loans-overview__metric-section__small-section-value
      margin-right: 9px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: color('neutral', 10);
    }

    &__percent-block {
      // .loans-overview__metric-section__percent-block
      display: flex;
    }

    &__percent-container {
      // .loans-overview__metric-section__percent-block
      margin-right: 8px;
      display: flex;
      padding: 2px 8px 2px 6px;
      background-color: #cce6ff;
      border-radius: 16px;
      align-items: center;
      width: fit-content;
    }

    &__percent-value {
      // .loans-overview__metric-section__percent-value
      margin-left: 2px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      color: #004f99;
    }

    &__percent-text {
      // .loans-overview__metric-section__percent-text
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: color('neutral', 50);
    }

    &__title {
      // .loans-overview__metric-section__title
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: color('neutral', 10);
    }

    &__title-secondary {
      // .loans-overview__metric-section__title-secondary
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: color('neutral', 10);
    }

    &__days-container {
      // .loans-overview__metric-section__days-container
      display: flex;
      margin-top: 13px;
      margin-bottom: 15px;

      &__tab {
        // .loans-overview__metric-section__days-container__tab
        width: 33%;
        padding-bottom: 8px;
        text-align: center;
        border-bottom: 2px solid #d1d6e0;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: color('neutral', 50);
      }

      &__tab--active {
        // .loans-overview__metric-section__days-container__tab--active
        font-family: Roboto;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: color('neutral', 10);
        border-bottom: 2px solid #003566;
      }
    }

    &__filterButton {
      border-radius: 8px;
      border: 2px solid var(--Blue-30, #004f99);
      color: var(--Blue-30, #004f99);
      padding: 8px 16px 8px 12px;
      height: 42px;

      font-family: Archivo;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    &__chartSwitchButtons {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__chartSwitchButton {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 12px;
      border-radius: 6px;
      border: 1px solid rgba(31, 35, 40, 0.15);
      background: var(--Neutrals-100---White, #fff);
      box-shadow: 0px 1px 0px 0px rgba(27, 31, 35, 0.1);

      color: var(--Neutrals-70---Disabled-Text, #a3adc2);
      font-family: Archivo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      &__isActive {
        color: var(--Neutrals-10---Primary-Text, #14181f);

        svg {
          path {
            stroke: #14181f;
          }
        }
      }
    }
  }

  &__metric-section-zp {
    &__lineChart {
      width: 120px;
      height: 64px;
      align-self: end;

      @media (max-width: 1440px) {
        width: 100px;
      }

      @media (max-width: 1300px) {
        width: 100%;
        height: 50%;
      }
    }

    canvas {
      max-width: unset;
      max-height: unset;

      @media (max-width: 1300px) {
        width: 100% !important;
        height: auto !important;
        aspect-ratio: unset;
        max-height: 120px !important;
      }
    }
  }
}

.triangle {
  width: 6px;
  height: 6px;
  background-image: linear-gradient(to bottom right, transparent 50%, #004f99 0),
    linear-gradient(to top right, #004f99 50%, transparent 0);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left, right;
}

.triangle-down {
  transform: rotate(180deg);
}

.rc-slider-rail {
  background-color: #99ceff !important;
}

.rc-slider-track {
  background-color: #1890ff !important;
}

.rc-slider-handle {
  background-color: #1890ff !important;
  border: none !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3) !important;
}

.loan {
  // loan

  &__title {
    font-family: Archivo;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: color('neutral', 10);
  }

  &__tabs {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  &__tab--active {
    display: flex;
    gap: 4px;
    padding: 8px 14px 8px 12px;
    font-family: Archivo;
    font-size: 13.46px;
    font-weight: 600;
    line-height: 15.38px;
    text-align: center;
    color: color('blue', 40);
    border-radius: 12px;
    background-color: color('blue', 95);
    border: 1px solid color('blue', 90);

    &:focus {
      border: 1px solid color('blue', 90);
      outline: none;
    }

    & path {
      fill: color('blue', 40);
      stroke: color('blue', 40);
    }
  }

  &__tab--disabled {
    display: flex;
    gap: 4px;
    padding: 8px 14px 8px 12px;
    font-family: Archivo;
    font-size: 13.46px;
    font-weight: 600;
    line-height: 15.38px;
    text-align: center;
    color: color('neutral', 70);
    border-radius: 12px;
    background-color: white;
    border: 1px solid color('neutral', 95);

    &:focus {
      outline: none;
    }

    & path {
      fill: color('neutral', 70);
      stroke: color('neutral', 70);
    }
  }

  &__card-title {
    margin-bottom: 12px;
    font-family: Archivo;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: color('neutral', 10);
  }

  &__summary {
    // loan__summary

    &__title {
      // loan__summary__title
      margin: 32px 0 8px 0;
      font-family: Archivo;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: color('neutral', 10);
    }
  }

  &__bank-card {
    padding: 20px;
    border-radius: 16px;
    background: var(--white, #fff);
    box-shadow: 0px 1px 14px 0px rgba(0, 7, 22, 0.14), 0px 0px 4px 0px rgba(65, 77, 92, 0.2);
  }

  &__bank-card-primary-section {
    border-radius: 16px 16px 0 0;
    border: 1px solid color('neutral', 95);
    padding: 16px 16px 24px 16px;

    &--summary {
      display: flex;
      justify-content: space-between;
      font-family: Archivo;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      color: color('neutral', 10);

      & span {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: color('neutral', 50);
      }
    }
  }

  &__bank-card-primary-section-footer {
    display: flex;
    align-items: center;
    border-radius: 0 0 16px 16px;
    background-color: color('blue', 95);
    padding: 12px 16px 12px 16px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: color('blue', 40);

    & svg {
      margin-right: 12px;
    }

    & span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__bank-card-secondary-section {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid color('neutral', 95);
    padding: 12px 16px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      & span:first-child {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: color('neutral', 50);
      }

      & span:nth-child(2) {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: color('neutral', 10);
      }
    }
  }

  &__bank-card-deactivated-section {
    // loan__bank-card-deactivated-section
    margin-bottom: -8px;
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: color('neutral', 50);

    & span {
      font-family: Archivo;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      text-align: right;
      color: color('neutral', 10);
    }
  }

  &__schedule {
    padding: 0;
    margin: 0;
    margin-top: 36px;
    list-style-type: none;

    &--extra-height {
      // loan__schedule--extra-height
      overflow: scroll;
    }

    &__collapse-button {
      // loan__schedule__collapse-button
      margin: 0 auto 24px;
      display: block;
      height: 32px;
      width: 167.89px;
      padding: 2px 23px 0 23px;
      gap: 7.72px;
      border-radius: 8px;
      border: 1px solid #ebedef;
      background-color: #ffffff;
      font-family: Archivo;
      font-size: 13.51px;
      font-weight: 600;
      line-height: 15.44px;
      text-align: center;
      color: color('neutral', 10);
    }

    &__warning {
      margin-top: 16px;
      display: flex;
      align-items: center;
      padding: 16px;
      border-left: 4px solid color('red', 55);
      background-color: color('red', 95);
      gap: 8px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: color('neutral', 10);
    }

    &__item {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid color('neutral', 95);

      & > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > div > span {
        font-family: Archivo;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.0042em;
        text-align: left;
        color: color('neutral', 10);
      }

      &__icon {
        margin-right: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid color('neutral', 95);
      }

      &__content-wrapper {
        display: flex;
        align-items: center;
      }

      &__content {
        & > span {
          margin-bottom: 4px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.0042em;
          text-align: left;
          color: color('neutral', 50);
        }

        & div {
          display: flex;
          align-items: flex-start;
        }

        & div span {
          margin-left: 4px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          color: color('neutral', 50);
          text-transform: capitalize;
        }
      }
    }

    &__item:last-child {
      border: none;
    }
  }

  &__forgive-schedule {
    padding: 20px;
    border-radius: 16px;
    background-image: url('./forgive-banner.png');
    background-size: cover;

    & h3 {
      font-family: Archivo;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.0042em;
      text-align: left;
      color: white;
    }

    & p {
      width: 323px;
      height: 32px;
      margin: 6px 0 12px 0;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: white;
    }

    & button {
      width: 168px;
      height: 32px;
      font-family: Archivo;
      font-size: 13.51px;
      font-weight: 600;
      line-height: 15.44px;
      text-align: center;
      color: color('neutral', 10);
      background-color: #fff;
      border: 0;

      border-radius: 8px;
    }

    &--disabled {
      opacity: 0.4;
    }

    & button:disabled {
      opacity: 0.7;
      color: color('neutral', 10) !important;
      background-color: #fff !important;
    }
  }

  &__overview {
    min-width: 370px;
    margin: -24px -24px 0 0;
    padding-top: 10px;
    box-shadow: 0px 0px 8px 0px #00000033;

    &__status-issue-button {
      background-color: color('red', 45);
      border-color: color('red', 45);
    }

    &__status-title {
      font-family: Archivo;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: color('neutral', 10);

      &--issue {
        color: color('red', 45);
      }
    }

    &__status-text {
      margin: 4px 26px 16px 0;
      width: 233px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: color('neutral', 40);
    }

    &__main {
      padding: 20px;
    }

    &__card {
      position: relative;
      margin-bottom: 20px;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0px 2px 4px 0px #00000024;

      &--issue {
        background-color: color('red', 95);
      }

      &-tab {
        margin-bottom: 14px;
        width: 32px;
        height: 4px;
        margin-right: 8px;
        display: inline-block;
        border-radius: 100px;
        background-color: color('neutral', 80);

        &--active {
          background-color: color('neutral', 10);
        }
      }

      & > svg {
        position: absolute;
        top: 0;
        right: 0;
      }

      & button {
        width: 303px;
        height: 44px;
        font-family: Archivo;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        color: white;
      }

      &__paid-block {
        margin-top: 28px;
        display: flex;
        justify-content: space-between;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.0042em;
        text-align: right;
        color: color('neutral', 50);
      }
    }

    &__card-title {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: color('neutral', 50);

      &--issue {
        color: color('red', 45);
      }
    }

    &__card-day {
      margin-top: 16px;
      margin-bottom: 12px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.0042em;
      text-align: left;
      color: color('neutral', 10);
    }

    &__card-amount {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
      font-family: Archivo;
      font-size: 26.92px;
      font-weight: 600;
      line-height: 32.69px;
      letter-spacing: 0.0038em;
      text-align: left;
      color: color('neutral', 10);
      border-radius: 8px;

      & span {
        font-family: Archivo;
        font-size: 26.92px;
        font-weight: 600;
        line-height: 32.69px;
        letter-spacing: 0.0038em;
        text-align: left;
      }
    }

    &__get-help {
      padding: 40px 16px 8px 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;

      & > svg {
        cursor: pointer;
      }

      &--text {
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: Archivo;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: color('neutral', 10);

        & svg {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}

.loans-filter {
  .datepicker__options {
    @media (max-width: 1399px) {
      left: auto;
      right: 0;
    }

    @media (max-width: 1363px) {
      left: 0;
    }

    @media (max-width: 1210px) {
      flex-direction: column;
      gap: 20px;
      position: fixed;
      top: 330px;
      right: 26px;
      left: unset;
    }

    @media (max-width: 992px) {
      position: fixed;
      top: 330px;
      left: 20px;
      right: unset;
      width: calc(100% - 40px);
    }
  }

  .datepicker__option-section {
    @media (max-width: 1210px) {
      margin: 0;
      width: 100%;
    }
  }
}
