@import './payment-verify-modal.scss';

.checkout-list {
  max-width: 460px;
  margin-top: 15px;
  border-radius: 8px;
  background-color: color('neutral', 100);
  border: 2px solid color('neutral', 90);
  overflow: hidden;
  &__header {
    //header
    display: flex;
    align-items: center;
    padding-right: 16px;
    button {
      background: transparent;
      outline: none;
      &:focus {
        outline: none;
      }
    }
  }

  &__dropdown-button {
    //header-dropdown
    display: flex;
    align-items: center;
    flex: 1;
    gap: 4px;
    padding: 16px;
    border: none;
    text-transform: capitalize;
    &__icon {
      width: 24px;
      aspect-ratio: 1/1;
    }

    &_title {
      font-size: 24px;
    }
  }

  &__steps-count {
    color: color('neutral', 60);
    font-size: 12px;
  }
  p {
    margin: 0;
  }

  &__progress-bar {
    background-color: color('blue', 90);
    height: 6px;
    &__progress {
      height: 6px;
      background-color: color('blue', 30);
    }
  }

  &__container {
    overflow: hidden;
    max-height: 400px;
    transition: max-height 0.3s linear;

    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      gap: 8px;
      list-style: none;
      box-sizing: border-box;
      padding: 16px;
    }

    &.close {
      max-height: 0;
    }

    a {
      text-decoration: none;
    }

    //list-item
    &__list-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;
      width: 100%;
      border: 2px solid color('neutral', 80);
      text-transform: uppercase;
      color: color('neutral', 60);
      cursor: pointer;
      background: transparent;
      background: transparent;
      outline: none;
      &:focus {
        outline: none;
      }
      //checkmark icon container
      &__checkmark {
        border-radius: 50%;
        border: inherit;
        width: 24px;
        aspect-ratio: 1/1;
      }

      //title
      &__title {
        margin: 0;
        position: relative;
      }

      &.done {
        border: 3px solid color('blue', 30);
        color: color('blue', 30);
        font-weight: 700;
        pointer-events: none;
      }

      &.done &__checkmark {
        background-color: color('blue', 30);
        display: flex;
        align-items: center;
        justify-content: center;
        //icon
        &__icon {
          color: color('neutral', 100);
          width: 12px;
        }
      }

      &.done &__title {
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: color('blue', 30);
          top: 50%;
          left: 0;
        }
      }
    }
  }
}
