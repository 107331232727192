.funding-card {
  position: relative;
  padding: 24px 24px 10px;
	background-color: color("neutral", 100);
  border: 1px solid color("neutral", 90);
  border-radius: 16px;
  box-shadow: 0 32px 60px rgba(color("neutral", 40), .08);
  overflow: hidden;
	
	&__top { // .funding-card__top
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
	}
	
	&__bank-box { // .funding-card__bank-box
    display: flex;
	}
	
	&__logo { // .funding-card__logo
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    width: 48px;
    height: 48px;
    border-radius: 18px;
    background-color: color("neutral", 90);
	}
	
	&__logo-image { // .funding-card__logo-image
    width: 24px;
    height: 24px;
	}
	
	&__bank-info { // .funding-card__bank-info
    display: flex;
    flex-direction: column;
    justify-content: center;
	}
	
	&__bank-name { // .funding-card__bank-name
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
	}
	
	&__bank-code { // .funding-card__bank-code
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;    
    color: color("neutral", 70);
	}
	
	&__status { // .funding-card__status
	}
	
	&__info { // .funding-card__info
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 11px 11px 0;
    border: 2px solid color("neutral", 90);
    border-radius: 8px;
	}
	
	&__info-col { // .funding-card__info-col
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 18px; 

    &--key { // .funding-card__info-col--key
      color: color("neutral", 60);
    }

    &--value { // .funding-card__info-col--value
      color: color("neutral", 30);
      text-align: right;
    }
	}

  &__cover { // .funding-card__cover
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(color("blue", 20), .5);
    opacity: 0;
    transition: opacity $default-transition-time;

    &:hover {
      opacity: 1;
    }
	}

  &__button { // .funding-card__button
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 6px 13px;
	}
}
