.partners-deeplinks {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 42px;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 42px;

    &-radio-wrapper {
      >label {
        margin-bottom: 0 !important;
        padding: 8px 0;
      }

      &:first-child {
        border-bottom: 1px solid #E9EBED;

        >label {
          padding-top: 0;
        }
      }

      &:last-child {
        border-bottom: 1px solid transparent;

        >label {
          padding-bottom: 0;
        }
      }
    }

    &-radio {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__label {
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }

    &-buttons {
      display: flex;
    }

    &-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-decoration: none;
      border-width: 1px;
      border-style: solid;
      cursor: pointer;
      transition: border-color 0.2s, background-color 0.2s, color 0.2s;
      outline: none;
      border: none;
      background: transparent;
      white-space: nowrap;
      padding: 0;

      color: var(--neutrals-40-emphasis-text, #525F7A);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline-width: 0;
      }

      &-isActive {
        border-radius: 3px;
        background: var(--blue-95-background, #E5F3FF);
      }

      &-disabled {
        border-radius: 0;
        background: unset;
        opacity: 0.4;
        pointer-events: none;
      }

      &-dot {
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: color("neutral", 40);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &-title {
      padding-top: 14px;
      padding-bottom: 16px;
      border-bottom: 1px solid #E9EBED;
      color: color("neutral", 30);
      font-size: 16px;
      font-weight: 600;
      line-height: 27px;
    }

    &-body {
      display: flex;
      gap: 46px;

      &-column {
        width: calc(50% - 23px);
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }

      &-isEmpty {
        gap: 20px;

        &-column {
          width: calc(50% - 10px);
        }
      }
    }

    &-section {
      border-radius: 4px;
      border: 1px solid #E9EBED;
      transition: opacity 0.2s;

      &-title {
        margin-bottom: 0;
        padding: 12px 20px;
        background: #F4F6F6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #2F3941;
        font-size: 16px;
        font-weight: 600;
        line-height: 27px;

        &-label {
          color: var(--blue-40, #006ACC);
          font-family: 'Archivo';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
        }
      }

      &-subtitle {
        margin-bottom: 12px;
        color: #2F3941;
        font-size: 14px;
        font-weight: 700;
        line-height: 27px;
      }

      &-qaBlock {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &-question {
        padding: 4px 0;
        color: color("neutral", 30);
        font-size: 14px;
        font-weight: 500;
        line-height: 17.99px;

        &-isBankingDetails {
          display: flex;
          justify-content: space-between;
          color: var(--Neutrals-40---Emphasis-Text, #525F7A);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17.99px;

          span {
            color: var(--Neutrals-40---Emphasis-Text, #525F7A);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 17.99px;
            text-transform: capitalize;
          }

          &-isEmpty {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            padding: 16px;
            padding-right: 8px;
            border-bottom: 1px solid var(--Yellow-Yellow-400, #FFB057);
            background: var(--Yellow-Yellow-300, #FED6A8);

            &-container {
              display: flex;
              align-items: center;
              gap: 8px;
              color: var(--Yellow-Yellow-800, #703815);
              font-feature-settings: 'clig'off, 'liga'off;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.154px;
            }

            &-button {
              cursor: pointer;
            }
          }
        }
      }

      &-answer {
        color: color("neutral", 40);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        span {
          border-radius: 3px;
          background: var(--Blue-95---Background, #E5F3FF);
          color: var(--Neutrals-40---Emphasis-Text, #525F7A);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: capitalize;
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        font-size: 14px;
        font-weight: 400;
      }

      &-body {
        &-container {
          padding: 16px 20px;
          background: #FFF;
        }
      }
    }

    &-emptyDetailLink {
      min-height: 252px;
      height: 100%;
      padding: 20px;
      border-radius: 4px;
      border: 1px solid var(--grey-grey-200, #E9EBED);
      background: #F1F3F3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &-title {
        color: var(--grey-grey-400, #C2C8CC);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      &-text {
        color: var(--grey-grey-400, #C2C8CC);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  &__select-block {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__generate-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--neutrals-95-card-border, #F0F1F5);
    background: rgba(255, 255, 255, 0.50);

    &-info {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-left: 24px;
      color: color("neutral", 50);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .divider {
    height: 1.5px;
    width: 100%;
    background: #E9EBED;
  }

  .buttons-divider {
    height: 100%;
    width: 2px;
    background: rgba(255, 255, 255, 0.80);
  }

  .icon-info-glyph {
    width: 20px;
    height: 20px;
  }

  .icon-empty-detail-link {
    height: 33px;
    width: 33px;
  }

  .active-text {
    color: color("blue", 40);
    text-transform: capitalize;
  }

  .light-grey-text {
    color: color("neutral", 40);
  }

  .is-bold {
    font-weight: 600;
  }

  .is-vertical {
    flex-direction: column;
  }

  .no-gap {
    gap: 0;
  }

  .section--disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .no-padding-bottom {
    padding-bottom: 0;
  }

  .third-width {
    width: 33.33333%;
  }

  &__generate-buttons {
    display: flex;
  }

  &__generate-button {
    padding: 14px 26px 14px 32px;
    border-radius: 0 12px 12px 0;

    &__isDirty {
      border-radius: 12px 0 0 12px;
    }
  }

  .refresh-button {
    height: 100%;
    border-radius: 0 12px 12px 0;
    border-left: 1px solid rgba(255, 255, 255, 0.80);
  }

  &__label {
    padding: 0 4px;
    color: color("neutral", 50);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &__secondary-text {
    color: color("neutral", 70);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}