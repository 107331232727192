.auth-slider {
  &__slide { // .auth-slider__slide
    text-align: left;
    user-select: none;
    cursor: grab;
  }

  &__title { // .auth-slider__title
    margin-bottom: 24px;
    font-family: $font-secondary;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  }

  &__list-title { // .auth-slider__list-title
    margin-bottom: 28px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: color("blue", 90);
  }

  &__list { // .auth-slider__list
    padding-left: 0;
    list-style-type: none;
  }

  &__list-item { // .auth-slider__list-item
    display: flex;
    font-size: 14px;
    line-height: 20px;
    color: color("neutral", 100);

    &:not(:last-child) {      
      margin-bottom: 32px;
    }
  }

  &__check { // .auth-slider__check
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 8px;
    background-color: color("blue", 55);
  }

  &__check-icon { // .auth-slider__check-icon
    width: 24px;
    height: 24px;
  }

  &__pagination { // .auth-slider__pagination
    display: flex;
    justify-content: center;
    margin: 64px -7px 0;
  }

  &__bullet { // .auth-slider__bullet
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    margin: 0 7px;
    padding: 0;
    background-color: color("neutral", 85);
    border-width: 0;
    border-radius: 8px;
    transition: width $default-transition-time, background-color $default-transition-time;

    &:focus {
      outline: none;
    }

    &--active { // .auth-slider__bullet--active
      width: 16px;
      background-color: color("green", 55);
    }
  }
}