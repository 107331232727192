.finances-empty {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23E0E4EB' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 16px;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    padding: 50px;
  }

  &__title {
    // .finances-empty__title
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: color('neutral', 40);
  }

  &__text {
    // .finances-empty__text
    margin-bottom: 44px;
    font-size: 16px;
    line-height: 28px;
    color: color('neutral', 50);
  }

  &__image {
    // .finances-empty__image
    width: 100%;
    max-width: 97px;
    margin-bottom: 44px;
  }

  &__button {
    // .finances-empty__button
    width: 100%;
    max-width: 343px;
  }
}
