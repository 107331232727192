.tabs {
  width: 100%;

  &--payments { //.tabs--payments
    margin-top: 40px;
  }

  &__list { // .tabs__list
    display: flex;
    width: 100%;
    margin: 0 -8px 8px;
    padding: 0 0 8px;
    list-style-type: none;
    overflow-x: auto;
  }

  &__item {
    position: relative;
    margin: 0 8px;
    padding: 4px 12px 12px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    color: color("neutral", 70);
    cursor: pointer;
    white-space: nowrap;
    transition: color $default-transition-time;
    text-decoration: none;
    
    &::after {
      opacity: 0;
      position: absolute;
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
      width: 51px;
      max-width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: color("neutral", 70);
      content: "";
      transition: opacity $default-transition-time, background-color $default-transition-time;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &--selected {
      color: color("blue", 30);

      &::after {
        opacity: 1;
        background-color: color("blue", 30);
      }
    }
  }

  &__panel { // .tabs__panel
  }
}
