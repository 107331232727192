.integration-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 25px;
  border-width: 0;
  border-radius: 16px;
  font-family: $font-secondary;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  box-shadow: 0 4px 10px rgba(color("neutral", 30), .05);
  user-select: none;
  cursor: pointer;

  &:hover {}

  &:focus {
    outline-width: 0;
  }

  &--blue { // .integration-btn--blue
    background-color: color("blue", 55);
    color: color("neutral", 100);
  }

  &--white { // .integration-btn--white
    background-color: color("neutral", 100);
    color: color("blue", 55);
  }

  &--black { // .integration-btn--black
    background-color: color("neutral", 20);
    color: color("neutral", 100);
  }

  &__icon { // .integration-btn__icon
    flex-shrink: 0;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}