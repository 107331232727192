.customers {
  padding: 16px;
  border-radius: 16px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 14px 0px rgba(0, 7, 22, 0.14), 0px 0px 4px 0px rgba(65, 77, 92, 0.2);

  &__col {
    width: 50%;

    .card {
      height: calc(100vh - 530px);
      overflow: auto;
    }
  }
}
