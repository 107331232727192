.auth-illustration {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  &--woman-with-notebook { // .auth-illustration--woman-with-notebook
    max-width: 310px;
    transform: translateX(-13px);
  }

  &--two-women { // .auth-illustration--two-women
    max-width: 310px;
    transform: translateX(10px);
  }

  &--man-with-notebook { // .auth-illustration--man-with-notebook
    max-width: 341px;
    transform: translateX(-11px);
  }

  &--woman-with-notes { // .auth-illustration--woman-with-notes
    max-width: 310px;
    transform: translateX(10px);
  }

  &--woman-with-mobile { // .auth-illustration--woman-with-mobile
    max-width: 386px;
  }

  &--party { // .auth-illustration--party
    max-width: 344px;
    transform: translateX(10px);
  }

  &--brain { // .auth-illustration--brain
    max-width: 310px;
    transform: translate(5px, -21px);
  }

  &--letter { // .auth-illustration--letter
    max-width: 310px;
    margin-top: 100px;
    transform: translate(5px, -21px);
  }

  &--painter { // .auth-illustration--painter
    max-width: 301px;
    margin-top: 41px;
  }

  &--lock { // .auth-illustration--lock
    max-width: 303px;
    margin-top: 4px;
    transform: translateX(-17px);
  }

  &__img { // .auth-illustration__img
    position: absolute;
    max-width: 100%;

    &--lv1 { // .auth-illustration__img--lv1 - first level images

      .auth-illustration--woman-with-notebook & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .auth-illustration--two-women & {
        top: 14.2%;
        left: -23.5%;
        width: 35.8%;
      }

      .auth-illustration--man-with-notebook & {
        top: 0;
        left: 0;
        width: 91%;
      }

      .auth-illustration--woman-with-notes & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .auth-illustration--party & {
        top: 0;
        left: 5%;
        width: 90%;
      }

      .auth-illustration--brain & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .auth-illustration--letter & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .auth-illustration--painter & {
        top: 6.8%;
        right: 0;
        width: 78.1%;
      }

      .auth-illustration--lock & {
        top: 0;
        right: 0;
        width: 95.1%;
      }

      .auth-illustration--woman-with-mobile & {
        top: -0.1%;
        right: 11.4%;
        width: 80.4%;
       }
    }

    &--lv2 { // .auth-illustration__img--lv2 - second level images

      .auth-illustration--woman-with-notebook & {
        top: 12%;
        right: -26.2%;
        transform: rotate(-5deg);
        width: 59.8%;
      }

      .auth-illustration--two-women & {
        top: 0;
        left: 0;
        width: 100%;
      }

      .auth-illustration--man-with-notebook & {
        top: -10.8%;
        right: -29.6%;
        width: 66.7%;
        transform: rotate(-10deg);
      }

      .auth-illustration--woman-with-notes & {
        top: 17.9%;
        left: -35.2%;
        width: 76%;
      }

      .auth-illustration--party & {
        bottom: 12.2%;
        right: -8.6%;
        width: 22.7%;
        transform: rotate(180deg);
      }

      .auth-illustration--brain & {
        top: -3.3%;
        right: -23.3%;
        width: 75%;
      }

      .auth-illustration--letter & {
        bottom: 3.5%;
        left: -3.3%;
        width: 17.3%;
      }

      .auth-illustration--painter & {
        top: -16.5%;
        left: -26.4%;
        width: 42.5%;
      }

      .auth-illustration--lock & {
        top: 11.3%;
        left: 0;
        width: 79.3%;
      }

      .auth-illustration--woman-with-mobile & {
        top: 7.3%;
        left: -21.5%;
        transform: scaleX(-1) rotate(10.9deg);
        width: 59.1%;
      }
    }

    &--lv3 { // .auth-illustration__img--lv3 - third level images

      .auth-illustration--woman-with-notebook & {
        top: 12.4%;
        left: -5%;
        width: 94.1%;
      }

      .auth-illustration--two-women & {
        top: 0;
        right: 0;
        width: 100%;
      }

      .auth-illustration--man-with-notebook & {
        top: 0;
        right: 0;
        width: 100%;
      }

      .auth-illustration--woman-with-notes & {
        top: 0;
        right: 0;
        width: 100%;
      }

      .auth-illustration--party & {
        top: 1%;
        left: -9.7%;
        width: 22.6%;
        transform: scaleY(-1) rotate(-58deg);
      }

      .auth-illustration--brain & {
        top: 2.1%;
        left: -22.2%;
        width: 93.2%;
      }

      .auth-illustration--letter & {
        top: 17.9%;
        left: 12.3%;
        width: 77.9%;
      }

      .auth-illustration--painter & {
        top: 0;
        left: 0;
        width: 84%;
      }

      .auth-illustration--lock & {
        top: -3.5%;
        right: -3.6%;
        transform: rotate(-23deg);
        width: 74.4%;
      }

      .auth-illustration--woman-with-mobile & {
        top: -4.7%;
        left: 8.4%;
        width: 100%;
      }
    }

    &--lv4 { // .auth-illustration__img--lv4

      .auth-illustration--party & {
        top: 0;
        right: 0;
        width: 100%;
      }

      .auth-illustration--letter & {
        top: 33.7%;
        right: -12.8%;
        width: 40%;
      }
    }
  }
}
