.autocomplete {
  position: relative;
  padding-bottom: 24px;

  &__input {
    // .autocomplete__input
    width: 100%;
    padding: 13px 16px;
    border: 1px solid color('neutral', 90);
    border-radius: 16px;
    transition: box-shadow $default-transition-time, border-color $default-transition-time;
    font-family: $font-primary;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;

    &::placeholder {
      color: color('neutral', 50);
    }

    &:focus {
      outline: none;
      border-color: color('blue', 30);
      box-shadow: 0 0 0 1px color('blue', 30);

      & + .autocomplete__icon {
        transform: rotate(180deg);
      }
    }

    &--error {
      border-color: color('red', 55);
      box-shadow: 0 0 0 1px color('red', 55);

      &:focus {
        border-color: color('red', 55);
        box-shadow: 0 0 0 1px color('red', 55);
      }
    }
  }

  &__icon {
    //.autocomplete__icon
    position: absolute;
    top: 0;
    right: 14px;
    bottom: 2px;
    display: flex;
    align-items: center;
    width: 24px;
    height: 48px;
    color: color('neutral', 30);
  }

  &__error {
    //.autocomplete__error
    color: color('red', 55);
    font-size: 12px;
    transform: translateY(5px);
    position: absolute;
    bottom: 8px;
  }
}

.pac-container {
  border: 2px solid color('neutral', 95);
  border-radius: 16px;
  box-shadow: none;
  padding: 16px;
  transform: translateY(8px);
  z-index: 10002;
}

.pac-item {
  height: 44px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  border-radius: 12px;
  font: 14px 'Roboto';
  padding: 10px 12px;

  &:hover {
    border-color: color('blue', 30);
    background-color: rgba(0, 79, 153, 0.05);
    color: color('blue', 30);
    font-weight: 500;

    .pac-item-query,
    .pac-matched {
      color: color('blue', 30);
      font-weight: 500;
    }
  }
}

.pac-icon-marker {
  display: none;
}

.pac-item-query {
  font: 14px 'Roboto';
}
.pac-matched {
  font-weight: 400;
}
.pac-logo:after {
  display: none !important;
}
