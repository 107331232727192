.refund-action {
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  padding: 0;
  border-width: 0;
  background-color: $transparent;
  font-family: $font-secondary;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: color("blue", 30);
  cursor: pointer;

  &:focus {
    outline-width: 0;
  }


  &--mint { // .refund-action--mint
    color: color("mint", 50);
  }

  &__icon { // .refund-action__icon
    width: 16px;
    height: 16px;
    margin-right: 4px;
    color: color("blue", 40);

    .refund-action--mint & {
      color: color("mint", 68);
    }
  }
}
