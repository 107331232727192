.multiStepForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-inline: auto;
  width: 100%;
  max-width: 1162px;

  &__step {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__centered {
    margin: 0 auto;
  }

  &__button {
    align-self: flex-start;
    display: flex;
    width: 56px;
    height: 56px;
    padding: 16px;
    gap: 8px;
    border-radius: 16px;
  }

  &__FormWrapper {
    display: flex;
    justify-content: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__inputPadding {
    padding-bottom: 0;
  }

  .selectionFlow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.verifyBankAccForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  column-gap: 48px;
}

.registerBusinessForm,
.loanTypeForm {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  &__input {
    width: 100%;
  }
}

.loanTypeForm {
  padding-top: 54px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  align-items: center;

  &__container {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 54px;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__title {
    color: var(--Blue-20---Overlay, #003566);
    text-align: center;
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  &__subtitle {
    color: var(--Neutrals-50---Secondary-Text, #679);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.256px;
  }

  &__typeSelect {
    margin: 0 26px;
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 24px;
    border: 1px solid transparent;
    background: var(--Neutrals-100---White, #FFF);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
    transition: 0.3s all;

    cursor: pointer;

    &:hover,
    &--active {
      border: 1px solid var(--Neutrals-90---Input-Border, #E0E4EB);
      box-shadow: 0px 4px 20px 0px rgba(0, 53, 102, 0.15);
      transition: 0.3s all;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    &__descr {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__title {
      color: var(--Blue-20---Overlay, #003566);
      font-family: Archivo;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.076px;
    }

    &__subtitle {
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-feature-settings: 'clig'off, 'liga'off;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__radio {
      min-width: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid rgba(194, 201, 214, 1);
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all;

      &--active {
        border: var(--space-space-0, 2px) solid var(--Blue-40, #006ACC);
        background: var(--Blue-30, #004F99);
        transition: 0.3s all;
      }
    }
  }
}

.connectBankAccForm {
  @include flex($d: column);

  @media (max-width: $screen-md-min) {
    width: 100%;
  }

  width: 100%;

  &__input-group,
  &__divider,
  &__plaid-button {
    @media (min-width: $screen-sm-min) {
      margin: 0 20px;
    }
  }

  &__input-group,
  &__divider,
  &__plaid-button {
    margin: 0 40px;
  }

  &__plaid-button {
    margin-bottom: 36px;
  }

  &__divider {
    text-align: center;
    text-transform: uppercase;
    position: relative;
    color: color('neutral', 70);
    margin-bottom: 42px;

    &::after,
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      width: 40%;
      height: 1px;
      background: color('neutral', 85);
    }

    &::after {
      left: 10px;
    }

    &::before {
      right: 10px;
    }
  }

  &__plaid {
    @include flex($d: column, $g: 36px);
  }
}

@media (max-width: 840px) {

  .registerBusinessForm,
  .loanTypeForm {
    flex-direction: column;
    margin-inline: auto;
    width: 90%;
    gap: 0px;

    &__input {
      width: 100%;
    }
  }
}