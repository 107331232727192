.funding-empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border: 2px solid color("neutral", 90);
	border-radius: 16px;
	text-align: center;

	@media (min-width: $screen-lg-min) { 
		padding: 34px;
	}
	
	&__title { // .funding-empty__title
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 24px;
    color: color("neutral", 40);

		@media (min-width: $screen-lg-min) {   
      font-size: 24px;
      line-height: 32px;
    }
	}
	
	&__text { // .funding-empty__text
		max-width: 330px;
    margin-bottom: 44px;
    font-size: 16px;
    line-height: 28px;
    color: color("neutral", 50);
	}
	
	&__image { // .funding-empty__image
		margin-bottom: 42px;
		width: 100%;
		max-width: 98px;
	}
	
	&__button { // .funding-empty__button
		width: 100%;
		max-width: 100%;

		@media (min-width: $screen-sm-min) {       
      width: auto;
    }
	}
}
