$body-color: color('neutral', 98);
$text-color: color('neutral', 10);

$transparent: rgba(#fff, 0);

$default-transition-time: 0.2s;

$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Archivo', sans-serif;
$font-third: 'IBM';

:root {
  --header-color: #004f99;
  --menu-color: #003566;
}

:root.admin {
  --header-color: #003566;
  --menu-color: #006acc;
}
