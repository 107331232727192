.select-button-container {
  border-radius: 8px;
  border: 2px solid color('blue', 30);
  display: flex;
  justify-content: center;
  max-width: 364px;
  &__button {
    color: color('blue', 30);
    font-weight: 700;
    font-size: 14px;
    padding: 11px 19px;
    height: 42px;
    background: transparent;
    border: none;
    flex: 1;
    text-transform: capitalize;
    transition: $default-transition-time;
    width: 50%;
    @media (min-width: $screen-xl-min) {
      white-space: nowrap;
    }
    &:focus,
    &:active {
      border: none;
      background: transparent;
      outline: none;
    }

    &:first-child {
      border-right: inherit;
    }

    &.active {
      background-color: color('blue', 30);
      color: color('neutral', 100);
      font-size: 12px;
    }
  }
}
