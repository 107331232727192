.banner {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: color('mint', 50);
  width: 100%;
  border-left: 1px solid color('neutral', 95);
  @media (max-width: $screen-lg-min) {
    border: 0;
  }
}

.banner__description-сontainer {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 24px;
  & > p {
    display: flex;
    align-items: center;
  }
}
.banner__description-text {
  display: flex;
  align-items: center;
}

.banner__description {
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  display: flex;
  gap: 5px;
}

.banner__close {
  padding: 0;
  background-color: transparent;
  outline: unset;
  border: unset;
  cursor: pointer;
  z-index: 331;
  color: color('neutral', 100);
  & > svg {
    max-width: 24px;
    max-height: 24px;
  }
}

.banner-icon {
  width: 16px;
}
.banner__link {
  white-space: nowrap;
}

@media (max-width: $screen-lg-min) {
  .banner {
    padding-inline: 15px;
    padding-top: 10px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background: color('mint', 50);
    width: 100%;
    position: static;
  }

  .banner__description-container {
    flex-direction: column;
  }
}
@media (max-width: 801px) {
  .banner__description-сontainer {
    flex-basis: 100%;
    gap: 0px;
    flex-direction: column;
  }
}
@media (max-width: $screen-sm-min) {
  .banner__description {
    gap: 0;
    flex-direction: column;
  }
  .banner__description-text {
    & > * {
      text-align: center !important;
    }
  }
  .banner-icon {
    display: none;
  }
  .banner {
    gap: 10px;
  }
}
