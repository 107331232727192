.partners-tabs {
  // partners-tabs

  &__note {
    // partners-tabs__note
    margin-top: 10px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    & svg {
      margin-right: 9px;
      width: 16.67px !important;
      height: 16.67px !important;
    }

    & path {
      fill: #d1d6e0 !important;
      stroke: #d1d6e0 !important;
    }

    & span {
      font-family: Roboto;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

      color: #667799;
    }
  }

  &__line-input-wrapper {
    // partners-tabs__line-input-wrapper
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__form-label {
    // partners-tabs__form-label
    text-transform: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.0042em;
    text-align: left;
    color: color('neutral', 50);
  }

  &__inputs-wrapper {
    // partners-tabs__inputs-wrapper
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &--mr {
      // partners-tabs__inputs-wrapper--mr

      & input {
        margin-right: 184px;
      }
    }

    & input {
      width: 384px;
    }

    &--small-inputs {
      // partners-tabs__inputs-wrapper--small-inputs
      width: 330px;
      margin-right: 237px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.0042em;
      text-align: left;
      color: color('neutral', 50);

      & input {
        width: 120px;
      }

      & .input-group__error-text {
        bottom: -8px;
      }
    }
  }

  &__unlimited-text {
    // partners-tabs__unlimited-text
    height: 24px;
    margin-left: 22px;
    margin-right: 8px;
    padding-left: 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.0042em;
    text-align: left;
    color: color('neutral', 50);
    border-left: 1px solid color('neutral', 50);
  }

  &__submit-wrapper {
    // partners-tabs__submit-wrapper
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid color('neutral', 95);

    & button {
      width: 113px;
      height: 42px;
      font-family: Archivo;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
    }
  }
}
