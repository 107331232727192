.main-title {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-bottom: 16px;
  padding-bottom: 18px;
  color: var(--Neutrals-10---Primary-Text, #14181f);
  border-bottom: 1px solid #e0e0e0;

  font-family: Archivo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.breadcrumb {
  margin: 4px 0;
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    padding: 0;

    outline: none;
    background: none;
    border: none;

    &:focus {
      outline: none;
      background: none;
      border: none;
    }

    span {
      font-weight: 400;
      background: var(--Blue-95---Background, #e5f3ff);
      color: var(--Blue-40, #006acc);
    }

    &:first-child {
      span {
        padding-left: 8px;
        font-weight: 400;
        border-radius: 8px 0 0 8px;
        background: var(--Blue-95---Background, #e5f3ff);
        color: var(--Blue-40, #006acc);
      }
    }

    &:last-child {
      span {
        font-weight: 600;
        border-radius: 0;
        background: var(--Neutrals-90---Input-Border, #e0e4eb);
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
      }
    }

    img {
      user-select: none;
    }
  }

  &__text {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 0;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
  }
}

.presetsList {
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__button {
    padding: 6px 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--Neutrals-10---Primary-Text, #14181f);
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__column {
    width: 18%;

    &:first-child {
      width: 25%;
    }

    &:last-child {
      width: 12%;
    }
  }

  &__head {
    height: 48px;
    border-bottom: 1px solid var(--Neutrals-90---Input-Border, #e0e4eb);

    &__row {
      padding: 8px 14px;
    }

    &__column {
      color: var(--Neutrals-10---Primary-Text, #14181f);
      font-family: Archivo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.15px;
    }
  }

  &__row {
    padding: 14px;
    display: flex;
    align-items: center;
    gap: 56px;
  }

  &__left {
    padding: 0;
    width: calc(67% - 28px);
    align-items: center;
  }

  &__rigth {
    padding: 0;
    width: calc(33% - 28px);
    align-items: center;
    justify-content: flex-end;
  }

  .padding-none {
    padding: 0 !important;
  }
}

.preset {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &__name {
    color: var(--Blue-20---Overlay, #003566);
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.15px;
  }

  &__date {
    color: var(--Neutrals-50---Secondary-Text, #679);
    font-family: Roboto;
    font-weight: 400;
  }

  &__status {
    color: var(--Mint---50, #47afb8);
    font-family: Roboto;
    font-weight: 500;
    text-transform: capitalize;

    display: flex;
    align-items: center;

    &__marker {
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%;

      &--active {
        background-color: color('mint', 50);
        box-shadow: 0 0 0 2px color('mint', 84);
      }
    }
  }
}

.integrations {
  margin: 20px;
  margin-bottom: 0;
  border: 2px solid color('neutral', 95);
  border-radius: 16px;
  display: flex;

  @media (max-width: $screen-md-min) {
    flex-direction: column;
  }

  &>* {
    flex: 1;
  }

  p {
    margin: 0;
  }

  &__content,
  &__preview {
    padding-right: 36px;
  }

  &__content {
    padding-right: 0;
    @include flex($d: column);
  }

  &__pay-button {
    @include flex($a: center, $g: 8px, $j: center);

    align-self: stretch;
    margin: 24px 78px 0;
  }

  &__title {
    //title
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 2px solid color('neutral', 95);
    padding-bottom: 24px;
  }

  &__subheading {
    //subheading
    color: color('neutral', 50);
    margin-bottom: 24px !important;
  }

  &__requestContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .divider {
      text-transform: uppercase;
      text-align: center;
      color: color('neutral', 50);
      font-weight: 500;
    }
  }

  &__requestWrapper {
    display: flex;
    gap: 8px;
    overflow: auto;

    p {
      color: color('neutral', 50);
    }

    justify-content: space-between;
    background-color: color('neutral', 95);
    border-radius: 8px;
    padding: 16px;
    align-items: start;

    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 20px;
      letter-spacing: 1px;
      font-size: 14px;
      color: color('neutral', 10);
    }
  }

  &__noPresets {
    margin-top: 24px;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &__iconBlock {
      width: 64px;
      height: 64px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 177.778px;
      background: var(--Blue-90, #cce6ff);
    }

    &__title {
      color: var(--Blue-30, #004f99);
      font-family: Archivo;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.16px;
    }

    &__descr {
      margin-bottom: 20px;
      color: var(--Neutrals-40---Emphasis-Text, #525f7a);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__footer {
      padding: 24px;
      width: 100%;
      background: #f7f8fc;

      &__text {
        display: flex;
        justify-content: center;
        gap: 6px;
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      &__link {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--Blue-40, #006acc);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
        position: relative;

        &:hover {
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: #006acc;
          }
        }
      }
    }
  }
}

.selectedCampaign {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: var(--Neutrals-10---Primary-Text, #14181f);
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__form {
    padding: 20px 24px;
    margin-bottom: 26px;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);

    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 12px;

    &__item {
      width: calc(50% - 12px);

      &__input {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  &__assets {
    display: flex;
    gap: 32px;
    margin-bottom: 20px;

    &__item {
      width: calc(33.333333% - 21.33333px);
      display: flex;
      border-radius: 8px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      box-shadow: 8px 14px 40px 0px rgba(194, 201, 214, 0.1);
      transition: 0.3s;

      &__active {
        border: 1px solid var(--Blue-40, #006acc);
        transition: 0.3s;
      }

      &__two {
        width: calc(50% - 16px);
      }

      cursor: pointer;

      &__left {
        background: var(--Blue-95---Background, #e5f3ff);
        border-radius: 8px 0 0 8px;
        display: flex;
        align-items: center;

        img {
          padding-left: 8px;
          padding-right: 12px;
        }
      }

      &__right {
        background: #fff;
        border-radius: 0 8px 8px 0;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &__title {
        color: var(--Neutrals-10---Primary-Text, #14181f);
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.15px;
      }

      &__text {
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  &__selectedAsset {
    padding: 24px 20px;
    border-radius: 16px;
    background: var(--white, #fff);
    box-shadow: 0px 0px 4px 0px rgba(65, 77, 92, 0.2), 0px 1px 14px 0px rgba(0, 7, 22, 0.14);
    position: relative;
    overflow: hidden;
    min-height: 800px;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      color: var(--Neutrals-10---Primary-Text, #14181f);
      font-family: Archivo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      img {
        transform: rotate(180deg);
        cursor: pointer;
      }
    }

    &__container {
      width: calc(70% - 12px);
      display: flex;

      border-radius: 8px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      background: #fff;
      box-shadow: 8px 14px 40px 0px rgba(194, 201, 214, 0.1);
      transition: 0.6s all;

      &.full {
        width: calc(100% - 60px - 24px);
        transition: 0.6s all;
      }

      &__title {
        color: var(--Neutrals-10---Primary-Text, #14181f);
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      &__text {
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
        font-feature-settings: 'clig'off, 'liga'off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &__code {
        color: var(--Neutrals-40---Emphasis-Text, #525f7a);
        font-feature-settings: 'clig'off, 'liga'off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      &__buttonBlock {
        min-height: 250px;
        padding: 16px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 0.66px dashed var(--Neutrals-80---Outline, #c2c9d6);
        background: var(--Neutrals-98---Base-Background, #f9fafb);

        button {
          width: 75%;
        }
      }

      &__payButton,
      &__popUp {
        display: flex;
        gap: 16px;

        &__section {
          display: flex;
          gap: 16px;
          width: calc(50% - 8px);

          &__num {
            width: 32px;
            height: 32px;
            background: #004f99;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            font-family: Archivo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15px;
          }

          &__content {
            width: calc(100% - 32px - 16px);
            padding-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
        }
      }

      &__popUp {
        &__section {
          width: 100%;

          &__num {
            width: 32px;
            height: 32px;
            background: #004f99;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            font-family: Archivo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15px;
          }

          &__content {
            padding-top: 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
        }
      }

      &__left {
        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 12px;
        background: var(--Mint---90, #daeff1);
        border-radius: 8px 0 0 8px;
      }

      &__right {
        width: calc(100% - 44px - 32px);
        padding: 16px;
        padding-top: 24px;
        border-radius: 0 8px 8px 0;

        &__popUp {
          width: calc(100% - 44px);

          &__innerBody {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          &__title {
            padding: 6px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
            color: var(--Neutrals-10---Primary-Text, #14181f);
            font-family: Archivo;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.15px;
            cursor: pointer;

            img {
              transform: rotate(90deg);
              transition: all 0.3s ease;

              &.isOpen {
                transform: rotate(270deg);
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
    }

    &__cards {
      width: calc(70% - 12px);
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    &__card {
      border-radius: 8px;
      border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
      box-shadow: 8px 14px 40px 0px rgba(194, 201, 214, 0.1);

      display: inline-flex;

      &__left {
        display: flex;
        align-items: center;
        background: var(--Mint---90, #daeff1);
        border-radius: 8px 0 0 8px;
      }

      &__right {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background: #fff;
        border-radius: 0 8px 8px 0;
        padding: 16px;
        padding-bottom: 24px;
      }

      &__title {
        padding: 6px 0;
        color: var(--Neutrals-10---Primary-Text, #14181f);
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.15px;
      }

      &__linkBlock {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__link {
          display: flex;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          border: 1px solid var(--Neutrals-85---Highlight-Border, #d1d6e0);
          background: var(--Neutrals-98---Base-Background, #f9fafb);
          box-shadow: 8px 14px 40px 0px rgba(194, 201, 214, 0.1);

          &__left {
            padding: 6px 8px;
            background: var(--Mint---90, #daeff1);
            color: var(--Mint---50, #47afb8);
            font-feature-settings: 'clig'off, 'liga'off;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            border-radius: 4px 0 0 4px;
            width: 50px;
          }

          &__right {
            width: calc(100% - 8px - 50px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            padding: 6px 0;
            padding-right: 12px;
            color: var(--Neutrals-60---Tertiary-Text, #8592ad);
            font-feature-settings: 'clig'off, 'liga'off;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            border-radius: 0 4px 4px 0;
          }

          &__icon {
            cursor: pointer;
          }

          &__text {
            color: var(--Neutrals-60---Tertiary-Text, #8592ad);
            font-feature-settings: 'clig'off, 'liga'off;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.linksContainer {
  margin: 0;
  padding: 0;
  @include flex($d: column, $g: 8px);
}

.requestCode {
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    line-height: 24px;
    color: color('neutral', 50);
  }

  &__code-wrapper {
    max-width: 500px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__code {
    display: flex;
    gap: 8px;
    line-height: 20px;
    font-size: 14px;

    &:first-child {
      span {
        &:last-child {
          color: #ed7a4b !important;
        }
      }
    }

    &:not(:last-child) {
      span {
        &:last-child {
          color: #1b5cad;
        }
      }
    }
  }

  &__codeContainer {
    padding: 16px;
    position: relative;
    max-height: 170px;
    overflow: hidden;
    color: var(--Neutrals-40---Emphasis-Text, #525f7a);
    font-family: 'IBM Plex Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-90---Input-Border, #e0e4eb);
    background: var(--Neutrals-98---Base-Background, #f9fafb);
  }

  &__codeBlock {
    margin: 0;
    padding: 0;

    &:after {
      content: '';
      display: none;
    }
  }

  &__linkBlock {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__link {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--Neutrals-85---Highlight-Border, #d1d6e0);
      background: var(--Neutrals-98---Base-Background, #f9fafb);
      box-shadow: 8px 14px 40px 0px rgba(194, 201, 214, 0.1);

      &__left {
        padding: 6px 8px;
        background: var(--Mint---90, #daeff1);
        color: var(--Mint---50, #47afb8);
        font-feature-settings: 'clig'off, 'liga'off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        border-radius: 4px 0 0 4px;
        width: 66px;
      }

      &__right {
        width: calc(100% - 8px - 66px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding: 6px 0;
        padding-right: 12px;
        color: var(--Neutrals-60---Tertiary-Text, #8592ad);
        font-feature-settings: 'clig'off, 'liga'off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        border-radius: 0 4px 4px 0;
      }

      &__icon {
        cursor: pointer;
      }

      &__text {
        color: var(--Neutrals-60---Tertiary-Text, #8592ad);
        font-feature-settings: 'clig'off, 'liga'off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

.requestLink {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    line-height: 24px;
    color: color('neutral', 50);
  }

  &__link {
    max-width: 500px;
    margin: 0;
    padding: 0;
  }
}

.integrationsPreview {
  overflow-y: auto;
  height: 100%;
  width: 60px;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid var(--Neutrals-90---Input-Border, #e0e4eb);
  background: var(--Neutrals-100---White, #fff);
  box-shadow: 0px 1px 1px 0px rgba(233, 235, 237, 0.4), 0px 6px 36px 0px rgba(0, 7, 22, 0.1);
  border-radius: 0 16px 0 0;
  transition: 0.6s all;

  &.open {
    width: calc(30% - 12px);
    transition: 0.6s all;
  }

  &__header {
    padding: 20px;
    padding-left: 28px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e9ebed;

    color: var(--Neutrals-10---Primary-Text, #14181f);
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    transition: 0.6s all;

    img {
      cursor: pointer;
    }

    &.closed {
      padding: 20px;
      transition: 0.6s all;

      img {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.closed {
      display: none;
      pointer-events: none;
    }
  }

  &__title {
    margin-bottom: 12px;
  }

  &__imageBlock {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 12px;
    margin-bottom: 34px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__btn {
      position: absolute;
      top: 16px;
      right: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: var(--space-space-0, 1px) solid #D1D1D1;
      opacity: 0.5;
      background: var(--Neutrals-100---White, #FFF);
      cursor: pointer;
    }
  }

  &__modalBody {
    height: 90%;
    max-width: 90% !important;
    background-color: #f9fafb;

    &__container {
      background-color: #f9fafb;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }

    &__btn {
      z-index: 3;
      position: absolute;
      top: 40px;
      right: 64px;
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: var(--space-space-0, 1px) solid #D1D1D1;
      opacity: 0.5;
      background: var(--Neutrals-100---White, #FFF);
      cursor: pointer;

      @media (max-width: 576px) {
        top: 20px;
        right: 32px;
        width: 26px;
        height: 26px;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}