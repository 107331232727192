.funding-add {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 40px 24px 24px;
  text-align: center;
		
  &__bg { // .funding-add__bg
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  &__image { // .funding-add__image
    width: 70px;
    margin-bottom: 28px;
  }

  &__button { // .funding-add__button
    min-width: 232px;
  }
}
