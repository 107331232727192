.profile {
  position: relative;
padding-top:66px; //added nov 22
  &__search {
    // .profile__search
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;

    &--hidden {
      visibility: hidden;
    }
  }
  &__padding {
    // .profile__search

    margin-bottom: 64px;
  }
}
