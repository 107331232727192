.user-nav-profile {
  // .user-nav__profile
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  &__avatar {
    // .user-nav__profile-avatar
    width: 36px;
    height: 36px;
    margin-right: 10px;
    padding: 2px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #006ACC;

    &--small {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      padding: 2px;
      border-radius: 50%;
      box-shadow: 0 0 0 2px #006ACC;
    }
  }

  &__name {
    // .user-nav__profile-name
    margin-bottom: 0;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    &--small {
      // user-nav-profile__name--small
      margin-bottom: 0;
      font-family: $font-secondary;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 170px;
    }
  }

  &__type {
    // .user-nav__profile-type
    color: color('neutral', 50);
    font-size: 12px;
    line-height: 16px;
  }
}