.alert-wrap {
  position: fixed;
  z-index: 100002;
  bottom: 36px;
  width: calc(100% - 32px);
  max-width: 416px;
  transition: all 0.4s;
  left: 16px;
  right: 16px;
  margin: 0 auto;

  &--bottom {
    bottom: 36px;
  }

  &--center {
    //.alert-wrap--center
    bottom: initial;
    top: 50%;
    transform: translateY(calc(50% + 100px));
  }

  &--top {
    top: 30px;
    height: min-content;
  }

  &--custom {
    //.alert-wrap--top
    top: 50px;
    position: absolute;
  }
}

.alert {
  margin: 0 auto 20px;
  transform: translateY(300px);
  display: flex;
  align-items: center;
  width: 420px;

  padding: 14px 12px;
  background-color: color('neutral', 100);
  border-radius: 6px;
  box-shadow: 0 4px 16px rgba(#000, 0.16);
  transition: all 0.4s;
  opacity: 0;

  &--shown {
    // .alert__shown
    transform: translateY(0);
    opacity: 1;
  }

  &__icon {
    // .alert__icon
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: color('mint', 50);

    &--error {
      // .alert__icon--error
      color: color('yellow', 50);
    }

    &--dismiss {
      // .alert__icon--dismiss
      width: 16px;
      height: 16px;
      color: color('red', 50);
    }
  }

  &__text {
    // .alert__text
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  &__close {
    // .alert__close
    position: absolute;
    right: 12px;
    padding: 4px;
    background-color: $transparent;
    border-width: 0;
    font-family: Archivo;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: color('blue', 30);

    &:focus {
      outline-width: 0;
    }
  }

  &__close-icon {
    // .alert__close-icon
    width: 16px;
    height: 16px;
  }
}
