.tag-area {
  margin-bottom: 24px;

  &__box { // .tag-area__box
    position: relative;
    z-index: 1;
    border: 1px solid color('neutral', 95);
    border-radius: 16px;
    background-color: color('neutral', 100);
    transition: border-color $default-transition-time, box-shadow $default-transition-time;
    margin-bottom: 10px;
    overflow: hidden;

    .tag-area--active & {
      border-color: color('blue', 30);
      box-shadow: 0 0 0 1px color('blue', 30);
    }
  
    .tag-area--error & {
      border-color: color('red', 55);
      box-shadow: 0 0 0 1px color('red', 55);
    }
  }

  &__content { // .tag-area__content
    position: relative;
    z-index: 1;
    height: 175px;
    overflow-x: hidden;
  }  

  &__textarea { // .tag-area__textarea
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    min-height: 100%;
    padding: 6px 16px 16px;
    border-width: 0;
    border-radius: 17px;
    font-size: 14px;
    line-height: 38px;
    opacity: 0;
    resize: none;
    overflow: hidden;

    &:focus {
      outline-width: 0;
    }

    .tag-area--active &,
    .tag-area--empty & {
      z-index: 1;
      opacity: 1;
    }
  }

  &__list { // .tag-area__list
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 16px;
    margin: 0 -4px;
    list-style-type: none;
    pointer-events: none;
    overflow: hidden;

    .tag-area--active &,
    .tag-area--empty & {
      z-index: -1;
      visibility: hidden;
    }
  }

  &__item { // .tag-area__item
    margin: 0 4px 8px;
  }

  &__error-text { // .tag-area__error-text
    font-size: 14px;
    line-height: 16px;
    color: color('red', 55);
  }
}
