.auth-success {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 16px;

  &__box { // .auth-success__box
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 536px;
    margin: auto;
    text-align: center;
  }

  &__illustration { // .auth-success__illustration
    position: relative;
    width: 100%;
    max-width: 240px;
    margin-bottom: 22px;

    &::before {
      display: block;
      padding-top: 100%;
      content: '';
    }
  }

  &__illustration-img { // .auth-success__illustration-img
    position: absolute;
    max-width: 100%;
    max-height: 100%;

    &--lv1 { // .auth-success__illustration-img--lv1
      top: 14%;
      left: 12.9%;
      width: 75.6%;
    }

    &--lv2 { // .auth-success__illustration-img--lv2
      top: 31%;
      left: 16.1%;
      width: 82%;
    }

    &--lv3 { // .auth-success__illustration-img--lv3
      top: 52.7%;
      left: 4.5%;
      width: 25.4%;
    }

    &--lv4 { // .auth-success__illustration-img--lv4
      top: 1.6%;
      right: 23.1%;
      width: 32.6%;
    }
  }

  &__title { // .auth-success__title
    margin-bottom: 12px;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }

  &__text { // .auth-success__text
    max-width: 380px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    color: color("neutral", 50);

    &--register { // .auth-success__text--register
      max-width: 520px;
    }

    &--activate { // .auth-success__text--activate
      max-width: 410px;
    }
  }
}
