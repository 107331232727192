.select {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 16px;
  text-align: left;

  &--error-margin {
    margin-bottom: 16px;
  }

  &--error {
    .input-group__error-text {
      display: block;
    }
  }

  &--no-margin {
    padding-bottom: 0;
  }

  &--relative-dates {
    flex: 0 0 114px;
    margin-left: 14px;
    padding: 0;
  }

  &--simple {
    display: flex;
    align-items: center;
  }

  &--sort-field {
    width: 113px;
  }

  &--sort-status {
    width: 140px;
  }

  .select--date-filter & {
    padding-bottom: 0;
  }

  &__box {
    position: relative;
    background-color: #fff;
    border-radius: 16px;
  }

  &__input {
    display: block;
    width: 100%;
    min-width: 0;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
    transition: border-color $default-transition-time, box-shadow $default-transition-time;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);

    &--role-selected {
      padding-left: 40px;
    }

    .select--opened>*>& {
      border-color: color('blue', 30);
      box-shadow: 0 0 0 1px color('blue', 30);
    }

    .select--disabled & {
      background-color: color('neutral', 95);
      border-color: color('neutral', 95);
      color: color('neutral', 60);
      -webkit-text-fill-color: color('neutral', 60);
      opacity: 1;
      cursor: not-allowed;

      &:focus {
        box-shadow: none;
      }
    }

    .select--flag & {
      padding-left: 38px;
    }

    .select--date-filter>*>& {
      margin-bottom: 0;
      padding-left: 56px;
      font-weight: 500;
      color: color('blue', 30);
    }

    .select--no-margin &,
    .select--relative-dates & {
      margin-bottom: 0;
    }

    .select--error & {
      border-color: color('red', 55);
      box-shadow: 0 0 0 1px color('red', 55);
    }

    .select--button & {
      border: 2px solid color('neutral', 95);
      border-radius: 6px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
      color: color('neutral', 50);
    }

    .select--simple & {
      border: none;
      color: color('neutral', 50);
      font-size: 16px;
      padding: 0;
      box-shadow: none;
      text-indent: 3px;
    }
  }

  &__chevron {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    border-width: 0;
    padding: 0;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, color('neutral', 100) 15px);
    border-radius: 0 17px 17px 0;
    z-index: 1;

    &:not(button) {
      pointer-events: none;
    }

    &:focus {
      outline-width: 0;
    }

    .select--disabled & {
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, color('neutral', 95) 15px);
    }

    .select--small>*>& {
      width: 29px;
    }

    .select--simple & {
      position: relative;
    }
  }

  &__calendar {
    &:not(button) {
      cursor: pointer;
      pointer-events: none;
    }
  }

  &__chevron-icon {
    width: 24px;
    height: 24px;
    color: color('neutral', 30);

    .select--opened>*>*>*>& {
      transform: scaleY(-1);
    }

    .select--disabled>*>*>*>& {
      color: color('neutral', 60);
    }

    .select--small & {
      width: 16px;
      height: 16px;
    }

    .select--simple & {
      width: 10px;
      color: color('neutral', 50);
      margin-left: 3px;
    }
  }

  &__icon-wrap {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    .select--simple & {
      top: -3px;
      left: 7px;
    }
  }

  &__hidden-value {
    visibility: hidden;
    font-size: 16px;
    font-family: $font-primary;
  }

  &__flag {
    width: 21px;
    height: 14px;
    margin-right: 5px;
    border-radius: 3px;

    .select--disabled & {
      opacity: 0.5;
    }
  }

  &__current-flag {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  &__list {
    list-style-type: none;
    padding: 16px 8px;
    margin-bottom: 0;
  }

  &__title {
    color: #667799;
    font-size: 16px;
    font-weight: normal;
    margin: 20px 20px -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close {
    width: 10px;
    height: 10px;
    background: none;
    border: none;
    padding: 0;
    color: color('neutral', 50);

    &:focus {
      outline: none;
    }
  }

  &__option-list {
    display: none;
    position: absolute;
    z-index: 10;
    left: 0;
    min-width: 100%;
    background-color: color('neutral', 100);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(color('blue', 20), 0.15);
    transition: opacity 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: auto;

    &--roles {
      padding: 16px;
    }

    &--relative {
      position: relative;
      top: auto;
      border: 1px solid color('neutral', 95);
      box-shadow: none;
    }

    &--bottom {
      top: 60px;
      bottom: initial;
    }

    &--top {
      top: initial;
      bottom: 60px;
    }

    .select--opened & {
      display: block;
    }

    .select--date-filter & {
      left: auto;
      right: 0;
      padding: 16px;

      @media (min-width: $screen-sm-min) {
        min-width: 350px;
      }
    }

    .select--relative-dates & {
      min-width: 350px;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    border: 2px solid $transparent;
    cursor: pointer;
    transition: border-color $default-transition-time, background-color $default-transition-time,
      color $default-transition-time;

    &:hover {
      border-color: color('blue', 30);
      color: color('blue', 30);
      background-color: rgba(color('blue', 30), 0.05);

      .select--relative-dates &,
      .select--date-range & {
        background-color: color('neutral', 98);
        border-color: color('neutral', 98);
        color: color('neutral', 10);
      }

      .select__multi {
        opacity: 1;
      }
    }

    &--selected {
      border-color: color('blue', 30);
      color: color('blue', 30);
      background-color: rgba(color('blue', 30), 0.05);

      .select--relative-dates &,
      .select--date-range & {
        background-color: color('mint', 50);
        color: #fff;
        border-color: color('mint', 50);

        &:hover {
          background-color: color('mint', 50);
          color: #fff;
          border-color: color('mint', 50);
        }
      }
    }
  }

  &__error-text {
    font-size: 14px;
    line-height: 16px;
    color: color('red', 55);
  }

  &--date-filter {
    padding-bottom: 0;
  }

  &__date-input-prefix {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: color('neutral', 50);
  }

  &__date-option-list {
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
  }

  &__date-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &--selected {
      font-weight: 500;
      color: color('blue', 30);
    }
  }

  &__date-option-icon {
    display: none;
    width: 16px;
    height: 16px;
    color: color('mint', 50);

    .select__date-option--selected & {
      display: block;
    }
  }

  &__date-range {
    margin: 0 10px;
    padding: 14px 0;
    border-top: 1px solid color('neutral', 95);
  }

  &__date-range-title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }

  &__date-range-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date-range-input {
    flex-grow: 1;
    max-width: calc(50% - 5px);

    @media (min-width: $screen-sm-min) {
      max-width: calc(50% - 35px);
    }
  }

  &__date-range-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    color: color('neutral', 90);
  }

  &__role {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  &__error-text {
    font-size: 14px;
    line-height: 16px;
    color: color('red', 55);
  }

  &__multi {
    width: 20px;
    margin-left: auto;
    opacity: 0;
    transition: opacity 350ms ease;

    &--checked {
      opacity: 1;
    }
  }
}