.table-pagination {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  margin-top: 22px;

  @media (min-width: $screen-xs-min) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__col {
    // .table-pagination__col
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $screen-xs-min) {
      width: auto;
    }

    &--page-nav {
      // .table-pagination__col--page-nav
      margin-bottom: 16px;

      @media (min-width: $screen-xs-min) {
        margin-top: 0;
        margin-left: 32px;
      }
    }
  }

  &__per-page-label {
    // .table-pagination__per-page-label
    margin-right: 8px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  &__per-page-select {
    // .table-pagination__per-page-select
    flex-shrink: 0;
    flex-grow: 1;

    @media (min-width: $screen-xs-min) {
      max-width: 80px;
    }
  }

  &__button {
    // .table-pagination__button
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 48px;
    padding: 0;
    background-color: $transparent;
    border-width: 0;
    color: color('blue', 30);
    transition: color $default-transition-time;

    &:focus {
      outline: none;
    }

    &:disabled {
      color: color('neutral', 90);
      cursor: inherit;
    }

    &:not(:disabled):hover {
      color: color('blue', 55);
    }
  }

  &__button-icon {
    // .table-pagination__button-icon
    width: 32px;
    height: 32px;

    .table-pagination__button--prev & {
      transform: scaleX(-1);
    }
  }

  &__center {
    // .table-pagination__center
    display: flex;
    align-items: center;
    margin: 0 12px;
  }

  &__input {
    // .table-pagination__input
    flex-shrink: 0;
    width: 48px;
    margin-right: 8px;
    padding: 14px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  &__text {
    // .table-pagination__text
    font-size: 16px;
    line-height: 28px;
    color: color('neutral', 40);
    white-space: nowrap;
  }

  &__controls {
    //.table-pagination__controls
    display: flex;
    margin-left: 10px;
  }

  &__total {
    margin-left: 10px;
    color: color('neutral', 40);
  }
}
