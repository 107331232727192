.modal {
  position: fixed;
  z-index: 10001;
  overflow-x: hidden;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: background-color 0.2s;
  -webkit-overflow-scrolling: touch;

  &--show {
    // .modal--show
    display: flex;
  }

  &__body {
    // .modal__body
    position: relative;
    z-index: 1001;
    width: calc(100% - 32px);
    max-width: 600px;
    min-width: 320px;
    margin: auto;
    background-color: #fff;
    border-radius: 16px;
    transition: transform 0.3s ease-in-out, opacity 0.7s ease;
    opacity: 0;
    transform: perspective(600px) translateY(350px) rotateX(-45deg) scale(0.7);
    transform-origin: 50% 0;
    box-shadow: 0 32px 60px rgba(color('neutral', 40), .08);

    .modal--shown & {
      opacity: 1;
      transform: perspective(600px) rotateX(0) scale(1);
    }

    .modal--lg & {
      max-width: 800px;
    }

    .modal--md & {
      max-width: 600px;
    }
  }
}

.modal__body--w-582 {
  // .modal__body--w-582
  max-width: 582px !important;
}