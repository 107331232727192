.auth-form {
  width: 100%;
  max-width: 536px;
  margin: 0 auto;

  &--styled {
    // .auth-form--styled
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 492px;
    padding: 16px 0;
    border-radius: 24px;
    background-image: radial-gradient(#fff, rgba(color('neutral', 100), 0.1));

    @media (min-width: $screen-sm-min) {
      padding: 40px 64px 100px;
    }

    &::before {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(14deg);
      width: 282px;
      height: 200vh;
      background-color: rgba(#eef1f7, 0.51);
      content: '';
    }

    &::after {
      position: absolute;
      z-index: -2;
      display: block;
      bottom: 0;
      right: 0;
      transform: translate(62px, 41px);
      width: 147px;
      height: 130px;
      background-image: url('../../../assets/images/auth/cones@1x.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: '';

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../../../assets/images/auth/cones@2x.png');
      }
    }
  }

  &__welcome {
    // .auth-form__welcome
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    font-family: $font-primary;
    text-align: center;
  }

  &__title {
    // .auth-form__title
    margin-bottom: 32px;
    font-family: $font-secondary;
    font-size: 30px;
    line-height: 42px;
    font-weight: 800;
    letter-spacing: -0.02em;
    color: color('blue', 30);
    text-align: center;
    
    &.title__line_height {
      line-height: 30px;
    }
  }

  &__subtitle {
    // .auth-form__subtitle
    margin-bottom: 32px;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    &--with-line {
      // .auth-form__subtitle--with-line
      position: relative;
      padding-bottom: 10px;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 120px;
        height: 2px;
        border-radius: 2px;
        background-color: color('neutral', 95);
        content: '';
      }
    }
  }

  &__text {
    // .auth-form__text
    margin-bottom: 48px;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.015em;
    color: color('neutral', 50);
  }

  &__link-box {
    // .auth-form__link-box
    display: flex;
    flex-wrap: wrap;

    &--forgot-password {
      // .auth-form__link-box--forgot-password
      justify-content: flex-end;
      margin-bottom: 32px;
    }

    &--resend-code {
      // .auth-form__link-box--resend-code
      justify-content: center;
      margin-top: 49px;
      margin-bottom: 14px;
    }
  }

  &__link {
    // .auth-form__link
    font-family: $font-secondary;
    line-height: 28px;
    font-weight: 700;
    color: color('blue', 30);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    .auth-form__link-box--forgot-password & {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  &__resend-text {
    // .auth-form__link
    font-family: $font-secondary;
    line-height: 28px;
    font-weight: 400;
    text-decoration: none;
  }

  &__btn-row {
    // auth-form__btn-row
    display: flex;
    justify-content: space-between;
    margin: 32px -8px 0;

    &--center {
      justify-content: center;
    }
  }

  &__btn-row-item {
    // auth-form__btn-row-item
    margin: 0 8px 16px;

    @media (min-width: $screen-xs-min) {
      min-width: 170px;
    }
  }

  &__btn {
    // auth-form__btn
    &--verify {
      // auth-form__btn--verify
      margin-top: 24px;
    }
  }
}
