/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Roboto-Regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Roboto-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Roboto-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/Roboto-Regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Roboto-Medium.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Roboto-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Roboto-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Roboto-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/Roboto-Medium.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/roboto-v29-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/roboto-v29-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/roboto-v29-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/roboto-v29-latin-700.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* archivo-regular - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/archivo-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/archivo-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/archivo-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/archivo-v9-latin-regular.svg#Archivo')
      format('svg'); /* Legacy iOS */
}
/* archivo-500 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/archivo-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/archivo-v9-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/archivo-v9-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/archivo-v9-latin-500.svg#Archivo')
      format('svg'); /* Legacy iOS */
}
/* archivo-800 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 800;
  src: url('../../assets/fonts/archivo-v9-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/archivo-v9-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/archivo-v9-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/archivo-v9-latin-800.svg#Archivo')
      format('svg'); /* Legacy iOS */
}
/* archivo-700 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/archivo-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/archivo-v9-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/archivo-v9-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/archivo-v9-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/archivo-v9-latin-700.svg#Archivo')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'IBM';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/IBMPlexMono-Regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/IBMPlexMono-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/IBMPlexMono-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/IBMPlexMono-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/IBMPlexMono-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/IBMPlexMono-Regular.svg#IBM') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'IBM';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/IBMPlexMono-Medium.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/IBMPlexMono-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/IBMPlexMono-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/IBMPlexMono-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/IBMPlexMono-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../assets/fonts/IBMPlexMono-Medium.svg#IBM') format('svg'); /* Legacy iOS */
}
