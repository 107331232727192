.modal-role {
  &__section { // .modal-role__section
    margin-bottom: 22px;
    padding-bottom: 24px;
    border-bottom: 1px solid color("neutral", 90);
  }

  &__header { // .modal-role__header
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: color("neutral", 60);
    letter-spacing: 1px;
    text-transform: uppercase;

    &--mo-margin { // .modal-role__header--mo-margin
      margin-bottom: 0;
    }
  }

  &__group { // .modal-role__group
    margin-bottom: 16px;
  }

  &__permission-list { // .modal-role__permission-list
  }

  &__permission-item { // .modal-role__permission-item
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__permission-text { // .modal-role__permission-text
    margin-right: 12px;
  }

  &__title { // .modal-role__title
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    &--selected { // .modal-role__title--selected
      color: color("mint", 50);
    }
  }

  &__text-gray { // .modal-role__text-gray
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
    color: color("neutral", 50);
  }

  &__search { // .modal-role__search
    margin-bottom: 20px;
  }

  &__member-list { // .modal-role__member-list
    margin-bottom: 32px;
  }

  &__member-list-scroll-box { // .modal-role__member-list-scroll-box
    max-height: 168px;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;
  }

  &__member-row { // .modal-role__member-row
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__member-text { // .modal-role__member-text
    margin-right: 24px;
  }

  &__checkbox { // .modal-role__checkbox
    margin-right: 24px;
  }

  &__avatar-box { // .member-name__avatar-box
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    padding: 2px;
    border: 2px solid color("neutral", 90);
    border-radius: 50%;
  }

  &__avatar-wrapper { // .modal-role__avatar-wrapper
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: color("blue", 30);
    color: color("neutral", 100);
  }

  &__avatar { // .modal-role__avatar
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__avatar-placeholder { // .modal-role__avatar-placeholder
    width: 20px;
    height: 20px;
  }

  &__remove-member { // .modal-role__remove-member
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: auto;
    padding: 0;
    background-color: $transparent;
    border: 2px solid color("neutral", 90);
    border-radius: 50%;
    transition: border-color $default-transition-time;

    &:hover {
      border-color: color("blue", 30);
    }

    &:focus {
      outline-width: 0;
    }
  }

  &__remove-member-icon { // .modal-role__remove-member-icon
    width: 12px;
    height: 12px;
    color: color("neutral", 50);
    transition: color $default-transition-time;

    .modal-role__remove-member:hover & {
      color: color("blue", 30);
    }
  }

  &__message-box { // .modal-role__message-box
    margin: 20px 0 22px;
    text-align: center;
  }

  &__message { // .modal-role__message
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    padding: 6px 12px;
    background-color: color("yellow", 80);
    border-radius: 8px;
  }

  &__message-icon { // .modal-role__message-icon
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: color("yellow", 45);
  }

  &__message-text { // .modal-role__message-text
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -.01em;
  }
}
