.code-verify {
  width: 100%;
  padding: 16px 0;

  &__row {
    // .code-verify__row
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -6px 12px;
  }

  &__input {
    // .code-verify__input
    width: 100%;
    max-width: 44px;
    margin: 0 6px;
    padding: 5px;
    border-radius: 10px;
    font-family: $font-secondary;
    font-size: 32px;
    line-height: 44px;
    color: color('neutral', 10);
    text-align: center;

    &:focus {
      padding: 4px;
      border: 2px solid color('blue', 30);
    }

    .code-verify--completed & {
      pointer-events: none;
      padding: 4px;
      border: 2px solid color('mint', 50);
      background-color: color('mint', 90);
      color: color('mint', 50);
    }

    .code-verify--disabled & {
      pointer-events: none;
      border: 1px solid color('neutral', 90);
      background-color: color('neutral', 90);
      color: color('neutral', 60);
    }

    .code-verify--error & {
      border: 2px solid color('red', 55);
      box-shadow: 0 0 2px color('red', 90);

      &:focus {
        border: 1px solid color('red', 55);
        box-shadow: 0 0 2px color('red', 90);
      }
    }
  }

  &__separator {
    // .code-verify__separator
    display: block;
    width: 10px;
    height: 2px;
    margin: 0 6px;
    border-radius: 2px;
    background-color: color('neutral', 90);
  }

  &__element-with-separator {
    // .code-verify__row
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__error-text {
    // .code-verify__error-text
    display: none;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: color('red', 50);

    .code-verify--error & {
      display: block;
    }
  }
}
