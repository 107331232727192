.auth {
  min-height: 100vh;

  @media (min-width: $screen-lg-min) {
    padding-left: 500px;
  }

  @media (min-width: $screen-xl-min) {
    padding-left: 620px;
  }

  &__content { // .auth__content
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 24px 42px;
    overflow: hidden;

    &-support {
      box-shadow: none;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      position: absolute;
      bottom: 7%;
      right: 5%;
      cursor: unset;
      width: 74px;
      height: 74px;
      user-select: none;
  
      &:focus {
        border: none;
        outline: none;
      }
      & circle {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
  
      & path {
        cursor: pointer;
      }
      & g:active circle{
        fill: color('blue', 40);
      }
    }

    @media (min-width: $screen-lg-min) {
      margin: 0;
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  &__header { // .auth__header
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 86px;
    padding: 22px 0;
  }

  &__header-text { // .auth__header-text
    margin-right: 16px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  &__progress {
    max-width: 536px;
    margin: 52px auto 34px;
  }

  &__bottom { // .auth__bottom
    display: flex;
    width: 100%;
    max-width: 562px;
    margin: auto auto 0;
    padding-top: 84px;
    padding-left: 0;
    list-style: none;

    
  }

  &__bottom-item { // .auth__bottom-item
    width: 100%;
    max-width: 100%/3*1;
    padding: 0 2px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: color('neutral', 70);
  }

  &__bottom-link { // .auth__bottom-link
    text-decoration: none;
    color: color('neutral', 70);
    transition: color $default-transition-time;

    &:hover {
      color: color('neutral', 30);
      text-decoration: underline;
    }

  }
}
