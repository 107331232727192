.not-found {
  text-align: center;
  max-width: 605px;
  margin: 50px auto;

  &__title {
    font-size: 40px;
    line-height: 42px;
    color: color('blue', 30);
    font-family: $font-secondary;
    font-weight: 800;
  }

  &__txt {
    font-size: 20px;
    line-height: 28px;
    color: color('neutral', 50);
  }
}
