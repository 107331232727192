.add-user {
  text-align: left;
  
  &__list-box { // .add-user__list-box
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 28px;
  }
  
  &__list { // .add-user__list
    flex-grow: 1;
    display: flex;
    width: 100%;
    margin-right: 8px;
    border-bottom: 2px solid color("neutral", 90);
    padding-bottom: 20px;

    @media (min-width: $screen-md-min) {
      width: calc(100% - 135px);
    }
  }
  
  &__list-prefix { // .add-user__list-prefix
    margin-right: 8px;
    padding-top: 13px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  
  &__tag-list { // .add-user__tag-list
    flex-grow: 1;
    font-size: 14px;
    line-height: 20px;
    color: color("neutral", 50);
  }
  
  &__role { // .add-user__role
    flex-shrink: 0;
    width: 100%;
    margin: 0;
    margin-top: 16px;
    padding: 0;

    @media (min-width: $screen-md-min) {
      margin-top: 0;
      width: 127px;
    }
  }
  
  &__message { // .add-user__message
    margin-bottom: 16px;
    padding: 16px 16px 8px;
    border: 1px solid color("neutral", 90);
    border-radius: 16px;

    @media (min-width: $screen-md-min) {
      padding: 32px 32px 24px;
    }
  }
  
  &__message-input { // .add-user__message-input
    display: block;
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
    border-width: 0;
    font-size: 16px;
    line-height: 28px;
    color: color("neutral", 50);

    &::placeholder {
      color: color("neutral", 50);
    }

    &:focus {
      outline-width: 0;
    }
  }
  
  &__message-bottom { // .add-user__message-bottom
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    border-top: 2px solid color("neutral", 90);
    font-size: 14px;
    line-height: 20px;
    color: color("neutral", 80);
  }
  
  &__message-text { // .add-user__message-text
    margin-bottom: 0;
  }
  
  &__message-limit { // .add-user__message-limit
    margin-bottom: 0;
  }
  
  &__message-limit-count { // .add-user__message-limit-count
    font-weight: 500;
  }
  
  &__note { // .add-user__note
    margin-bottom: 32px;
  }
}