.user-nav {
  position: relative;

  &__profile-wrapper {
    //user-nav__profile-wrapper
    padding: 16px;
    border-bottom: 1px solid color('neutral', 95);
    margin: 0 0 4px;
  }

  &__box {
    // .user-nav__box
    display: flex;
    align-items: center;
    padding: 13px 15px 13px 18px;
    background-color: var(--menu-color);
    border-radius: 80px;
  }

  &__avatar-link {
    // .user-nav__avatar-link
    flex-shrink: 0;
    display: none;
    width: 52px;
    height: 52px;
    margin-right: 14px;
    border: 2px solid color('blue', 20);
    border-radius: 50%;
    box-shadow: 0 0 0 2px color('blue', 30);

    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }

  &__avatar {
    // .user-nav__avatar
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__name {
    // .user-nav__name
    margin-bottom: 0;
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: color('neutral', 100);
    white-space: nowrap;
  }

  &__dropdown-button {
    // .user-nav__dropdown-button
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-left: 7px;
    padding: 0;
    border-width: 0;
    background-color: $transparent;
    color: color('neutral', 100);

    &:focus {
      outline: none;
    }
  }

  &__dropdown {
    // .user-nav__dropdown
    position: absolute;
    z-index: 10;
    top: 135%;
    right: -23px;
    transform: translateY(18px);
    min-width: 313px;
    max-width: 100vw;
    padding: 6px 0 12px;
    background-color: color('neutral', 100);
    border-radius: 16px;
    box-shadow: 0 4px 14px rgba(#000, 0.08);
  }

  &__dropdown-button-icon {
    // .user-nav__dropdown-button-icon
    width: 24px;
    height: 24px;
  }

  &__dropdown-list {
    // .user-nav__dropdown-list
    margin-bottom: 0;
    padding-left: 0;
  }

  &__dropdown-item {
    // .user-nav__dropdown-item
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__dropdown-link {
    // .user-nav__dropdown-link
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 24px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: color('neutral', 30);
    transition: background-color $default-transition-time;

    &:hover {
      background-color: color('neutral', 98);
    }

    &--red {
      color: color('red', 55);
    }
  }

  &__dropdown-link-icon {
    // .user-nav__dropdown-link-icon
    flex-shrink: 0;
    width: 20px;
    margin-right: 16px;
  }  

  &__act-as {
    // .user-nav__act-as
    margin-bottom: 0;
    padding: 16px 24px;
    font-size: 12px;
    line-height: 18px;
    color: color('neutral', 60);
  }
}
