.onboarding-tooltip {
  width: 100%;
  max-width: 275px;
  padding: 20px 24px;
  background-color: color("blue", 40);
  border-radius: 16px;
  color: color("neutral", 100);

  &__content { // .onboarding-tooltip__content
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }

  &__nav { // .onboarding-tooltip__nav
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__steps-counter { // .onboarding-tooltip__steps-counter
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
  }

  &__btn-box { // .onboarding-tooltip__btn-box
    margin: 0 -4px;
  }

  &__btn { // .onboarding-tooltip__btn
    margin: 0 4px;
    padding: 6px 12px;
    border-radius: 5px;
    border-width: 0;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    &:focus {
      outline-width: 0;
    }

    &--skip { // .onboarding-tooltip__btn--skip
      background-color: color("blue", 30);
      color: color("neutral", 100);
    }

    &--next { // .onboarding-tooltip__btn--next
      background-color: color("neutral", 100);
      color: color("blue", 40);
    }
  }
}
