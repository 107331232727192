.logo {
	&__top-part { // .logo__top-part
		fill: color("neutral", 40);

		.logo--white & {
			fill: color("neutral", 100);
		}
	}
	&__bottom-part { // .logo__bottom-part
		fill: color("blue", 55);

		.logo--white & {
			fill: color("neutral", 100);
		}
	}
}
