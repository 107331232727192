.code {
  border-radius: 8px;
  background-color: color('neutral', 98);

  .token {
    color: #000;

    &.boolean {
      color: #9c419a;
    }

    &.function,
    &.string {
      color: #ed7a4b;
    }

    &.number {
      color: #b04057;
    }
  }

  &--response {
    color: #9e9e9e;

    .token {
      color: #9e9e9e;

      &.boolean,
      &.function,
      &.string,
      &.number {
        color: #9e9e9e;
      }
    }
  }
}
code {
  font-family: $font-third;
}
