.profile-card {
  padding: 20px 16px;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(color("neutral", 30), .1);
  background-color: color("neutral", 100);

  @media (min-width: $screen-lg-min) {
    padding: 31px 23px;
  }

  &__row { // .profile-card__row
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: $screen-lg-min) {
      flex-wrap: nowrap;
    }
  }
  
  &__icon-box { // .profile-card__icon-box
    display: none;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    color: color("neutral", 80);

    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }
  
  &__content { // .profile-card__content
    display: none;
    padding-top: 40px;

    .profile-card--opened & {
      display: block;
    }
  }

  &__info { // .profile-card__info
    @media (min-width: $screen-lg-min) {
      margin-right: 32px;
    }
  }
  
  &__title { // .profile-card__title
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  
  &__text { // .profile-card__text
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: color("neutral", 50);
  }
  
  &__action-box { // .profile-card__action-box
    width: 100%;
    margin-top: 32px;

    @media (min-width: $screen-lg-min) {
      margin-left: auto;
      width: auto;
    }
  }

  &__action-button { // .profile-card__action-button
    min-width: 172px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 8px;
  }

  &__form-submit-row { // .profile-card__form-submit-row
    margin-bottom: 34px;
  }
  
  &__form-submit { // .profile-card__form-submit
    min-width: 172px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &__form-link { // .profile-card__form-link 
    font-family: $font-secondary;
    line-height: 28px;
    font-weight: 700;
    color: color('blue', 55);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    } 
  }
}