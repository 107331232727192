.card {
  position: relative;
  background-color: color('neutral', 100);
  border: 2px solid color('neutral', 95);
  border-radius: 16px;
  padding: 23px;

  &--shadow {
    // .card--shadow
    border: 1px solid var(--Neutrals-95---Card-Border, #f0f1f5);
    box-shadow: 0px 1px 1px 0px rgba(9, 30, 66, 0.25), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  }

  &--zp-shadow {
    border: none;
    background: var(--white, #fff);
    box-shadow: 0px 0.938px 13.134px 0px rgba(0, 7, 22, 0.14),
      0px 0px 3.752px 0px rgba(65, 77, 92, 0.2);
  }

  &__title {
    // .card__title
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
  }

  &__text {
    // .card__text
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    color: color('neutral', 40);

    .card__header-row & {
      margin-bottom: 16px;
    }
  }

  &__header-row {
    // .card__header-row
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: -8px;
    margin-right: -8px;

    @media (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }

  &__header-col {
    // .card__header-col
    padding: 0 8px;
  }
}
