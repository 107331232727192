.mobile-menu {
  display: none;
  position: fixed;
  z-index: 10050;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 100%;
  padding: 25px 0;
  background-color: color('blue', 30);
  -webkit-overflow-scrolling: touch;
  transform: translateX(-100%);
  transition: transform $default-transition-time;

  &--will-toggle { // .mobile-menu--will-toggle
    display: flex;
  }

  &--show { // .mobile-menu--show
    display: flex;
    transform: none;
  }

  &__top-row { // .mobile-menu__top-row
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    padding: 0 16px;
  }

  &__logo { // .mobile-menu__logo
    display: block;
    width: 100%;
    max-width: 120px;
    color: color("neutral", 100);
  }

  &__close { // .mobile-menu__close
    width: 48px;
    height: 48px;
    padding: 3px;
    border-width: 0;
    background-color: $transparent;
    color: color('neutral', 100);
    transform: translateX(3px);

    &:focus {
      outline-width: 0;
    }
  }

  &__close-icon { // .mobile-menu__close-icon
    width: 24px;
    height: 24px;
  }

  &__nav { // .mobile-menu__nav
    overflow-y: auto;
  }

  &__nav-list { // .mobile-menu__nav-list
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  &__nav-item { // .mobile-menu__nav-item
  }

  &__nav-link { // .mobile-menu__nav-link
    display: flex;
    align-items: center;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    color: color('neutral', 100);
    text-decoration: none;
    width: 100%;
  
    &--active { // .mobile-menu__nav-link--active
      background-color: color("blue", 20);
    }

    &:active, &:focus {
      outline: none;
    }
  }

  &__nav-icon { // .mobile-menu__nav-icon
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
