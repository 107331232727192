.table {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  border-collapse: separate;
  border-spacing: 0;

  &__body {
    &__isEmpty {
      display: flex;
      justify-content: center;
    }
  }

  &__data-row {
    // .table__data-row
    // TODO: adjust styles when UI design is ready for this
    // TODO: maybe refactor
    &:hover,
    &--selected {
      .table__cell--data {
        cursor: pointer;
        background-color: color('blue', 95);
      }
    }

    &--selected:hover {
      .table__cell--data {
        cursor: default;
      }
    }

    &--deactivated:hover {
      .table__cell--data {
        background: none;
      }
    }

    &--no-hover:hover {
      .table__cell--data {
        background: none;
        cursor: default;
      }
    }
  }

  &--without-border {
    // .table--without-border
    border-spacing: 2px;
  }

  &__cell {
    &--right {
      // .table__cell--right
      text-align: right;
    }

    &--billPaynemts {
      width: 14.3%;
    }

    // .table__cell
    white-space: nowrap;
    text-overflow: ellipsis;

    &--nowrap {
      // .table__cell--nowrap
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--header {
      // .table__cell--header
      padding: 15px 16px;
      vertical-align: middle;
      background-color: color('blue', 30);
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    &--data {
      // .table__cell--data
      height: 40px;
      padding: 6px 16px;
      border: 1px solid color('neutral', 95);
      background-color: color('neutral', 100);
      vertical-align: middle;

      .table--without-border & {
        border-width: 0;
      }

      &.table__cell--fixed {
        z-index: 1;
      }

      .table__data-row--deactivated & {
        opacity: 0.5;
      }
    }

    &--name {
      // .table__cell--name
      padding-left: 3px;
      font-family: Archivo;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: color('neutral', 20);
    }

    &--status {
      .table__data-row--deactivated & {
        opacity: 1;
      }
    }

    &--quick-actions {
      // .table__cell--quick-actions
      width: 46px;
      padding: 0;

      .table__data-row--deactivated & {
        opacity: 1;
      }
    }

    &--fixed {
      // .table__cell--fixed
      position: absolute;
      width: 160px;
      margin-left: -160px;

      &::after {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 32px;
        height: 100%;
        background-image: linear-gradient(to right, rgba(#3d475c, 0.15), rgba(#3d475c, 0));
        content: '';
      }
    }

    &--email {
      // .table__cell--email
      width: 100%;
      max-width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__header-row {
    // .table__header-row
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: color('neutral', 100);

    .table--without-border & {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }

    &--flex {
      display: flex;
    }
  }

  &__sort-btn {
    // .table__sort-btn
    display: flex;
    align-items: center;
    padding: 0;
    border-width: 0;
    background-color: $transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;

    &:focus {
      outline-width: 0;
    }
  }

  &__sort-icon {
    // .table__sort-icon
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  &__quick-actions-cell {
    // .table__quick-actions-cell
    min-width: 0;
    width: 46px;
  }

  &__copy-btn {
    // .table__copy-btn
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    padding: 0;
    background-color: rgba(255, 255, 255, 0);
    border-width: 0;
    color: color('neutral', 80);
    transition: color 0.2s;
    transform: translateY(3px);

    &:focus {
      outline: none;
    }

    &:hover {
      color: color('blue', 30);
    }
  }

  &__no-data {
    margin-top: 64px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &__title {
      margin-top: 16px;
      color: #003566;
      font-family: Archivo;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    &__text {
      color: var(--Neutrals-50---Secondary-Text, #679);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
