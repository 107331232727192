.insights {
  &__chart-col { // .insights__chart-col
    margin-bottom: 36px;

    @media (min-width: $screen-md-min) {
      max-width: calc(100% - 300px);
      margin-bottom: 0;
    }
  }

  &__chart-box { // .insights__chart-box
    display: flex;
    flex-direction: column;
    padding: 38px 24px 19px;
    border: 2px solid color("neutral", 95);
    border-radius: 16px;
    background-color: color("neutral", 100);
  }

  &__chart-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 16px;

    @media (min-width: $screen-xl-min) {
      flex-direction: row;
      margin-bottom: 34px;
    }
  }

  &__chart-top-wrapper { // .insights__chart-top-wrapper
    display: flex;
  }

  &__chart-date-filter { // .insights__chart-date-filter
    width: 100%;

    @media (min-width: $screen-xl-min) {
      max-width: 264px;
    }
  }

  &__icon-box { // .insights__icon-box
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 24px;
    border-radius: 8px;
    border: 2px solid color("mint", 84);
    background-color: color("mint", 90);
    color: color("mint", 68);
  }

  &__icon { // .insights__icon
    width: 20px;
    height: 20px;
  }

  &__chart-title-box { // .insights__chart-title-box
    margin-right: 32px;
    margin-bottom: 16px;
  }

  &__title { // .insights__title
    margin-bottom: 0;
    font-size: 40px;
    line-height: 44px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }

  &__subtitle { // .insights__subtitle
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
    color: color("neutral", 50);
  }

  &__chart { // .insights__chart
    flex-grow: 1;
    position: relative;
    width: 100%;
    max-height: calc(100% - 280px);
    height: 454px;
    min-height: 241px;
  }

  &__indicators-col { // .insights__indicators-col
    @media (min-width: $screen-md-min) {
      max-width: 300px;
    }
  }

  &__indicator { // .insights__indicator
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__chart-indicator-filter { // .insights__chart-indicator-filter
    margin-bottom: 14px;
    width: 100%;
  }
}
