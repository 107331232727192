.dev-tools-menu {
  display: none;
  position: absolute;
  left: 12px;
  top: 0;
  z-index: 2;
  background-color: color('neutral', 98);
  padding: 80px 10px 0;
  min-height: 100%;
  height: calc(100vh - 54px);
  box-shadow: inset -11px 0px 12px -6px #0007161a;
  border-left: 3px solid color('neutral', 95);
  margin: 0 0 0 2px;
  transition: left 0.3s linear;

  @media (min-width: $screen-lg-min) {
    display: block;
  }

  &__close-button {
    // .dev-tools-menu__close-button
    position: absolute;
    z-index: 10;
    top: 29px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
    background-color: $transparent;
    cursor: pointer;
    color: color('blue', 30);
    transition: color $default-transition-time;

    &:focus {
      outline-width: 0;
    }
  }

  &__close-icon {
    // .dev-tools-menu__close-icon
    width: 14px;
    height: 14px;
    color: color('neutral', 50);
  }

  &--open {
    left: -226px;
  }

  &--active {
    left: 240px;
  }

  &__wrapper {
    padding: 0 0 0 20px;
    width: 203px;
  }

  &__category {
    // .dev-tools-menu__category
    margin-bottom: 28px;
  }

  &__category-title {
    // .dev-tools-menu__category-title
    color: color('neutral', 30);
    font-size: 14px;
    line-height: 22px;
    position: relative;
    margin-bottom: 12px;
  }

  &__list {
    // .dev-tools-menu__list
    list-style: none;
    padding: 0;
    display: grid;
    gap: 12px;
  }

  &__item {
    // .dev-tools-menu__item
    display: flex;
    align-items: center;
    padding: 0 0 0 4px;
  }

  &__link {
    // .dev-tools-menu__link
    color: color('neutral', 30);
    font-size: 14px;
    line-height: 20px;
    padding-left: 8px;
    text-decoration: none;
    &.dev-tools-menu__link--active {
      color: color('blue', 40);
      text-decoration: underline;
    }
  }

  &__line {
    background-color: color('neutral', 90);
    width: 100%;
    height: 1px;
    margin: 0 0 28px;
  }

  &__handle-book {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: color('neutral', 40);
  }

  &__more {
    font-size: 12px;
    line-height: 16px;
    color: color('neutral', 60);
  }
}
