.table-scroll {
  position: relative;
  width: 100%;

  &__box { // .table-scroll__box
    overflow-x: auto;
    width: 100%;

    .table-scroll--with-fixed-col & {
      padding-left: 160px;
    }
  }
}
