.password-strength {
  &__criteria-list {
    // .password-strength__criteria-list
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-left: 0;
    list-style-type: none;
  }

  &__criteria {
    // .password-strength__criteria
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: color('neutral', 50);
  }

  &__check-icon {
    // .password-strength__check-icon
    width: 12px;
    height: 12px;
    margin-right: 4px;
    color: color('neutral', 50);

    .password-strength__criteria--active & {
      color: color('mint', 50);
    }
  }

  &__progress-box {
    // .password-strength__progress-box
  }

  &__progress {
    // .password-strength__progress
    position: relative;
    width: 100%;
    height: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: color('neutral', 90);
    overflow: hidden;
  }

  &__progress-value {
    // .password-strength__progress-value
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: color('neutral', 50);
    width: 0;
    transition: width 450ms linear;

    .password-strength__progress-box--red & {
      background-color: color('red', 55);
      width: 25%;
    }

    .password-strength__progress-box--half & {
      width: 50%;
    }

    .password-strength__progress-box--yellow & {
      background-color: color('yellow', 55);
      width: 75%;
    }

    .password-strength__progress-box--mint & {
      background-color: color('mint', 50);
      width: 100%;
    }
  }

  &__message {
    // .password-strength__message
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: color('neutral', 50);

    .password-strength__progress-box--red & {
      color: color('red', 55);
    }

    .password-strength__progress-box--yellow & {
      color: color('yellow', 55);
    }

    .password-strength__progress-box--mint & {
      color: color('mint', 50);
    }
  }
}
