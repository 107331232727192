.react-datepicker-wrapper {
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  display: block;
  min-width: 100%;
  padding: 15px;
  border: 2px solid color('neutral', 95);
  border-radius: 16px;
  background-color: color('neutral', 100);
  color: color('neutral', 10);

  @media (min-width: $screen-xs-min) {
    padding: 40px;
  }

  .datepicker__calendar & {
    display: flex;
    padding: 0;
    border: none;
    position: relative;
  }

  &__navigation {
    // .react-datepicker__navigation
    position: absolute;
    background: none;
    border: none;

    &--previous {
      // .react-datepicker__navigation--previous
      left: -18px;
      top: 105px;
    }

    &--next {
      // .react-datepicker__navigation--next
      right: -18px;
      top: 105px;
    }
  }

  &__month-container {
    // height: 430px;
    // overflow-y: hidden;

    // .react-datepicker__month-container
    .datepicker__calendar & {
      border: 2px solid color('neutral', 95);
      border-radius: 16px;
      padding: 12px;
      margin: 0 8px;
    }
  }

  &__tab-loop {
    position: relative;
    z-index: 10;
    width: 100%;
  }

  &__header-nav {
    // .react-datepicker__header-nav
    display: flex;
    align-items: center;
    width: 100%;
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  &__header-month {
    // .react-datepicker__header-month
    margin-right: 5px;
    cursor: pointer;
  }

  &__header-year-select {
    // .react-datepicker__header-year-select
    border-width: 0;
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &__header-btn-box {
    // .react-datepicker__header-btn-box
    display: flex;
    margin-left: auto;
  }

  &__month-nav-btn {
    // .react-datepicker__month-nav-btn
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: $transparent;
    border-width: 0;

    &:focus {
      outline-width: 0;
    }
  }

  &__month-nav-icon {
    // .react-datepicker__month-nav-icon
    width: 24px;
    height: 24px;

    .react-datepicker__month-nav-btn--prev & {
      transform: rotate(90deg);
    }

    .react-datepicker__month-nav-btn--next & {
      transform: rotate(-90deg);
    }
  }

  &__day-names {
    // .react-datepicker__day-names
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-xs-min) {
      margin-top: 17px;
    }

    .datepicker__calendar & {
      margin-top: 0;
    }
  }

  &__week {
    // .react-datepicker__week
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-xs-min) {
      margin-top: 18px;

      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    .datepicker__calendar & {
      margin: 0;
      justify-content: flex-start;
    }
  }

  &__day-name {
    // .react-datepicker__day-name
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    .datepicker__calendar & {
      width: 24px;
      height: 24px;
      margin-right: 2px;
    }
  }

  &__day {
    // .react-datepicker__day
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    &--selected {
      // .react-datepicker__day--selected
      background-color: color('blue', 30);
      border-radius: 10px;
      color: color('neutral', 100);

      .datepicker__calendar & {
        border-radius: 0;
        background-color: color('mint', 50);
        color: #fff;

        &.react-datepicker__day--in-range {
          background: color('mint', 90);
          color: color('neutral', 10);
        }
      }
    }

    &--outside-month {
      color: color('neutral', 10);
    }

    &--disabled {
      color: color('neutral', 70);
    }


    &--in-range {
      background: color('mint', 90);
    }

    &:empty {
      // display: none;
      visibility: hidden;
    }

    .datepicker__calendar & {
      width: 24px;
      height: 24px;
      border: 2px solid color('neutral', 90);
      margin: 0 0 -2px -2px;
      box-sizing: content-box;
      outline: none;
    }

    &--today {
      .datepicker__calendar & {
        background-color: color('mint', 50);
        color: #fff;

        &.react-datepicker__day--in-range {
          background-color: color('mint', 68);
        }
      }
    }
  }

  &__current-month {
    // .react-datepicker__current-month
    text-align: center;
    font-size: 12px;
    font-family: $font-secondary;
    font-weight: 700;
  }
}

.react-datepicker-popper {
  max-width: 100%;

  &[data-placement='top-start'] {
    transform: translateY(-64px) !important;
  }

  &[data-placement='bottom-start'] {
    transform: translateY(0) !important;
  }
}

.select--date-filter .react-datepicker {
  min-width: 260px;
  padding: 26px;

  &__week {
    margin-top: 0;
    margin-bottom: 0;
  }
}