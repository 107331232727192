.dashboard {
  width: 100%;
  min-height: 100vh;
  position: relative;

  &__helpPanel {
    overflow-y: auto;
    height: 100vh;
    width: 15.2%;
    position: absolute;
    top: 0;
    right: -100%;
    background: var(--Neutrals-100---White, #fff);
    box-shadow: 0px 1px 1px 0px rgba(233, 235, 237, 0.4), 0px 6px 36px 0px rgba(0, 7, 22, 0.1);
    transition: 0.6s all;

    &.open {
      right: 0;
      transition: 0.6s all;
    }

    &__header {
      border-bottom: 2px solid #e9ebed;
      padding: 20px;
      padding-left: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--Neutrals-10---Primary-Text, #14181f);
      font-family: Archivo;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: -0.2px;

      img {
        cursor: pointer;
      }
    }

    &__body {
      padding: 20px 28px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__descr {
      color: var(--Neutrals-50---Secondary-Text, #679);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      a {
        color: var(--Neutrals-50---Secondary-Text, #679);
      }
    }

    &__subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--Neutrals-10---Primary-Text, #14181f);
      font-family: Archivo;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 18px;
    }

    &__divider {
      width: 100%;
      height: 2px;
      background: #e9ebed;
    }

    &__link {
      color: var(--Blue-40, #006acc);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }

    &__iconLink {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &__init-block {
    position: relative;
    background: var(--Blue-20---Overlay, #003566);
    padding-top: 62px;
    padding-bottom: 36px;
    padding-left: 392px;

    @media (max-width: 1400px) {
      padding-left: 280px;
    }

    @media (max-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 0;
    }

    &__step {
      color: var(--Neutrals-80---Outline, #c2c9d6);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }

    &__title {
      color: var(--Neutrals-100---White, #fff);
      font-family: Archivo;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;
      letter-spacing: -0.64px;
    }
  }

  &__box {
    // dashboard__box
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  &__main {
    // dashboard__main
    width: 100%;
    padding-left: 15px;
    transition: padding-left 0.3s linear;

    &--openNavBar {
      padding-left: 255px;

      &__init {
        padding-top: 32px;
        padding-left: 392px;
        padding-right: 6%;

        @media (max-width: 1400px) {
          padding-left: 280px;
          padding-right: 2%;
        }

        &__first {
          @media (max-width: 1200px) {
            padding-left: 180px;
          }
        }
      }
    }

    &--openToolsMenu {
      padding-left: 467px;
    }

    @media (max-width: $screen-lg-min) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__content {
    // dashboard__content
    padding: 24px 24px 64px;
    background-color: color('neutral', 100);
    border-left: 1px solid color('neutral', 95);

    &__init {
      padding: 0;
      padding-top: 16px;
      padding-bottom: 12px;
      border-radius: 16px;
      background: var(--white, #fff);

      box-shadow: 0px 0px 4px 0px rgba(65, 77, 92, 0.2), 0px 1px 14px 0px rgba(0, 7, 22, 0.14);
      border-left: 1px solid color('neutral', 95);
    }

    &__loanCreation {
      min-height: 730px;
      height: calc(100vh - 54px - 55px - 80px);
      padding: 0;
      margin: 40px 0;
      margin-right: 16px;
      border-radius: 16px;
      background: var(--white, #fff);

      &--isBannerClosed {
        height: calc(100vh - 54px - 80px);
      }

      box-shadow: 0px 1px 14px 0px rgba(0, 7, 22, 0.14), 0px 0px 4px 0px rgba(65, 77, 92, 0.2);

      @media (max-height: 850px) {
        min-height: 600px;
      }

      @media (max-height: 750px) {
        min-height: 550px;
      }

      @media (max-height: 600px) {
        min-height: 500px;
      }
    }

    @media (max-width: $screen-lg-min) {
      border: 0;
    }

    &__zp-overview {
      background: var(--Neutrals-98---Base-Background, #f9fafb);
    }
  }

  &__title {
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: 700;
    line-height: 48px;

    @media (min-width: $screen-lg-min) {
      font-size: 40px;
      line-height: 64px;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 24px;
    color: color('neutral', 40);

    @media (min-width: $screen-lg-min) {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__table-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  }

  &__search {
    width: 100%;
    margin-bottom: 10px;

    @media (min-width: $screen-md-min) {
      max-width: 343px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  &__add-user {
    width: 100%;
    max-width: 166px;
  }

  &__table-bottom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  &__pagination {
    margin-left: auto;
  }
}
