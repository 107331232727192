.request-status {
  font-family: $font-primary;
  font-size: 16px;
  color: color('neutral', 50);
  font-weight: 400;
  display: flex;
  align-items: baseline;

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}