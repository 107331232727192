@import '../../../scss/utils/_normalize.scss';
@import '../../../scss/utils/_reboot.scss';
@import '../../../scss/vars/_colors.scss';
@import '../../../scss/utils/_functions.scss';
@import '../../../scss/vars/_base.scss';
@import '../../../scss/vars/_sizes.scss';
@import '../../../scss/vendor/_vendor.scss';

.init-setup {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .selection {
    display: flex;
    gap: 16px;
  }

  &__wrapper {
    padding: 0 20px;
  }

  &__footer {
    border-top: 2px solid #E9EBED;
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    &--complete {
      padding: 0 20px;
      position: relative;

      &__imageBlock {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__text {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: 80px;
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        &__title {
          color: #FFF;
          font-family: Archivo;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
        }

        &__subtitle {
          color: #FFF;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  &__complete-text {
    margin: 20px 0;
    color: var(--Neutrals-50---Secondary-Text, #679);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    display: flex;
    width: 124px;
    height: 42px;
    padding: 8px 16px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 16px;
    margin: 10px 0;
    transition: all $default-transition-time;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Blue-30, #004F99);

    &:disabled {
      background: var(--Blue-95---Background, #E5F3FF);
      color: color('blue', 80);
      border: none;

      &>svg {
        color: color('blue', 80);
      }
    }

    &__progress {
      padding: 24px;
    }

    &--prev {
      padding: 2px 27px;
      outline: none;
      border: none;
      background: transparent;
      color: var(--Blue-30, #004F99);
      font-family: Archivo;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    &--complete {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 0;
      color: var(--Neutrals-100---White, #FFF);
      text-align: center;
      font-feature-settings: 'clig'off, 'liga'off;
      font-family: Archivo;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }

  &__title {
    padding: 4px 0;
    color: var(--Neutrals-10---Primary-Text, #14181F) !important;
    font-family: Archivo !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: -0.36px !important;
  }

  &__descr {
    color: var(--Neutrals-50---Secondary-Text, #679) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
  }
}

.onboarding-progress {
  .progress__bar {
    background-color: color('blue', 90);
  }
}

.progress__bar-value--register {
  background-color: color('blue', 30);
}

@import './SelectionFlow';
@import './MultiStepForm';