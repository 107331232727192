.progress {
  width: 100%;
  text-align: center;

  &__bar {
    position: relative;
    width: 100%;
    height: 12px;
    background-color: color('neutral', 95);
    border-radius: 8px;
  }

  &__bar-value {
    top: 0;
    left: 0;
    height: 100%;
    background-color: color('mint', 50);
    border-radius: 8px;
    transition: width $default-transition-time;
  }

  &__title {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.12px;
    color: color('neutral', 60);
  }
}

.sign-up-progress {
  .progress__bar {
    height: 8px;
  }
}

.loan-create-progress {
  .progress {
    &__bar {
      height: 8px;
      background: var(--Blue-95---Background, #E5F3FF);
      border-radius: 0;

      &-value {
        background: var(--Blue-40, #006ACC);
        border-radius: 0;
      }
    }
  }
}