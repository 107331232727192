﻿* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {	
	overflow-x: hidden;
}

body {
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	min-width: 320px;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	background-color: $body-color;
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
	color: $text-color;
	overflow-x: hidden;
}

button {
	cursor: pointer;
}
