.new-sign-up {
  p {
    margin: 0;
  }
  position: relative;
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  padding-top: 136px;
  justify-content: center;
  margin-inline: auto;
  width: 100%;
  max-width: 700px;

  &__signUpForm {
    display: flex;
    flex-direction: column;
    gap: 56px;
    width: 100%;
  }

  .signUpForm {
    &__form {
      display: flex;
      flex-direction: column;
      gap: 56px;
    }

    &__button {
      display: flex;
      width: 100%;
      max-width: 536px;
      margin-inline: auto;
      padding: 14px 24px;
      border-radius: 16px;
    }
    &__resend {
      margin-top: 0;
    }
    &__resend-button {
      margin: 0;
      padding: 0;
      outline: unset;
      border: none;
      background-color: transparent;
      text-decoration: unset;

      &:active {
        color: color('blue', 40);
      }
    }
  }

  &__signUpWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .verifyAcc {
    display: flex;
    flex-direction: column;
    gap: 56px;

    &__successful {
      display: flex;
      flex-direction: column;
      gap: 56px;
    }

    &__icon {
      width: 96px;
      margin-inline: auto;
    }

    &__codeVerify {
      max-width: 536px;
      margin-inline: auto;
    }
  }

  .createAcc {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__inputs {
      display: flex;
      flex-direction: column;
    }
    &__inputRow {
      display: flex;
      gap: 32px;
      justify-content: space-between;
    }
    &__inputCol {
      flex-grow: 1;
    }

    &__title {
      margin-bottom: 32px;
    }
  }

  .registerBusiness {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
}

.registerSubdomain {
  &__inputRow {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    & > div:nth-child(2) {
      display: flex;
      align-items: end;
      & > div {
        width: 100%;
      }
    }
  }

  &__inputCol {
    width: 100%;
  }
}
