.member-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__first-letters { // .member-name__first-letters
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    margin-right: 8px;
    border: 1px solid color("neutral", 90);
    border-radius: 50%;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;

    &--green { // .member-name__box--green
      background-color: color("mint", 50);
      color: color("mint", 20);
    }

    &--pink { // .member-name__box--pink
      background-color: color("red", 90);
      color: color("chery", 24);
    }

    &--gray { // .member-name__box--gray
      background-color: color("neutral", 70);
      color: color("neutral", 30);
    }

    &--orange { // .member-name__box--orange
      background-color: color("yellow", 90);
      color: color("yellow", 45);
    }
    
    &--blue { // .member-name__box--blue
      background-color: color("blue", 80);
      color: color("neutral", 100);
    }
  }

  &__avatar-box { // .member-name__avatar-box
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    padding: 2px;
    border: 2px solid color("neutral", 90);
    border-radius: 50%;
  }

  &__avatar-wrapper { // .member-name__avatar-wrapper
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: color("blue", 30);
    color: color("neutral", 100);
  }

  &__avatar { // .member-name__avatar
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__avatar-placeholder { // .member-name__avatar-placeholder
    width: 20px;
    height: 20px;
  }

  &__text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
