.table-permission {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  padding-left: 0;

  &__item { // .table-permission__item
    display: flex;
    align-items: center;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    color: color("neutral", 50);

    .table-permission--disabled & {
      color: color("neutral", 85);
    }
  }

  &__item-icon { // .table-permission__item-icon
    width: 12px;
    height: 12px;
    margin-right: 4px;
    color: color("mint", 50);

    .table-permission--disabled & {
      color: color("neutral", 85);
    }
  }
}
