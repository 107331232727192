.upload-photo {
  margin-bottom: 32px;

  &__input { // .upload-photo__input
    display: none;
  }

  &__label { // .upload-photo__label
    position: relative;
    display: block;
    width: 100%;
    max-width: 120px;
    margin: 0 auto 16px;
    border-radius: 50%;
    background-color: #f2f7fc;
    color: #fff;
    cursor: pointer;

    &::before {
      display: block;
      padding-top: 100%;
      content: '';
    }
  }

  &__first-letters { // .upload-photo__first-letters
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin-bottom: 0;
    border: 4px solid #fff;
    border-radius: 50%;
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__cover { // .upload-photo__cover
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(#3F4756, .4);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  &__cover-icon { // .upload-photo__cover-icon
    width: 23px;
    height: 23px;
  }

  &__hint { // .upload-photo__hint
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    color: color("neutral", 60);
    text-align: center;
  }

  &__image { // .upload-photo__image
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    height: 100%;
    max-height: 56px;
    transform: translate(-50%, -50%);
  }
}